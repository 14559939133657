/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms inline
///
/// @param {number} $btn-line-height [$btn-font-size * $base-line-height]
/// @param {number} $btn-small-line-height [$btn-small-font-size * $base-line-height]
/// @param {number} $btn-big-line-height [$btn-big-font-size * $base-line-height]
///
/// @param {number} $tag-min-height [28]
/// @param {number} $tag-small-min-height [24]
/// @param {number} $tag-big-min-height [32]
@use "sass:math";

@mixin _btn-inline-elements($parent, $child) {
  $margin: $parent - $child;
  margin-top: rem(math.div($margin, 2));
  margin-bottom: rem(math.div($margin, 2));
}

.btn {
  .tag {
    @include _btn-inline-elements($btn-line-height, $tag-min-height);

    &.tag_small {
      @include _btn-inline-elements($btn-line-height, $tag-small-min-height);
    }

    &.tag_big {
      @include _btn-inline-elements($btn-line-height, $tag-big-min-height);
    }
  }

  .ixo {
    font-size: em($btn-icon-size, $btn-font-size);
    @include _btn-inline-elements($btn-line-height, $btn-icon-size);
  }
}

.btn_small {
  .tag {
    @include _btn-inline-elements($btn-small-line-height, $tag-min-height);

    &.tag_small {
      @include _btn-inline-elements($btn-small-line-height, $tag-small-min-height);
    }

    &.tag_big {
      @include _btn-inline-elements($btn-small-line-height, $tag-big-min-height);
    }
  }

  .ixo {
    font-size: em($btn-small-icon-size, $btn-small-font-size);
    @include _btn-inline-elements($btn-small-line-height, $btn-small-icon-size);
  }
}

.btn_big {
  .tag {
    @include _btn-inline-elements($btn-big-line-height, $tag-min-height);

    &.tag_small {
      @include _btn-inline-elements($btn-big-line-height, $tag-small-min-height);
    }

    &.tag_big {
      @include _btn-inline-elements($btn-big-line-height, $tag-big-min-height);
    }
  }

  .ixo {
    font-size: em($btn-big-icon-size, $btn-big-font-size);
    @include _btn-inline-elements($btn-big-line-height, $btn-big-icon-size);
  }
}
