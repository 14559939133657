/// @author Bernhard Ruoff
/// @since 9.1.0
/// @access public
/// @group molecules
///
/// @deprecated since 10.0.0
/// @param {array} $dialog-padding [16]
/// @param {color} $dialog-wrapper-background [$backdrop]
/// @param {number} $dialog-z-index [100]
/// @param {array} $dialog-style

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  overflow: hidden;
  overscroll-behavior: contain;
  @include inject-style($dialog-wrapper-style);
  padding: array-magic($dialog-wrapper-padding);
  background: $dialog-wrapper-background;
  z-index: $dialog-wrapper-z-index;

  &.is-active {
    display: flex;

    > .dialog {
      display: flex;
    }
  }
}

// @param {color} $dialog-background
// @param {color} $dialog-color
// @param {number} $dialog-min-width [240]
// @param {array} $dialog-padding [4]
// @param {number} $dialog-radius [4]
// @param {string} $dialog-shadow
// @param {number} $dialog-z-index [100]
// @param {array} $dialog-style
.dialog {
  display: none;
  position: relative;
  max-height: 100%;
  flex-direction: column;
  @include inject-style($dialog-style);
  background: $dialog-background;
  color: $dialog-color;
  padding: array-magic($dialog-padding);
  border-radius: array-magic($dialog-radius);
  box-shadow: array-magic($dialog-shadow);

  &:not([data-col]) {
    min-width: rem($dialog-min-width);

    // @param {percentage | false } $dialog-max-width-relative [50]
    // @param {pixel | false } $dialog-max-width-relative [640]

    @if $dialog-max-width-absolute and $dialog-max-width-relative {
      @media (min-width: ($dialog-max-width-absolute * 1px)) {
        max-width: $dialog-max-width-relative * 1%;

        .dialog-header,
        .dialog-body,
        .dialog-footer {
          max-width: rem($dialog-max-width-absolute);
        }
      }
    }
  }

  &.is-active {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: $dialog-z-index;
  }

  > .dialog-dismiss + .dialog-body {
    margin-top: rem($btn-min-height);
  }
}

// @param {array} $dialog-dismiss-style
.dialog-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  background: none;
  @include _btn-reset;
  @include inject-style($dialog-dismiss-style);

  &:only-child {
    margin-left: auto;
  }

  &:hover {
    opacity: 1;
  }
}

// @param {array} $dialog-header-spacing [0]
// @param {array} $dialog-header-padding [8]
// @param {array} $dialog-header-style
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  @include inject-style($dialog-header-style);
  margin: array-magic($dialog-header-spacing);
  padding: array-magic($dialog-header-padding);

  .dialog-dismiss {
    position: relative;
    top: auto;
    right: auto;
  }
}

// @param {array} $dialog-body-spacing [0]
// @param {array} $dialog-body-padding [8]
// @param {array} $dialog-body-style
.dialog-body {
  flex-grow: 1;
  @include is-scrollable(y, contain, $behavior: auto);
  @include inject-style($dialog-body-style);
  margin: array-magic($dialog-header-spacing);
  padding: array-magic($dialog-header-padding);
}

// @param {array} $dialog-footer-spacing [0]
// @param {array} $dialog-footer-padding [8]
// @param {array} $dialog-footer-style
.dialog-footer {
  flex-shrink: 0;
  flex-grow: 0;
  @include inject-style($dialog-footer-style);
  margin: array-magic($dialog-footer-spacing);
  padding: array-magic($dialog-footer-padding);
}
