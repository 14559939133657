.advanced-search {
  position: relative;

  > .btn {
    &.active {
      @extend .btn, .is-active;
    }
  }
}

.advanced-search-pop {
  position: absolute;
  z-index: 1000;
  background-color: get-color(background);
  border-radius: rem($dropdown-options-radius);
  @if $dropdown-options-elevation {
    @include elevation($dropdown-options-elevation);
  }
  transition: opacity 0.2s;
  padding: rem(16);
  width: rem(400);
}

.advanced-search-pop {
  .advanced-search-selector,
  .field-group {
    margin-bottom: rem(8);
    border-radius: rem(4);
    background-color: get-color(background, lightest);
    padding: rem(8);
  }

  .advanced-search-selector {
    padding-right: 0;
  }
}
