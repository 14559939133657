.tags-list {
  list-style: none;
  padding: 0;
  margin: 0 rem(-2);
  display: flex;
  flex-wrap: wrap;

  li {
    overflow: hidden;
    padding: rem(2);
  }
}
