.reporting-list {
  .data-list-body {
    .data-list-cell {
      height: rem(40);
      display: flex;
      align-items: center;
    }
  }

  .data-list-head {
    .data-list-cell {
      flex-basis: 10%;
    }
  }

  .data-list-body > .data-list-row:hover {
    .data-list-cell {
      background-color: get-color(background);
    }
  }

  // input[type='number'] {
  //   width: 100% !important;
  // }
}
