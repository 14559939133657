.mg-login.not-authenticated {
  background-color: get-color(background);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .mg-login__wrapper {
    width: rem(240);
    padding: rem(16);
  }

  #mega-logo {
    fill: get-color(text, lighter);
    height: rem(24);
    width: auto;
    margin: rem(16) auto;
    display: block;
  }
}

login-page-view {
  flex-grow: 1;
}
