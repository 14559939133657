.hide {
  display: none;
}

em.fallback {
  color: get-color(text, light);
}

fir [data-grid~="nowrap"] {
  flex-wrap: nowrap;
}

.xui-list-item {
  &:nth-child(even) {
    background-color: get-color(background, lightest);
  }
}

%ec-icon {
  font-family: "ec-icon" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal !important;
  font-variant: normal !important;
  font-style: normal;
  text-transform: none !important;
  text-rendering: optimizeLegibility;
  speak: none;
  display: inline-block;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  font-size: inherit;
  line-height: inherit;
  vertical-align: text-bottom;
}

.is-ink-traffic-low {
  color: $traffic-low;
}

.is-ink-traffic-normal {
  color: $traffic-normal;
}

.is-ink-traffic-high {
  color: $traffic-high;
}

.img-crop-1-1 {
  .file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.is-small {
  font-size: 0.875em;
}

$btn-outlook: #dc3e18;

.btn_outlook {
  background-color: $btn-outlook;
  color: set-contrast($btn-outlook);

  &:hover {
    background-color: set-hover($btn-outlook);
    color: set-contrast($btn-outlook);
  }
}

.card {
  position: initial;
  z-index: initial;
}

.xui-list_quicklist {
  > div {
    &:nth-child(even) {
      .xui-list-item {
        background-color: get-color(background, lightest);

        &:hover {
          background: $xui-list-item-hover-background;
        }
      }
    }
  }
}

.xui-list-item > * {
  mix-blend-mode: multiply;
}

.btn_icon {
  padding-left: 0;
  padding-right: 0;
}
