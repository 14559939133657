.progress {
  margin: rem(4) 0;
  height: rem(4);
  width: 100%;
  border-radius: rem(2);
  background-color: $input-background;

  .progress-bar {
    background-color: get-color(link);
    height: rem(4);
    border-radius: rem(2);
    max-width: 100%;
  }

  &-thick {
    height: rem(12);
    border-radius: rem(8);

    .progress-bar {
      border-radius: rem(8);
      height: rem(12);
    }
  }
}
