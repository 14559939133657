.doc-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: get-color(background);
  display: none;
  flex-direction: column;

  &.is-visible {
    display: flex;
    opacity: 1;
  }

  .ui-select-container {
    min-width: rem(240);
    margin-right: rem(16);
  }

  .toolbar {
    flex-grow: 0;
    flex-shrink: 0;
    padding: rem(16);
  }

  .doc-view-body {
    flex-grow: 1;
    position: relative;

    iframe {
      height: 100%;
      width: 100%;
      overflow: auto;
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .doc-view-footer {
    flex-grow: 0;
    flex-shrink: 0;
    padding: rem(16);

    .xui-list {
      max-width: rem(480);
      margin: 0 auto;
      border-radius: rem(4);
      border: 1px solid get-color(divider);

      .xui-list-item {
        border-bottom: 1px solid get-color(divider);

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
