.upload-zone {
  border: 1px dashed get-color(link);
  text-align: center;
  padding: rem(16);
  border-radius: rem(4);
  color: get-color(text, light);
  position: relative;
  margin: rem(32) 0;
  display: block;

  input {
    @extend .is-hidden-input;
  }

  &.is-active {
    background-color: get-color(state, hover);
  }
}
