.kiosk {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 56px auto;
  grid-template-areas: "header" "body";

  &.no-header {
    grid-template-rows: auto;
    grid-template-areas: "body";
  }
}

.kiosk-header {
  grid-area: header;
  background-color: get-color(background, lightest);
  box-shadow: inset 0 -1px 0 rgba(#000, 0.04);
  padding: 0 rem(16);
  display: flex;
  align-items: center;
  justify-content: stretch;

  > [data-grid] {
    flex-grow: 1;
  }
}

.kiosk-body {
  grid-area: body;
  padding: rem(16) rem(16) rem(80);
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  overflow-x: hidden;
}
