.form-validating {
  .input:not(.ng-pristine),
  &.force-validation * {
    &.ng-invalid,
    &.ng-invalid .input-group__addon {
      //@include inject-style($input-invalid-style);
      color: get-color(error, safe);
      border: 1px solid get-color(error, safe);
    }

    &.ng-invalid .input-group__addon {
      border-left-width: 0px;
    }

    date-picker.ng-invalid {
      border: none;
    }

    &.ng-valid,
    &.ng-valid .input-group {
      //@include inject-style($input-valid-style);
      color: get-color(success, safe);
    }
  }
}

input.ng-proc-dirty:not(.ng-touched),
.ng-proc-dirty .ui-select-container:not(.ng-touched) .ui-select-match :not(.close),
.ng-proc-dirty input:not(.ng-touched),
.ng-proc-dirty input:not(.ng-touched) ~ .input-group__addon,
.ng-proc-dirty select:not(.ng-touched) {
  border-color: get-color(warning, safe);
  color: get-color(warning, safe);
  // border-color: #FFC107;
  // color: #ec7e00;
}

.ng-proc-dirty .mce-tinymce .mce-edit-area {
  border-color: get-color(warning, safe) !important;
  border: 1px solid;
}
