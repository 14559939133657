/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group layout mixins
///
/// @param {boolean} $area - set a range for the media query
/// @param {boolean} $has-root - sets @content for a root element without a media-query
/// @return a set of styles for one element with all available breakpoints
///
/// @require {map} $breakpoints
/// @require {function} bp()

@mixin set-breakpoints($area: false, $has-root: true) {
  @if $has-root == true {
    @content;
  }

  @if $area == true {
    @each $bp in map-keys($breakpoints) {
      @if index($bps, $bp) == length($breakpoints) {
        &\@#{$bp} {
          @media (min-width: bp(#{$bp})) {
            @content;
          }
        }
      } @else {
        &\@#{$bp} {
          @media (min-width: bp(#{$bp})) and (max-width: bp(#{$bp}, max)) {
            @content;
          }
        }
      }
    }
  } @else {
    @each $bp in map-keys($breakpoints) {
      &\@#{$bp} {
        @media (min-width: bp(#{$bp})) {
          @content;
        }
      }
    }
  }
}
