//checked

dl {
  &.is-inline {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-8);

    &.valign-center {
      align-items: center;
      dt,
      dd,
      dd > span {
        vertical-align: middle;
      }
    }

    dt {
      margin: 0;
      padding-top: rem(4);
      padding-bottom: rem(4);
      color: get-color(text, light);
      font-size: rem(10);
      line-height: rem(16);
      border-radius: array-magic(4 0 0 4);

      &:not([data-col]) {
        flex-basis: 32%;
        width: 32%;
        padding-left: rem(8);
        padding-right: rem(8);
      }
    }

    dd {
      margin: 0;
      padding-top: rem(4);
      padding-bottom: rem(4);
      border-radius: array-magic(0 4 4 0);
      font-size: rem(12);
      line-height: rem(16);

      &:not([data-col]) {
        flex-basis: 68%;
        width: 68%;
        padding-left: rem(8);
        padding-right: rem(8);
      }
    }

    dt,
    dd {
      &:nth-of-type(even) {
        background-color: get-color(background, lightest);
      }
    }
  }
}
