/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @example
///   <div class='y-space-2 x-space-2-md'></div>
///   <div class='x-space-2'></div>
///
/// @require {variable} $spacings
/// @require {function} get-space
/// @require {mixin} set-breakpoints

@each $_spacing, $_h in $spacings {
  .y-space-#{$_spacing} {
    display: inline-flex;

    @include set-breakpoints {
      width: auto;
      height: get-space($_spacing);
      flex-shrink: 0;
    }
  }
}

@each $_spacing, $_h in $spacings {
  .x-space-#{$_spacing} {
    display: inline-flex;

    @include set-breakpoints {
      height: auto;
      width: get-space($_spacing);
      flex-shrink: 0;
    }
  }
}
