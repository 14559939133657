.data-list-info {
  @include elevation(8);
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: em(10);
  font-weight: $is-bold;
  padding: rem(8) rem(16);
  color: get-color(text);
  background-color: get-color(background);
  z-index: 10;
  border-radius: rem(4) 0 0 0;
}

.data-list-info-inline {
  border-top: 1px solid get-color(divider);
  font-size: 1em;
  line-height: 1.2em;
  font-weight: $is-bold;
  padding: rem(4) rem(8);
  color: get-color(text, light);
  text-align: center;
}
