/// @author Bernhard Ruoff
/// @since 7.0.0
/// @access public
/// @group typeface mixins

@mixin ellipsis {
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
