.edit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: calc(100vw - 80px);

  > .section {
    max-width: 800px;
    width: 100%;
  }
}

.mg-deck-sub-open,
.mg-deck_half {
  .edit-wrapper {
    width: 100%;
  }
}
