/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atom modifier
///
/// @param {number} $input-min-height [32]
/// @param {number} $input-small-min-height [28]
/// @param {number} $input-big-min-height [40]
/// @param {boolean} $input-round [false]
@use "sass:math";

@if $input-round == true {
  .input {
    border-radius: 100vw;
    padding-left: rem(math.div($input-min-height, 2));
    padding-right: rem(math.div($input-min-height, 2));
  }
}

.input_round {
  padding-left: rem(math.div($input-min-height, 2));
  padding-right: rem(math.div($input-min-height, 2));
  border-radius: 100vw;
}
