@use "sass:math";

$quicklist: var-color(get-color(background, invert), -8);

.quicklist {
  @include elevation(4);
  height: 100vh;
  width: 0;
  background-color: get-color(background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  order: -1;
  z-index: 200;
  transition: all 0.2s;

  quicklist-sidebar {
    display: flex;
    height: 100%;
  }

  &:hover {
    transition-delay: 0.2s;
    transition: all 0.2s;
  }

  &.is-active {
    width: rem(280);

    .quicklist-body,
    .quicklist-footer,
    .quiklist-header h2 {
      display: inherit;
    }
  }
}

@keyframes quicklist {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.quicklist-open {
  position: fixed;
  left: rem(64);
  bottom: 0;
  display: block;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding: rem(16) rem(4);
  margin-left: rem(-8);
  font-size: rem(10);
  font-weight: $is-bold;
  background-color: $btn-background;
  color: $btn-color;
  line-height: rem(12);
  text-transform: uppercase;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  border-radius: 0 rem($btn-radius) 0 0;
  box-shadow: 2px 0 8px rgba(#000, 0.2);
  animation: quicklist 0.4s forwards;
  animation-delay: 0.2s;

  &:hover {
    background-color: $btn-hover-background;
    color: $btn-hover-color;
  }
}

.tbrl {
  writing-mode: tb-rl;
}

.quicklist-body {
  flex-grow: 1;
  overflow-y: auto;
  flex-direction: column;
  padding: rem(math.div($grid-gutter, 2));
  display: none;

  .nav {
    width: 100%;

    .nav__item {
      padding-left: 0;
      padding-right: 0;

      &.disabled,
      &[disabled] {
        cursor: not-allowed;

        .quicklist-item {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }
}
