/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group modifier
///
/// @param {map} $is-margin-options [$spacings]
///
/// @require {mixin} set-breakpoints
/// @require {function} get-space
/// @require {map} $spacings
///
/// @deprecated since 10.0.0 `.has-#`

.has-margin,
.is-margin {
  @each $_spacing, $_h in $is-margin-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        margin: get-space($_spacing);
      }
    }
  }
}

.has-margin-middle,
.is-margin-middle {
  @each $_spacing, $_h in $is-margin-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        margin-bottom: get-space($_spacing);
        margin-top: get-space($_spacing);
      }
    }
  }
}

.has-margin-top,
.is-margin-top {
  @each $_spacing, $_h in $is-margin-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        margin-top: get-space($_spacing);
      }
    }
  }
}

.has-margin-bottom,
.is-margin-bottom {
  @each $_spacing, $_h in $is-margin-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        margin-bottom: get-space($_spacing);
      }
    }
  }
}

.has-margin-center,
.is-margin-center {
  &-auto {
    @include set-breakpoints {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @each $_spacing, $_h in $is-margin-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        margin-left: get-space($_spacing);
        margin-right: get-space($_spacing);
      }
    }
  }
}

.has-margin-left,
.is-margin-left {
  &-auto {
    @include set-breakpoints {
      margin-left: auto;
    }
  }

  @each $_spacing, $_h in $is-margin-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        margin-left: get-space($_spacing);
      }
    }
  }
}

.has-margin-right,
.is-margin-right {
  &-auto {
    @include set-breakpoints {
      margin-right: auto;
    }
  }

  @each $_spacing, $_h in $is-margin-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        margin-right: get-space($_spacing);
      }
    }
  }
}
