.history-list {
  margin: 0 rem(-12);

  .xui-list-item__label,
  .xui-list-item__end {
    line-height: rem(16);
  }

  .xui-list-item__end {
    font-size: rem(10);
  }

  .xui-list-item {
    padding: array-magic(12);

    &.is-declined .action-type {
      text-decoration: line-through;
    }
  }
}
