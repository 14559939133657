/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group grid-columns
///
/// @param {number} $grid-num-columns [16]
///
/// @require {function} bp()
/// @require {variable} $breakpoints
///
/// @deprecated since 10.0.0 breakpoints with '-' prefix
@use "sass:math";

[data-col] {
  flex: 100%;
  min-height: 1px;
  min-width: 0;
}

[data-col~="auto"] {
  flex: 1;
}

[data-col~="fit"] {
  flex: none;
}

@for $i from 1 through $grid-num-columns {
  [data-col~="#{$i}"] {
    max-width: (math.div($i, $grid-num-columns)) * 100%;
  }
}

@each $bp in map-keys($breakpoints) {
  @for $i from 1 through $grid-num-columns {
    [data-col~="#{$i}-#{$bp}"],
    [data-col~="#{$i}\@#{$bp}"] {
      @media (min-width: bp(#{$bp})) {
        max-width: (math.div($i, $grid-num-columns)) * 100%;
      }
    }
  }
}

@each $bp in map-keys($breakpoints) {
  [data-col~="auto-#{$bp}"],
  [data-col~="auto\@#{$bp}"] {
    @media (min-width: bp(#{$bp})) {
      flex: 1;
    }
  }
}

@each $bp in map-keys($breakpoints) {
  [data-col~="fit-#{$bp}"],
  [data-col~="fit\@#{$bp}"] {
    @media (min-width: bp(#{$bp})) {
      flex: none;
    }
  }
}

// scss-lint:disable ImportantRule
[data-col~="no-gutter"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
