/// @author Bernhard Ruoff
/// @since 9.0.4
/// @access public
/// @group modifier

/// no text wrapping
.is-nowrap {
  @include set-breakpoints {
    vertical-align: baseline;
    white-space: nowrap;
  }
}

/// uses automatic hyphenation
.is-hyphen {
  @include set-breakpoints {
    @include word-wrap;
  }
}

/// use automatic hyphenation with justification
.is-hyphen-block {
  @include set-breakpoints {
    @include word-wrap;
    text-align: justify;
  }
}

/// automatic text cut off with ...
.is-ellipsis {
  @include set-breakpoints {
    @include ellipsis;
    display: block;
  }
}
