/// @author Bernhard Ruoff
/// @since 9.1.0
/// @access public
/// @group molecules
///
/// @deprecated since 10.0.0
/// @param {color} $overlay-background
/// @param {color} $overlay-color
/// @param {array} $overlay-padding [4]
/// @param {number} $overlay-z-index [100]
/// @param {array} $overlay-style

.overlay-wrapper {
  &.is-active {
    .overlay {
      display: flex;
    }
  }
}

.overlay {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  @include inject-style($dialog-style);
  background: $dialog-background;
  color: $dialog-color;
  padding: array-magic($dialog-padding);
  z-index: $dialog-z-index;

  &.is-active {
    display: flex;
  }
}

// @param {array} $overlay-dismiss-style
.overlay-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.4;
  transition: opacity 0.2s;
  background: none;
  @include _btn-reset;
  @include inject-style($overlay-dismiss-style);
  color: $overlay-color;

  &:only-child {
    margin-left: auto;
  }

  &:hover {
    opacity: 1;
  }
}

// @param {array} $overlay-header-spacing [0]
// @param {array} $overlay-header-padding [8]
// @param {array} $overlay-header-style
.overlay-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  @include inject-style($overlay-header-style);
  margin: array-magic($overlay-header-spacing);
  padding: array-magic($overlay-header-padding);

  .overlay-dismiss {
    position: relative;
    top: auto;
    right: auto;
  }
}

// @param {array} $overlay-body-spacing [0]
// @param {array} $overlay-body-padding [8]
// @param {array} $overlay-body-style
.overlay-body {
  flex-grow: 1;
  @include is-scrollable(y, contain, $behavior: auto);
  @include inject-style($overlay-body-style);
  margin: array-magic($overlay-header-spacing);
  padding: array-magic($overlay-header-padding);
}

// @param {array} $overlay-footer-spacing [0]
// @param {array} $overlay-footer-padding [8]
// @param {array} $overlay-footer-style
.overlay-footer {
  flex-shrink: 0;
  flex-grow: 0;
  @include inject-style($overlay-footer-style);
  margin: array-magic($overlay-footer-spacing);
  padding: array-magic($overlay-footer-padding);
}
