// scss-lint:disable ImportantRule
.data-field {
  &_id {
    flex-grow: 0;
    width: rem(58);
    flex-basis: unset;
  }

  &_menu {
    flex-basis: unset;
    flex-grow: 0;
    width: rem($btn-small-min-height);
    padding-right: 0;
    padding-left: 0;
    position: sticky;
    right: 0;
    z-index: 1;

    &-long {
      width: rem(120);
      justify-content: flex-end;
    }
  }

  &_longer,
  &_responsible,
  &_city,
  &_contact,
  &_region {
    width: rem(120);
    flex-basis: unset;
    flex-grow: 0;
    @extend .is-hyphen;
  }

  &_date {
    width: rem(96);
    flex-basis: unset;
    flex-grow: 0;

    &.gantt-active {
      padding: 0;
      > span {
        font-weight: $is-bold;
        display: inline-block;
        width: 100%;
        padding: rem(8);
        background-color: $text !important;
        height: rem(30);
      }
    }
  }

  &_select {
    width: rem(96);
    flex-basis: unset;
    flex-grow: 0;

    select {
      width: 100%;
      min-width: initial;
      min-height: initial;
    }
  }

  &_minimal,
  &_tag,
  &_priority,
  &_document {
    width: rem(120);
    flex-grow: 0;
    flex-basis: unset;

    .tag {
      margin: 0;
    }

    .tag {
      @extend .tag, .tag_small;
    }

    &.centered {
      justify-content: center;
      width: rem(80);
      @extend .align-center;
    }
  }

  &_icon {
    display: flex;
    width: 32px;
    flex-grow: 0;
    flex-basis: unset;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }

  &_tags {
    padding: rem(4) rem(8);
    min-width: rem(160);
    flex: 1;
    mix-blend-mode: multiply;
  }

  &_title {
    display: flex;
    min-width: rem(240);
    flex: 1;
    @include word-wrap;
    position: relative;
    padding-left: rem(16);

    .traffic-light-popover {
      margin-right: rem(8);
    }

    .company-name,
    .candidate-full-name,
    .employee-full-name {
      font-size: rem(12);
      line-height: rem(16);
      margin-bottom: rem(4);
      margin-right: 1px;
    }

    .internal-employee {
      color: $background;
      border: 0px solid;
      border-radius: 5px;
      background: $text;
      display: inline-block;
      padding: 1px;
    }

    small,
    .candidate-wanted-job-title,
    .candidate-job-title,
    .employee-job-title {
      font-size: rem(10);
      line-height: rem(12);
      font-weight: $is-normal;
      margin: rem(2) 0;
    }
  }

  &_kpis,
  &_role {
    flex-basis: unset;
    flex-grow: 0;
    width: rem(28 * 8 + 16);

    .kpis-list {
      margin: 0;
    }
  }
}
