/// @author Bernhard Ruoff
/// @since 7.0.0
/// @access public
/// @group typeface mixins

@mixin word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
