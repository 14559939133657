/// @author Bernhard Ruoff
/// @since 7.0.0
/// @access public
/// @group typeface mixins

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}
