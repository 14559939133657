/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @example
///   <div class='spacer'></div>
///
/// @param {number} $spacer-size [get-space(5)]
/// @param {number} $spacer-small-size [get-space(3)]
/// @param {number} $spacer-big-size [get-space(6)]
///
/// @require {mixin} set-breakpoints()
///
/// @deprecated `.spacer-small` and `.spacer-big` since 10.0.0

.spacer,
.spacer-small,
.spacer-big {
  align-self: center;
  flex-shrink: 0;
  width: 100%;

  @include set-breakpoints {
    height: $spacer-size;
  }
}

.spacer-small,
.spacer_small {
  @include set-breakpoints {
    height: $spacer-small-size;
  }
}

.spacer-big,
.spacer_big {
  @include set-breakpoints {
    height: $spacer-big-size;
  }
}
