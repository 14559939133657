.mg-page-header {
  flex-grow: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset -1px 0 0 get-color(divider);
  @include is-scrollable(y, contain, auto);

  .mg-logo {
    color: var(--areaColor);
    background-color: get-color(background);
    font-size: rem(24);
    padding: rem(8);
    position: sticky;
    top: 0;
  }
}
