/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public

//
// COLORS
// -------------------------------------------------------------------------------------------------
// these variables are used in `_core/_colors.scss`
// Base theme colors
@use "sass:math";

$background: #fff !default;
$text: #0d2e48 !default;

// @param {color | false} $lighter-fallback [false] - overwrite light fallback color for set-contrast
// @param {color | false} $darker-fallback [false] - overwrite darker fallback color for set-contrast
$lighter-fallback: false !default;
$darker-fallback: false !default;

@if $lighter-fallback == false {
  @if luma($text) > 0.5 {
    $lighter-fallback: $text;
  } @else {
    $lighter-fallback: $background;
  }
}

@if $darker-fallback == false {
  @if luma($text) < 0.5 {
    $darker-fallback: $text;
  } @else {
    $darker-fallback: $background;
  }
}

// @param {color} $_flatten-color-base
$_flatten-color-base: $background !default;

$link: #00cd97 !default;
$link-contrast: set-contrast($link) !default;
$link-safe: $link !default;
$link-hover: set-hover($link) !default;

$super: #fb5557 !default;
$super-contrast: set-contrast($super) !default;
$super-safe: set-contrast($background, $super) !default;
$super-hover: set-hover($super) !default;

$minor: #edf2f7 !default;
$minor-contrast: set-contrast($minor, $link) !default;
$minor-safe: set-contrast($background, $minor) !default;
$minor-hover: set-hover($minor) !default;

$highlight: #2196f3 !default;
$highlight-contrast: set-contrast($highlight) !default;
$highlight-safe: set-contrast($background, $highlight) !default;
$highlight-hover: set-hover($highlight) !default;

// background shading variables
// @param {color} $background-invert [$text] - inverted color for background
// @param {color} $background-light [null] - set value too overwrite generated color
// @param {color} $background-lighter [null] - set value too overwrite generated color
// @param {color} $background-lightest [null] - set value too overwrite generated color
$background-invert: $text !default;
$background-lightest: shade-by-contrast($background, 2) !default;
$background-lighter: shade-by-contrast($background, 4) !default;
$background-light: shade-by-contrast($background, 8) !default;

// @param {color} $base-background [$background]
$base-background: $background !default;

// text shading variables
// @param {color} $text-invert [$background] - inverted color for text
// @param {color} $text-light [rgba($text, .6)]
// @param {color} $text-lighter [rgba($text, .4)]
// @param {color} $text-lightest [rgba($text, .2)]
$text-invert: $background !default;
$text-light: rgba($text, 0.6) !default;
$text-lighter: rgba($text, 0.4) !default;
$text-lightest: rgba($text, 0.2) !default;

// @param {color} $backdrop [rgba(#000, .2)]
$backdrop: rgba(#000, 0.2) !default;

// @param {color} $base-divider-color [rgba(#000, 0.1)]
$base-divider-color: rgba($background-invert, 0.1) !default;

// @param {color} $base-divider-inverted-color [rgba(#fff, 0.1)]
$base-divider-inverted-color: rgba($background, 0.1) !default;

// @param {color} $state-hover [rgba($link, 0.1)]
// @param {color} $state-hover-contrast [$link]
$state-hover: rgba($link, 0.1) !default;
$state-hover-contrast: $link !default;

// @param {color} $state-error [#f9d7da]
// @param {color} $state-error-contrast [set-contrast($background, $state-error)]
// @param {color} $state-error-safe [set-contrast($background, $state-error)]
// @param {color} $state-error-hover [set-hover($state-error)]
$state-error: #f9d7da !default;
$state-error-contrast: set-contrast($state-error) !default;
$state-error-safe: set-contrast($background, $state-error) !default;
$state-error-hover: set-hover($state-error) !default;

// @param {color} $state-success [#d3ecdb]
// @param {color} $state-success-contrast [set-contrast($background, $state-success)]
// @param {color} $state-success-safe [set-contrast($background, $state-success)]
// @param {color} $state-success-hover [set-hover($state-success)]
$state-success: #d3ecdb !default;
$state-success-contrast: set-contrast($state-success) !default;
$state-success-safe: set-contrast($background, $state-success) !default;
$state-success-hover: set-hover($state-success) !default;

// @param {color} $state-warning [#fff2cf]
// @param {color} $state-warning-contrast [set-contrast($background, $state-warning)]
// @param {color} $state-warning-safe [set-contrast($background, $state-warning)]
// @param {color} $state-warning-hover [set-hover($state-warning)]
$state-warning: #fff2cf !default;
$state-warning-contrast: set-contrast($state-warning) !default;
$state-warning-safe: set-contrast($background, $state-warning) !default;
$state-warning-hover: set-hover($state-warning) !default;

// @param {color} $state-info [#cae6fe]
// @param {color} $state-info-contrast [set-contrast($background, $state-info)]
// @param {color} $state-info-safe [set-contrast($background, $state-info)]
// @param {color} $state-info-hover [set-hover($state-info)]
$state-info: #cae6fe !default;
$state-info-contrast: set-contrast($state-info) !default;
$state-info-safe: set-contrast($background, $state-info) !default;
$state-info-hover: set-hover($state-info) !default;

//
// COLORMAP
// ---------------------------------------------
// colormap used in `get-color()` function
$xui-colors: () !default;
$xui-colors: map-merge(
  (
    "base": (
      "text": $text,
      "background": $background,
      "link": $link,
      "minor": $minor,
      "super": $super,
      "highlight": $highlight,
      "divider": $base-divider-color,
    ),
    "text": (
      "invert": $text-invert,
      "light": $text-light,
      "lighter": $text-lighter,
      "lightest": $text-lightest,
    ),
    "background": (
      "invert": $background-invert,
      "light": $background-light,
      "lighter": $background-lighter,
      "lightest": $background-lightest,
    ),
    "link": (
      "contrast": $link-contrast,
      "safe": $link-safe,
      "hover": $link-hover,
    ),
    "minor": (
      "contrast": $minor-contrast,
      "safe": $minor-safe,
      "hover": $minor-hover,
    ),
    "super": (
      "contrast": $super-contrast,
      "safe": $super-safe,
      "hover": $super-hover,
    ),
    "highlight": (
      "contrast": $highlight-contrast,
      "safe": $highlight-safe,
      "hover": $highlight-hover,
    ),
    "state": (
      "hover": $state-hover,
      "error": $state-error,
      "success": $state-success,
      "warning": $state-warning,
      "info": $state-info,
    ),
    "hover": (
      "contrast": $state-hover-contrast,
    ),
    "error": (
      "contrast": $state-error-contrast,
      "safe": $state-error-safe,
      "hover": $state-error-hover,
    ),
    "success": (
      "contrast": $state-success-contrast,
      "safe": $state-success-safe,
      "hover": $state-success-hover,
    ),
    "warning": (
      "contrast": $state-warning-contrast,
      "safe": $state-warning-safe,
      "hover": $state-warning-hover,
    ),
    "info": (
      "contrast": $state-info-contrast,
      "safe": $state-info-safe,
      "hover": $state-info-hover,
    ),
  ),
  $xui-colors
);

// @param {variable} $color-map-id [$xui-colors] - can be changed use a different colormap
$color-map-id: $xui-colors !default;

//
// ROOT
// -------------------------------------------------------------------------------------------------
// @param {number} $root-size [16] - default size used for global size calculations
$root-size: 16 !default;

//
// ROOT
// -------------------------------------------------------------------------------------------------
// @param {color} $body-color [$text]
// @param {color} $body-background [$background]
// @param {array} $body-style
$body-color: $text !default;
$body-background: $background !default;
$body-style: () !default;

//
// GRID
// -------------------------------------------------------------------------------------------------
// @param {number} $grid-container-width [1200] - max-width of container
// @param {string} $grid-container-gutter [16]
$grid-container-width: 1200 !default;
$grid-container-gutter: 16 !default;

// @param {number} $grid-num-columns [12] - number of columns in a row
$grid-num-columns: 12 !default;

// @param {number} $grid-gutter [32]
// @param {number} $grid-small-gutter [16]
// @param {number} $grid-smaller-gutter [4]
$grid-gutter: 32 !default;
$grid-small-gutter: 16 !default;
$grid-smaller-gutter: 4 !default;

//
// SPACING
// -------------------------------------------------------------------------------------------------
// @param {number} $base-spacing [16]
// @param {*} $base-spacing-unit [xrem]
// @param {map} $spacings
$base-spacing: 16 !default;
$base-spacing-unit: xrem !default;

$spacings: () !default;
$spacings: map-merge(
  (
    0: 0,
    1: $base-spacing * 0.25 /* equals 4 twin */,
    2: $base-spacing * 0.5 /* equals 8 sibling */,
    3: $base-spacing /* equals 16 buddy */,
    4: $base-spacing * 1.5 /* equals 24 peer */,
    5: $base-spacing * 2 /* equals 32 relative */,
    6: $base-spacing * 3 /* equals 48 stranger */,
    7: $base-spacing * 4 /* equals 64 stranger */,
    8: $base-spacing * 5 /* equals 80 stranger */,
  ),
  $spacings
);

//
// RESPONSIVE BREAKPOINTS
// -------------------------------------------------------------------------------------------------
// @param {map} $breakpoints
$breakpoints: () !default;
$breakpoints: map-merge(
  (
    "xs": 0 /* smartphone*/,
    "sm": 480 /* smartphone landscape*/,
    "md": 720 /* tablet*/,
    "lg": 1024 /* tablet landscape*/,
    "xl": 1280 /* desktop*/,
    "xxl": 1920 /* large*/,
  ),
  $breakpoints
);

//
// TYPEFACE
// -------------------------------------------------------------------------------------------------

//
// FONTFACE
// ---------------------------------------------
// use @import to use custom font-families
// @param {string} $body-font-family
// @param {string} $headings-font-family
// @param {string} $monospace-font
$body-font-family: sans-serif !default;
$headings-font-family: sans-serif !default;
$monospace-font: monospace !default;

//
// FONT-WEIGHT
// ---------------------------------------------
// @param {string} $is-light [lighter]
// @param {string} $is-normal [normal]
// @param {string} $is-medium [bold]
// @param {string} $is-bold [bold]
$is-light: lighter !default;
$is-normal: normal !default;
$is-medium: bold !default;
$is-bold: bolder !default;

//
// FONT-SIZE
// ---------------------------------------------
// @param {number} $base-font-size [16]
// @param {em} $base-line-height [1.4]
// @param {string} $base-font-weight [$is-normal]
// @param {string} $base-scale-unit [em]
$base-font-size: 16 !default;
$base-line-height: 1.4 !default;
$base-font-weight: $is-normal !default;
$base-scale-unit: em !default;

//
// EC-ICON
// ---------------------------------------------
// @param {number} $base-icon-size [16]
$base-icon-size: 16 !default;

//
// HEADINGS
// ---------------------------------------------
// @param {em} $headings-line-height [$base-line-height]
// @param {string} $headings-weight [$is-bold]
// @param {array} $headings-style
$headings-line-height: $base-line-height !default;
$headings-weight: $is-bold !default;
$headings-style: () !default;

// @param {array} $headings-inline-small-style
$headings-inline-small-style: () !default;

// @param {array} $h1-style
// @param {array} $h2-style
// @param {array} $h3-style
// @param {array} $h4-style
// @param {array} $h5-style
// @param {array} $h6-style
$h1-style: () !default;
$h2-style: () !default;
$h3-style: () !default;
$h4-style: () !default;
$h5-style: () !default;
$h6-style: () !default;

// @param {array} $giga-style
// @param {array} $mega-style
// @param {array} $kilo-style
$giga-style: () !default;
$mega-style: () !default;
$kilo-style: () !default;

//
// TYPEFACE SPACING
// ---------------------------------------------
// @param {number} $_headings-vertical-rhythm [$headings-line-height * $base-font-size]
$_headings-vertical-rhythm: round($headings-line-height * $base-font-size) !default; // equals 22px

// @param {*} $headings-spacing-unit [$base-spacing-unit]
$headings-spacing-unit: $base-spacing-unit !default;

// @param {array} $h1-spacing [0 0 ($vertical-rhythm * 2) 0]
// @param {array} $h2-spacing [0 0 $vertical-rhythm 0]
// @param {array} $h3-spacing [$h2-spacing]
// @param {array} $h4-spacing [$h3-spacing]
// @param {array} $h5-spacing [0 0 ($vertical-rhythm / 2) 0]
// @param {array} $h6-spacing [$h5-spacing]
$h1-spacing: 0 0 ($_headings-vertical-rhythm * 2) 0 !default;
$h2-spacing: 0 0 $_headings-vertical-rhythm 0 !default;
$h3-spacing: $h2-spacing !default;
$h4-spacing: $h3-spacing !default;
$h5-spacing: 0 0 (math.div($_headings-vertical-rhythm, 2)) 0 !default;
$h6-spacing: $h5-spacing !default;

// @param {array} $giga-spacing [($_headings-vertical-rhythm / 2) 0]
// @param {array} $mega-spacing [$giga-spacing]
// @param {array} $kilo-spacing [$mega-spacing]
$giga-spacing: (math.div($_headings-vertical-rhythm, 2)) 0 !default;
$mega-spacing: $giga-spacing !default;
$kilo-spacing: $mega-spacing !default;

//
// TYPEFACE SCALE
// ---------------------------------------------
// @param {map} $type-scale - modular scale for typeface
// @link http://www.modularscale.com/?1&em&1.25
$type-scale: () !default;
$type-scale: map-merge(
  (
    h1: 3.052,
    h2: 2.441,
    h3: 1.953,
    h4: 1.563,
    h5: 1.25,
    h6: 1,
    is-giga: 2,
    is-mega: 1.5,
    is-kilo: 1,
    is-lead: 1.25,
    p: 1,
  ),
  $type-scale
);

// @param {unit} $type-scale-unit [$base-scale-unit] - unit for modular scale
$type-scale-unit: $base-scale-unit !default;

// @param {map} $type-scale-bp - breakpoints for modular scale
$type-scale-bp: () !default;
$type-scale-bp: map-merge(
  (
    xs: 0.875,
    md: 1,
  ),
  $type-scale-bp
);

//
// PARAGRAPH STYLES
// ---------------------------------------------
// @param {number} $_base-vertical-rhythm [$base-line-height * $base-font-size]
$_base-vertical-rhythm: round($base-line-height * $base-font-size) !default; // equals 22px

// @param {string} $paragraph-weight [$is-light]
// @param {em} $paragraph-line-height [$base-line-height]
// @param {array} $paragraph-spacing [0 0 $vertical-rhythm 0]
// @param {string} $paragraph-spacing-unit [$base-spacing-unit]
// @param {array} $paragraph-style
$paragraph-weight: $is-normal !default;
$paragraph-line-height: $base-line-height !default;
$paragraph-spacing: 0 0 $_base-vertical-rhythm 0 !default;
$paragraph-spacing-unit: $base-spacing-unit !default;
$paragraph-style: () !default;

//
// LEAD PARAGRAPH STYLES
// ---------------------------------------------
// @param {string} $is-lead-weight [$is-light]
// @param {em} $is-lead-line-height [$paragraph-line-height]
// @param {array} $is-lead-spacing [$paragraph-spacing]
// @param {string} $is-lead-spacing-unit [$base-spacing-unit]
// @param {array} $is-lead-style
$is-lead-weight: $is-light !default;
$is-lead-line-height: $paragraph-line-height !default;
$is-lead-spacing: $paragraph-spacing !default;
$is-lead-spacing-unit: $base-spacing-unit !default;
$is-lead-style: () !default;

//
// LINK STYLES
// ---------------------------------------------
// @param {color} $link-color [$link]
// @param {string} $link-decoration [none]
// @param {string} $link-weight [$is-normal]
// @param {array} $link-style
$link-color: $link !default;
$link-decoration: none !default;
$link-weight: $is-normal !default;
$link-style: () !default;

// @param {color} $link-hover-color [$link-hover]
// @param {string} $link-hover-decoration [none]
// @param {array} $link-hover-style
$link-hover-color: $link-hover !default;
$link-hover-decoration: $link-decoration !default;
$link-hover-style: () !default;

//
// SMALL STYLES
// ---------------------------------------------
// @param {array} $inline-small-style
$inline-small-style: () !default;

//
// MEDIA
// -------------------------------------------------------------------------------------------------
// @param {map} $img-crop-ratio - modular scale for img-crop classes
$img-crop-ratio: () !default;
$img-crop-ratio: map-merge(
  (
    "1-1": (
      1,
      1,
    ),
    "3-4": (
      3,
      4,
    ),
    "4-3": (
      4,
      3,
    ),
    "16-9": (
      16,
      9,
    ),
    "golden": (
      1.618,
      1,
    ),
    "silver": (
      2.414,
      1,
    ),
    "ultrawide": (
      3.6,
      1,
    ),
  ),
  $img-crop-ratio
);

//
// UI
// -------------------------------------------------------------------------------------------------

//
// BTN
// ------------------------------------------------------

// METRICS
// @param {variable} $btn-font-family [$body-font-family]
// @param {string} $btn-transition [background-color .2s, box-shadow .1s]
$btn-font-family: $body-font-family !default;
$btn-transition: background-color 0.2s, box-shadow 0.1s !default;

// @param {number} $btn-padding-ratio [1.5]
// @param {array} $btn-spacing [0]
$btn-padding-ratio: 1.5 !default;
$btn-spacing: 0 !default;

// @param {boolean} $btn-round [false] - sets border-radius to 50% of button height
$btn-round: false !default;

// radius of buttons - if $btn-round === false
// @param {number} $btn-radius [4]
// @param {number} $btn-small-radius [$btn-radius]
// @param {number} $btn-big-radius [$btn-radius]
$btn-radius: 4 !default;
$btn-small-radius: $btn-radius !default;
$btn-big-radius: $btn-radius !default;

// @param {number} $btn-font-size [$base-font-size]
// @param {number} $btn-line-height [$btn-font-size * $base-line-height]
// @param {number} $btn-icon-size [$base-icon-size]
// @param {number} $btn-min-height [40]
// @param {string} $btn-weight [$is-bold]
$btn-font-size: $base-font-size !default;
$btn-line-height: $btn-font-size * $base-line-height !default;
$btn-icon-size: $base-icon-size !default;
$btn-min-height: 40 !default;
$btn-weight: $is-bold !default;

// @param {array} $btn-disabled-style
$btn-disabled-style: () !default;
$btn-disabled-style: map-merge(
  (
    box-shadow: none,
    opacity: 0.6,
  ),
  $btn-disabled-style
);

// @param {array} $btn-active-style
$btn-active-style: () !default;
$btn-active-style: map-merge(
  (
    transform: translateY(2px),
  ),
  $btn-active-style
);

// @param {number} $btn-small-font-size [$btn-font-size * .875]
// @param {number} $btn-small-line-height [$btn-small-font-size * $base-line-height]
// @param {number} $btn-small-icon-size [$btn-icon-size]
// @param {number} $btn-small-min-height [32]
// @param {string} $btn-small-weight [$btn-weight]
// @param {array} $btn-small-style
$btn-small-font-size: $btn-font-size * 0.875 !default;
$btn-small-line-height: $btn-small-font-size * $base-line-height !default;
$btn-small-icon-size: $btn-icon-size !default;
$btn-small-min-height: 32 !default;
$btn-small-weight: $btn-weight !default;
$btn-small-style: () !default;

// @param {number} $btn-big-font-size [$btn-font-size * 1.125]
// @param {number} $btn-big-line-height [$btn-big-font-size * $base-line-height]
// @param {number} $btn-big-icon-size [$btn-icon-size]
// @param {number} $btn-big-min-height [48]
// @param {string} $btn-big-weight [$btn-weight]
// @param {array} $btn-big-style
$btn-big-font-size: $btn-font-size * 1.125 !default;
$btn-big-line-height: $btn-big-font-size * $base-line-height !default;
$btn-big-icon-size: $btn-icon-size !default;
$btn-big-min-height: 48 !default;
$btn-big-weight: $btn-weight !default;
$btn-big-style: () !default;

// @param {number} $btn-wide -  min width for .btn_wide button
// @param {number} $btn-small-wide - min width for .btn_wide.btn_small button
// @param {number} $btn-big-wide - min width for .btn_wide.btn_big button
$btn-wide: 240 !default;
$btn-small-wide: 160 !default;
$btn-big-wide: 240 !default;

//
// BTN STYLE
// ------------------------------------------------------------
// @param {color} $btn-background [$link]
// @param {color} $btn-color [$link-contrast]
// @param {array} $btn-style
$btn-background: $link !default;
$btn-color: $link-contrast !default;
$btn-style: () !default;

// @param {color} $btn-hover-background [set-hover($btn-background)]
// @param {color} $btn-hover-color [$btn-color]
// @param {array} $btn-hover-style
$btn-hover-background: set-hover($btn-background) !default;
$btn-hover-color: $btn-color !default;
$btn-hover-style: () !default;

//
// BTN OUTLINE STYLE
// ------------------------------------------------------------
// @param {number} $btn-outlined-border-width [2]
// @param {color} $btn-outlined-border-style [solid]
$btn-outlined-border-width: 1 !default;
$btn-outlined-border-style: solid !default;

// @param {color} $btn-outlined-border-color [$link]
// @param {color} $btn-outlined-color [$link]
// @param {array} $btn-outlined-style
$btn-outlined-border-color: $link !default;
$btn-outlined-color: $link !default;
$btn-outlined-style: () !default;

// @param {color} $btn-outlined-hover-border-color [set-hover($btn-outlined-border-color)]
// @param {color} $btn-outlined-hover-color [set-hover($btn-outlined-color)]
// @param {array} $btn-outlined-hover-style
$btn-outlined-hover-border-color: set-hover($btn-outlined-border-color) !default;
$btn-outlined-hover-color: set-hover($btn-outlined-color) !default;
$btn-outlined-hover-style: () !default;

//
// BTN MINOR STYLE
// ------------------------------------------------------------
// @param {color} $btn-minor-background [$minor]
// @param {color} $btn-minor-color [$minor-contrast]
// @param {array} $btn-minor-style
$btn-minor-background: $minor !default;
$btn-minor-color: $minor-contrast !default;
$btn-minor-style: () !default;

// @param {color} $btn-minor-hover-background [$btn-minor-background]
// @param {color} $btn-minor-hover-color [$btn-minor-color]
// @param {array} $btn-minor-hover-style
$btn-minor-hover-background: $btn-minor-background !default;
$btn-minor-hover-color: $btn-minor-color !default;
$btn-minor-hover-style: () !default;

//
// BTN OUTLINE MINOR STYLE
// ------------------------------------------------------------
// @param {color} $btn-outlined-minor-border-color [$minor]
// @param {color} $btn-outlined-minor-color [$minor]
// @param {array} $btn-outlined-minor-style
$btn-outlined-minor-border-color: $minor !default;
$btn-outlined-minor-color: $minor !default;
$btn-outlined-minor-style: () !default;

// @param {color} $btn-outlined-minor-hover-border-color [set-hover($btn-outlined-minor-border-color)]
// @param {color} $btn-outlined-minor-hover-color [set-hover($btn-outlined-minor-color)]
// @param {array} $btn-outlined-minor-hover-style
$btn-outlined-minor-hover-border-color: set-hover($btn-outlined-minor-border-color) !default;
$btn-outlined-minor-hover-color: set-hover($btn-outlined-minor-color) !default;
$btn-outlined-minor-hover-style: () !default;

//
// BTN SUPER STYLE
// ------------------------------------------------------------
// @param {color} $btn-super-background [$super]
// @param {color} $btn-super-color [$super-contrast]
// @param {array} $btn-super-style
$btn-super-background: $super !default;
$btn-super-color: $super-contrast !default;
$btn-super-style: () !default;

// @param {color} $btn-super-hover-background [set-hover($btn-super-background)]
// @param {color} $btn-super-hover-color [$btn-super-color]
// @param {array} $btn-super-hover-style
$btn-super-hover-background: set-hover($btn-super-background) !default;
$btn-super-hover-color: $btn-super-color !default;
$btn-super-hover-style: () !default;

//
// BTN OUTLINE SUPER STYLE
// ------------------------------------------------------------
// @param {color} $btn-outlined-super-border-color [$super]
// @param {color} $btn-outlined-super-color [$super]
// @param {array} $btn-outlined-super-style
$btn-outlined-super-border-color: $super !default;
$btn-outlined-super-color: $super !default;
$btn-outlined-super-style: () !default;

// @param {color} $btn-outlined-super-hover-border-color [set-hover($btn-outlined-super-border-color)]
// @param {color} $btn-outlined-super-hover-color [set-hover($btn-outlined-super-color)]
// @param {array} $btn-outlined-super-hover-style
$btn-outlined-super-hover-border-color: set-hover($btn-outlined-super-border-color) !default;
$btn-outlined-super-hover-color: set-hover($btn-outlined-super-color) !default;
$btn-outlined-super-hover-style: () !default;

//
// BTN INVERT STYLE
// ------------------------------------------------------------
// @param {color} $btn-invert-background [$background]
// @param {color} $btn-invert-color [$link]
// @param {array} $btn-invert-style
$btn-invert-background: $background !default;
$btn-invert-color: $link !default;
$btn-invert-style: () !default;

// @param {color} $btn-invert-hover-background [set-hover($btn-invert-background)]
// @param {color} $btn-invert-hover-color [set-hover($btn-invert-color)]
// @param {array} $btn-invert-hover-style
$btn-invert-hover-background: set-hover($btn-invert-background) !default;
$btn-invert-hover-color: set-hover($btn-invert-color) !default;
$btn-invert-hover-style: () !default;

//
// BTN OUTLINE INVERT STYLE
// ------------------------------------------------------------
// @param {color} $btn-outlined-invert-border-color [$text-invert]
// @param {color} $btn-outlined-invert-color [$text-invert]
// @param {array} $btn-outlined-invert-style
$btn-outlined-invert-border-color: $text-invert !default;
$btn-outlined-invert-color: $text-invert !default;
$btn-outlined-invert-style: () !default;

// @param {color} $btn-outlined-invert-hover-border-color [set-hover($btn-outlined-invert-border-color)]
// @param {color} $btn-outlined-invert-hover-color [set-hover($btn-outlined-invert-color)]
// @param {array} $btn-outlined-invert-hover-style
$btn-outlined-invert-hover-border-color: set-hover($btn-outlined-invert-border-color) !default;
$btn-outlined-invert-hover-color: set-hover($btn-outlined-invert-color) !default;
$btn-outlined-invert-hover-style: () !default;

//
// BTN CLEAR STYLE
// ------------------------------------------------------------
// @param {color} $btn-clear-background [transparent]
// @param {color} $btn-clear-color [$link]
// @param {array} $btn-clear-style
$btn-clear-background: transparent !default;
$btn-clear-color: $link !default;
$btn-clear-style: () !default;

// @param {color} $btn-clear-hover-background [$btn-clear-background] - set `background` value
// @param {color} $btn-clear-hover-color [set-hover($btn-clear-color)]
// @param {array} $btn-clear-hover-style
$btn-clear-hover-background: $btn-clear-background !default;
$btn-clear-hover-color: set-hover($btn-clear-color) !default;
$btn-clear-hover-style: () !default;

//
// HAMBURGER BUTTON
// ------------------------------------------------------
// @param {array} $hamburger-spacing [$btn-spacing]
// @param {number} $hamburger-width [16] - width of hamburger menu
// @param {color} $hamburger-background [transparent]
// @param {number} $hamburger-line-weight [2] - height of hamburger menu bars
// @param {number} $hamburger-line-spacing [4]
// @param {color} $hamburger-line-color [$link]
// @param {array} $hamburger-style
$hamburger-spacing: $btn-spacing !default;
$hamburger-background: transparent !default;
$hamburger-line-width: 16 !default;
$hamburger-line-weight: 2 !default;
$hamburger-line-spacing: 4 !default;
$hamburger-line-color: $link !default;
$hamburger-style: () !default;

//
// LOADER
// ------------------------------------------------------
// @param {number} $loader-size [24]
// @param {array} $loader-style
// @param {number} $loader-track-width [4]
// @param {color} $loader-track-color [$background-lightest]
// @param {color} $loader-racer-color [$link]
// @param {string} $loader-racer-animation [loaderDefault]
// @param {s | ms} $loader-racer-animation-speed [1s]
// @param {string} $loader-racer-animation-behavior [infinite linear]
$loader-size: 24 !default;
$loader-style: () !default;
$loader-track-width: 4 !default;
$loader-track-color: $background-lightest !default;
$loader-racer-color: $link !default;
$loader-racer-animation: loaderDefault !default;
$loader-racer-animation-speed: 1s !default;
$loader-racer-animation-behavior: infinite linear !default;

// @param {array} $loader-is-local-style
// @param {array} $loader-is-global-style
// @param {color} $loader-is-global-background [$backdrop]
$loader-is-local-style: () !default;
$loader-is-global-style: () !default;
$loader-is-global-background: $backdrop !default;

//
// TAG
// ------------------------------------------------------
// @param {variable} $tag-font-family [$body-font-family]
// @param {number} $tag-padding-ratio [1] - ratio by which padding-left and padding-right are bigger
// @param {string} $tag-weight [$is-bold]
// @param {number} $tag-spacing [0]
// @param {color} $tag-background [$minor]
// @param {color} $tag-color [$minor-contrast]
// @param {boolean} $tag-round [false] - sets border-radius to 50% of tag height
$tag-font-family: $body-font-family !default;
$tag-weight: $is-bold !default;
$tag-padding-ratio: 2 !default;
$tag-spacing: 0 !default;
$tag-background: $minor !default;
$tag-color: $minor-contrast !default;
$tag-round: false !default;

// @param {number} $tag-font-size [14]
// @param {number} $tag-line-height [14]
// @param {number} $tag-min-height [20]
// @param {array} $tag-style
$tag-font-size: 14 !default;
$tag-line-height: 16 !default;
$tag-min-height: 20 !default;
$tag-style: () !default;

// @param {number} $tag-small-font-size [12]
// @param {number} $tag-small-line-height [12]
// @param {number} $tag-small-min-height [16]
// @param {array} $tag-small-style
$tag-small-font-size: 12 !default;
$tag-small-line-height: 12 !default;
$tag-small-min-height: 16 !default;
$tag-small-style: () !default;

// @param {number} $tag-big-font-size [16]
// @param {number} $tag-big-line-height [20]
// @param {number} $tag-big-min-height [24]
// @param {array} $tag-big-style
$tag-big-font-size: 16 !default;
$tag-big-line-height: 20 !default;
$tag-big-min-height: 24 !default;
$tag-big-style: () !default;

// @param {color} $tag-outlined-color [$minor-contrast]
// @param {color} $tag-outlined-border-color [$minor]
// @param {number} $tag-outlined-border-width [2]
// @param {string} $tag-outlined-border-style [solid]
$tag-outlined-color: $minor-contrast !default;
$tag-outlined-border-color: $minor !default;
$tag-outlined-border-width: 1 !default;
$tag-outlined-border-style: solid !default;

// radius of buttons - if $tag-round === false
// @param {number} $tag-radius [2]
// @param {number} $tag-small-radius [$tag-radius]
// @param {number} $tag-big-radius [$tag-radius]
$tag-radius: 2 !default;
$tag-small-radius: $tag-radius !default;
$tag-big-radius: $tag-radius !default;

// @param {array} $tag-styles
$tag-styles: () !default;
$tag-styles: map-merge(
  (
    "minor": (
      $minor,
      $minor-contrast,
      $minor-hover,
    ),
    "link": (
      $link,
      $link-contrast,
      $link-hover,
    ),
    "super": (
      $super,
      $super-contrast,
      $super-hover,
    ),
    "highlight": (
      $highlight,
      $highlight-contrast,
      $highlight-hover,
    ),
    "info": (
      $state-info,
      $state-info-contrast,
      $state-info-hover,
    ),
    "error": (
      $state-error,
      $state-error-contrast,
      $state-error-hover,
    ),
    "success": (
      $state-success,
      $state-success-contrast,
      $state-success-hover,
    ),
    "warning": (
      $state-warning,
      $state-warning-contrast,
      $state-warning-hover,
    ),
  ),
  $tag-styles
);

//
// MEATBALL
// ------------------------------------------------------
// @param {variable} $meatball-family [$body-font-family]
// @param {number} $meatball-size [12]
// @param {number} $meatball-font-size [12]
// @param {number} $meatball-position-top [0]
// @param {number} $meatball-position-right [0]
// @param {number} $meatball-offset-x [25%]
// @param {number} $meatball-offset-y [-25%]
// @param {number} $meatball-border-color [$super]
// @param {color} $meatball-background [$super]
// @param {color} $meatball-color [$super-contrast]
// @param {[1-24] | false} $meatball-elevation [4]
// @param {map} $meatball-style
$meatball-family: $body-font-family !default;
$meatball-size: 12 !default;
$meatball-font-size: 12 !default;
$meatball-position-top: 0 !default;
$meatball-position-right: 0 !default;
$meatball-offset-x: 25% !default;
$meatball-offset-y: -25% !default;
$meatball-border-width: 2 !default;
$meatball-border-color: $super !default;
$meatball-background: $super !default;
$meatball-color: $super-contrast !default;
$meatball-elevation: 4 !default;
$meatball-style: () !default;

//
// DROPDOWN
// ------------------------------------------------------
// @param {boolean} $dropdown-in-hover [false]
$dropdown-on-hover: false !default;

// @param {number} $dropdown-options-radius [4]
// @param {array} $dropdown-options-padding [8]
// @param {px | null} $dropdown-options-max-height [320]
// @param {color} $dropdown-options-background [$background]
// @param {color} $dropdown-options-color [$text]
// @param {[1-24] | false} $dropdown-options-elevation [8]
// @param {array} $dropdown-options-style
$dropdown-options-radius: 4 !default;
$dropdown-options-padding: 8 !default;
$dropdown-options-max-height: 320 !default;
$dropdown-options-background: $background !default;
$dropdown-options-color: $text !default;
$dropdown-options-elevation: 8 !default;
$dropdown-options-style: () !default;

// @param {color} $dropdown-option-color [$text]
// @param {color} $dropdown-option-background [none]
// @param {array} $dropdown-option-padding [4 8]
// @param {array} $dropdown-option-spacing [0]
// @param {number} $dropdown-option-font-size [$base-font-size]
// @param {number} $dropdown-option-line-height [$dropdown-option-font-size * $base-line-height]
// @param {string} $dropdown-option-alignment [center]
// @param {array} $dropdown-option-style
// @param {array} $dropdown-option-hover-style
$dropdown-option-color: $text !default;
$dropdown-option-background: none !default;
$dropdown-option-padding: 4 8 !default;
$dropdown-option-spacing: 0 !default;
$dropdown-option-font-size: $base-font-size !default;
$dropdown-option-line-height: $dropdown-option-font-size * $base-line-height !default;
$dropdown-option-alignment: center !default;
$dropdown-option-style: () !default;
$dropdown-option-hover-style: () !default;

// @param {number} $dropdown-option-divider-size [1]
// @param {number} $dropdown-option-divider-spacing [4 0]
// @param {color} $dropdown-option-divider-color [$base-divider-color]
// @param {array} $dropdown-option-divider-style
$dropdown-option-divider-size: 1 !default;
$dropdown-option-divider-spacing: 4 0 !default;
$dropdown-option-divider-color: $base-divider-color !default;
$dropdown-option-divider-style: () !default;

//
// BUTTON GROUP
// ------------------------------------------------------
// @param {array} $btn-group-style
// @param {number} $btn-group-divider-size [1]
// @param {color} $btn-group-divider-color [$base-divider-color]
$btn-group-style: () !default;
$btn-group-divider-size: 1 !default;
$btn-group-divider-color: $base-divider-color !default;

//
// NAV
// ------------------------------------------------------
// @param {array} $nav-paddding [0]
// @param {array} $nav-spacing [0]
// @param {array} $nav-style
$nav-padding: 0 !default;
$nav-spacing: 0 !default;
$nav-style: () !default;

// @param {array} $nav-item-padding [8]
$nav-item-padding: 8 !default;

// @param {array} $nav-stacked-paddding [0]
// @param {array} $nav-stacked-spacing [0]
// @param {array} $nav-stacked-style
$nav-stacked-padding: 0 !default;
$nav-stacked-spacing: 0 !default;
$nav-stacked-style: () !default;

// @param {array} $nav-stacked-item-padding [$nav-item-padding]
$nav-stacked-item-padding: $nav-item-padding !default;

// @param {color} $nav-divider-color [$divider]
// @param {number} $nav-divider-size [1]
// @param {number} $nav-divider-spacing [$nav-item-padding]
$nav-divider-color: $base-divider-color !default;
$nav-divider-size: 1 !default;
$nav-divider-spacing: $nav-item-padding !default;

// @param {number} $nav-link-radius [4]
// @param {array} $nav-link-spacing [0]
// @param {array} $nav-link-padding [4]
// @param {array} $nav-link-font-size [$base-font-size]
// @param {color} $nav-link-background
// @param {color} $nav-link-color
// @param {array} $nav-link-style
$nav-link-radius: 4 !default;
$nav-link-spacing: 0 !default;
$nav-link-padding: 4 !default;
$nav-link-font-size: $base-font-size !default;
$nav-link-background: none !default;
$nav-link-color: $text !default;
$nav-link-style: () !default;

// @param {color} $nav-link-hover-background
// @param {color} $nav-link-hover-color
// @param {array} $nav-link-hover-style
$nav-link-hover-background: $nav-link-background !default;
$nav-link-hover-color: $nav-link-color !default;
$nav-link-hover-style: () !default;

// @param {color} $nav-link-active-background
// @param {color} $nav-link-active-color
// @param {array} $nav-link-active-style
$nav-link-active-background: $nav-link-hover-background !default;
$nav-link-active-color: $nav-link-hover-color !default;
$nav-link-active-style: () !default;

//
// TITLE
// ------------------------------------------------------
// @param {array} $titlebar-spacing [0]
// @param {array} $titlebar-padding [0]
// @param {array} $titlebar-style
$titlebar-spacing: 0 !default;
$titlebar-padding: 0 !default;
$titlebar-style: () !default;

//
// XUI-TABS
// ------------------------------------------------------
// @param {number} $xui-tab-count [10]
$xui-tab-count: 10 !default;

// @param {color} $xui-tabs-background [none]
// @param {array} $xui-tabs-spacing [0]
// @param {array} $xui-tabs-padding [0]
// @param {array} $xui-tabs-style
$xui-tabs-background: none !default;
$xui-tabs-spacing: 0 !default;
$xui-tabs-padding: 0 !default;
$xui-tabs-style: () !default;

// @param {color} $xui-tabs-nav-background [none]
// @param {array} $xui-tabs-nav-spacing [0]
// @param {array} $xui-tabs-nav-padding [0]
// @param {array} $xui-tabs-nav-style
$xui-tabs-nav-background: none !default;
$xui-tabs-nav-spacing: 0 !default;
$xui-tabs-nav-padding: 0 !default;
$xui-tabs-nav-style: () !default;

// @param {array} $xui-tabs-nav-item-gutter [0]
// @param {array} $xui-tabs-nav-item-padding [0]
// @param {string} $xui-tabs-nav-item-radius [0]
// @param {color} $xui-tabs-nav-item-background [none]
// @param {color} $xui-tabs-nav-item-color [$text]
// @param {string} $xui-tabs-nav-item-weight [$is-bold]
// @param {array} $xui-tabs-nav-item-style
$xui-tabs-nav-item-gutter: 0 !default;
$xui-tabs-nav-item-padding: 4 8 !default;
$xui-tabs-nav-item-radius: 0 !default;
$xui-tabs-nav-item-background: none !default;
$xui-tabs-nav-item-color: $text !default;
$xui-tabs-nav-item-weight: $is-bold !default;
$xui-tabs-nav-item-style: () !default;

// @param {color} $xui-tabs-nav-item-hover-background [none]
// @param {color} $xui-tabs-nav-item-hover-color [$link]
// @param {array} $xui-tabs-nav-item-hover-style
$xui-tabs-nav-item-hover-background: none !default;
$xui-tabs-nav-item-hover-color: $link !default;
$xui-tabs-nav-item-hover-style: () !default;

// @param {color} $xui-tabs-nav-item-active-background [none]
// @param {color} $xui-tabs-nav-item-active-color [$link]
// @param {array} $xui-tabs-nav-item-active-style
$xui-tabs-nav-item-active-background: none !default;
$xui-tabs-nav-item-active-color: $link !default;
$xui-tabs-nav-item-active-style: () !default;

// @param {color} $xui-tab-background [none]
// @param {array} $xui-tab-spacing [0]
// @param {array} $xui-tab-padding [0]
// @param {array} $xui-tab-style
$xui-tab-background: none !default;
$xui-tab-spacing: 0 !default;
$xui-tab-padding: 0 !default;
$xui-tab-style: () !default;

//
// XUI-List
// ------------------------------------------------------
// @param {color} $xui-list-background [none]
// @param {color} $xui-list-color [inherit]
// @param {array} $xui-list-padding [0]
// @param {array} $xui-list-spacing [0 0 16]
// @param {number} $xui-list-radius [0]
// @param {array} $xui-list-style
$xui-list-background: none !default;
$xui-list-color: inherit !default;
$xui-list-padding: 0 !default;
$xui-list-spacing: 0 0 16 !default;
$xui-list-radius: 0 !default;
$xui-list-style: () !default;

// @param {array} $xui-list-item-padding [8]
// @param {array} $xui-list-item-spacing [0]
// @param {array} $xui-list-item-gutter [$base-spacing]
// @param {number} $xui-list-item-radius [4]
// @param {color} $xui-list-item-background [transparent]
// @param {color} $xui-list-item-color [none]
// @param {array} $xui-list-item-style
$xui-list-item-padding: 8 !default;
$xui-list-item-spacing: 0 !default;
$xui-list-item-gutter: $base-spacing !default;
$xui-list-item-radius: 4 !default;
$xui-list-item-background: transparent !default;
$xui-list-item-color: none !default;
$xui-list-item-style: () !default;

// @param {color} $xui-list-item-hover-background [$xui-list-item-background]
// @param {color} $xui-list-item-hover-color [$xui-list-item-color]
// @param {array} $xui-list-item-hover-style
$xui-list-item-hover-background: $xui-list-item-background !default;
$xui-list-item-hover-color: $xui-list-item-color !default;
$xui-list-item-hover-style: () !default;

// @param {color} $xui-list-item-active-background [$xui-list-item-hover-background]
// @param {color} $xui-list-item-active-color [$xui-list-item-hover-color]
// @param {array} $xui-list-item-active-style
$xui-list-item-active-background: $xui-list-item-hover-background !default;
$xui-list-item-active-color: $xui-list-item-hover-color !default;
$xui-list-item-active-style: () !default;

// @param {color} $xui-list-group-background [none]
// @param {array} $xui-list-group-padding [0]
// @param {array} $xui-list-group-spacing [0 0 16]
// @param {number} $xui-list-group-radius [0]
// @param {array} $xui-list-group-style
$xui-list-group-background: none !default;
$xui-list-group-padding: 0 !default;
$xui-list-group-spacing: 0 0 16 !default;
$xui-list-group-radius: 0 !default;
$xui-list-group-style: () !default;

// @param {color} $xui-list-group-header-background [none]
// @param {color} $xui-list-group-header-color [inherit]
// @param {array} $xui-list-group-header-padding [0]
// @param {array} $xui-list-group-header-spacing [0 0 16]
// @param {number} $xui-list-group-header-radius [0]
// @param {array} $xui-list-group-header-style
$xui-list-group-header-background: none !default;
$xui-list-group-header-color: inherit !default;
$xui-list-group-header-padding: 0 !default;
$xui-list-group-header-spacing: 0 0 16 !default;
$xui-list-group-header-radius: 0 !default;
$xui-list-group-header-style: () !default;

//
// CARD
// ------------------------------------------------------
// @param {number} $card-radius [4]
// @param {color} $card-background [$background]
// @param {color} $card-color [$text]
// @param {array} $card-padding [24]
// @param {array} $card-spacing [0]
// @param {[1-24] | false} $card-elevation [4]
// @param {array} $card-style
$card-radius: 4 !default;
$card-background: $background !default;
$card-color: $text !default;
$card-padding: 24 !default;
$card-spacing: 0 !default;
$card-elevation: 4 !default;
$card-style: () !default;

// MODAL
// ------------------------------------------------------
// @param {color} $modal-wrapper-background [$backdrop]
// @param {number} $modal-z-index [100]
// @param {array} $modal-style
$modal-wrapper-background: $backdrop !default;
$modal-wrapper-z-index: 100 !default;
$modal-wrapper-style: () !default;

// @param {array} $modal-gutter [16]
$modal-gutter: 16 !default;

// @param {string} $modal-align-items [flex-start]
$modal-align-items: flex-start !default;

// @param {number} $modal-min-width [240]
// @param {percentage | false } $modal-max-width-relative [50]
// @param {pixel | false } $modal-max-width-relative [640]
$modal-min-width: 240 !default;
$modal-max-width-relative: 50 !default;
$modal-max-width-absolute: 640 !default;

// @param {color} $modal-background
// @param {color} $modal-color
// @param {array} $modal-padding [16]
// @param {number} $modal-radius [16]
// @param {string} $modal-shadow
// @param {[1-24] | false} $modal-elevation [8]
// @param {number} $modal-z-index [100]
// @param {array} $modal-style
$modal-background: $background !default;
$modal-color: $text !default;
$modal-padding: 16 !default;
$modal-radius: 16 !default;
$modal-elevation: 8 !default;
$modal-z-index: 100 !default;
$modal-style: () !default;

// @param {array} $modal-dismiss-style
$modal-dismiss-style: () !default;

// @param {array} $modal-header-spacing [0]
// @param {array} $modal-header-padding [16]
// @param {array} $modal-header-style
$modal-header-spacing: 0 !default;
$modal-header-padding: 16 !default;
$modal-header-style: () !default;

// @param {array} $modal-body-spacing [0]
// @param {array} $modal-body-padding [16]
// @param {array} $modal-body-style
$modal-body-spacing: 0 !default;
$modal-body-padding: 16 !default;
$modal-body-style: () !default;

// @param {array} $modal-footer-spacing [0]
// @param {array} $modal-footer-padding [16]
// @param {array} $modal-footer-style
$modal-footer-spacing: 0 !default;
$modal-footer-padding: 16 !default;
$modal-footer-style: () !default;

// DIALOG
// @deprecated since 10.0.0
// ------------------------------------------------------
// @param {array} $dialog-padding [16]
// @param {color} $dialog-wrapper-background [$backdrop]
// @param {number} $dialog-z-index [100]
// @param {array} $dialog-style
$dialog-wrapper-padding: 16 !default;
$dialog-wrapper-background: $backdrop !default;
$dialog-wrapper-z-index: 100 !default;
$dialog-wrapper-style: () !default;

// @param {number} $dialog-min-width [240]
// @param {percentage | false } $dialog-max-width-relative [50]
// @param {pixel | false } $dialog-max-width-relative [640]
$dialog-min-width: 240 !default;
$dialog-max-width-relative: 50 !default;
$dialog-max-width-absolute: 640 !default;

// @param {color} $dialog-background
// @param {color} $dialog-color
// @param {array} $dialog-padding [0]
// @param {number} $dialog-radius [4]
// @param {string} $dialog-shadow
// @param {number} $dialog-z-index [100]
// @param {array} $dialog-style
$dialog-background: $background !default;
$dialog-color: $text !default;
$dialog-padding: 0 !default;
$dialog-radius: 8 !default;
$dialog-shadow: 0 4 16 -4 rgba(#000, 0.16) "," 0 8 24 0 rgba(#000, 0.1) !default;
$dialog-z-index: 100 !default;
$dialog-style: () !default;

// @param {array} $dialog-dismiss-style
$dialog-dismiss-style: () !default;

// @param {array} $dialog-header-spacing [0]
// @param {array} $dialog-header-padding [8]
// @param {array} $dialog-header-style
$dialog-header-spacing: 0 !default;
$dialog-header-padding: 8 !default;
$dialog-header-style: () !default;

// @param {array} $dialog-body-spacing [0]
// @param {array} $dialog-body-padding [8]
// @param {array} $dialog-body-style
$dialog-body-spacing: 0 !default;
$dialog-body-padding: 8 !default;
$dialog-body-style: () !default;

// @param {array} $dialog-footer-spacing [0]
// @param {array} $dialog-footer-padding [8]
// @param {array} $dialog-footer-style
$dialog-footer-spacing: 0 !default;
$dialog-footer-padding: 8 !default;
$dialog-footer-style: () !default;

// DECK
// @deprecated since 10.0.0
// ------------------------------------------------------
// @param {color} $deck-wrapper-background [$backdrop]
// @param {number} $deck-z-index [100]
// @param {array} $deck-style
$deck-wrapper-background: $backdrop !default;
$deck-wrapper-z-index: 100 !default;
$deck-wrapper-style: () !default;

// @param {number} $deck-min-width [240]
// @param {percentage | false } $deck-max-width-relative [50]
// @param {pixel | false } $deck-max-width-relative [640]
$deck-min-width: 240 !default;
$deck-max-width-relative: 50 !default;
$deck-max-width-absolute: 640 !default;

// @param {color} $deck-background
// @param {color} $deck-color
// @param {array} $deck-padding [0]
// @param {number} $deck-z-index [100]
// @param {array} $deck-style
$deck-background: $background !default;
$deck-color: $text !default;
$deck-padding: 0 !default;
$deck-z-index: 100 !default;
$deck-style: () !default;

// @param {string} $deck-opens-left-shadow
// @param {string} $deck-opens-right-shadow
$deck-opens-left-shadow: 4 0 16 -4 rgba(#000, 0.16) "," 8 0 24 0 rgba(#000, 0.1) !default;
$deck-opens-right-shadow: -4 0 16 -4 rgba(#000, 0.16) "," -8 0 24 0 rgba(#000, 0.1) !default;

// @param {array} $deck-dismiss-style
$deck-dismiss-style: () !default;

// @param {array} $deck-header-spacing [0]
// @param {array} $deck-header-padding [8]
// @param {array} $deck-header-style
$deck-header-spacing: 0 !default;
$deck-header-padding: 8 !default;
$deck-header-style: () !default;

// @param {array} $deck-body-spacing [0]
// @param {array} $deck-body-padding [8]
// @param {array} $deck-body-style
$deck-body-spacing: 0 !default;
$deck-body-padding: 8 !default;
$deck-body-style: () !default;

// @param {array} $deck-footer-spacing [0]
// @param {array} $deck-footer-padding [8]
// @param {array} $deck-footer-style
$deck-footer-spacing: 0 !default;
$deck-footer-padding: 8 !default;
$deck-footer-style: () !default;

// TOAST
// @deprecated since 10.0.0
// ------------------------------------------------------
// @param {color} $toast-wrapper-background [$backdrop]
// @param {number} $toast-z-index [100]
// @param {array} $toast-style
$toast-wrapper-background: $backdrop !default;
$toast-wrapper-z-index: 100 !default;
$toast-wrapper-style: () !default;

// @param {color} $toast-background
// @param {color} $toast-color
// @param {array} $toast-padding [0]
// @param {number} $toast-z-index [100]
// @param {array} $toast-style
$toast-background: $background !default;
$toast-color: $text !default;
$toast-padding: 0 !default;
$toast-z-index: 100 !default;
$toast-style: () !default;

// @param {string} $toast-opens-top-shadow
// @param {string} $toast-opens-bottom-shadow
$toast-opens-top-shadow: 0 4 16 -4 rgba(#000, 0.16) "," 0 8 24 0 rgba(#000, 0.1) !default;
$toast-opens-bottom-shadow: 0 -4 16 -4 rgba(#000, 0.16) "," 0 -8 24 0 rgba(#000, 0.1) !default;

// @param {array} $toast-dismiss-style
$toast-dismiss-style: () !default;

// @param {array} $toast-header-spacing [0]
// @param {array} $toast-header-padding [8]
// @param {array} $toast-header-style
$toast-header-spacing: 0 !default;
$toast-header-padding: 8 !default;
$toast-header-style: () !default;

// @param {array} $toast-body-spacing [0]
// @param {array} $toast-body-padding [8]
// @param {array} $toast-body-style
$toast-body-spacing: 0 !default;
$toast-body-padding: 8 !default;
$toast-body-style: () !default;

// @param {array} $toast-footer-spacing [0]
// @param {array} $toast-footer-padding [8]
// @param {array} $toast-footer-style
$toast-footer-spacing: 0 !default;
$toast-footer-padding: 8 !default;
$toast-footer-style: () !default;

// snackbar
// @deprecated since 10.0.0
// ------------------------------------------------------
// @param {color} $snackbar-wrapper-background [$backdrop]
// @param {number} $snackbar-z-index [100]
// @param {array} $snackbar-style
$snackbar-wrapper-background: $backdrop !default;
$snackbar-wrapper-z-index: 100 !default;
$snackbar-wrapper-style: () !default;

// @param {number} $snackbar-min-width [240]
// @param {percentage | false } $snackbar-max-width-relative [50]
// @param {pixel | false } $snackbar-max-width-relative [640]
$snackbar-min-width: 240 !default;
$snackbar-max-width-relative: 50 !default;
$snackbar-max-width-absolute: 640 !default;

// @param {color} $snackbar-background
// @param {color} $snackbar-color
// @param {array} $snackbar-padding [0]
// @param {array} $snackbar-radius [4]
// @param {number} $snackbar-z-index [100]
// @param {array} $snackbar-style
$snackbar-background: rgba($background-invert, 0.8) !default;
$snackbar-color: $text-invert !default;
$snackbar-padding: 0 !default;
$snackbar-radius: 4 !default;
$snackbar-z-index: 100 !default;
$snackbar-style: () !default;

// @param {string} $snackbar-opens-top-shadow
// @param {string} $snackbar-opens-bottom-shadow
$snackbar-opens-top-shadow: none !default;
$snackbar-opens-bottom-shadow: none !default;

// @param {array} $snackbar-dismiss-style
$snackbar-dismiss-style: () !default;

// @param {array} $snackbar-header-spacing [0]
// @param {array} $snackbar-header-padding [8]
// @param {array} $snackbar-header-style
$snackbar-header-spacing: 0 !default;
$snackbar-header-padding: 8 !default;
$snackbar-header-style: () !default;

// @param {array} $snackbar-body-spacing [0]
// @param {array} $snackbar-body-padding [8]
// @param {array} $snackbar-body-style
$snackbar-body-spacing: 0 !default;
$snackbar-body-padding: 8 !default;
$snackbar-body-style: () !default;

// @param {array} $snackbar-footer-spacing [0]
// @param {array} $snackbar-footer-padding [8]
// @param {array} $snackbar-footer-style
$snackbar-footer-spacing: 0 !default;
$snackbar-footer-padding: 8 !default;
$snackbar-footer-style: () !default;

//
// OVERLAY
// ------------------------------------------------------]
// @param {array} $overlay-padding [16]
// @param {color} $overlay-background
// @param {color} $overlay-color
// @param {number} $overlay-z-index [100]
// @param {array} $overlay-style
$overlay-padding: 16 !default;
$overlay-background: $background !default;
$overlay-color: $text !default;
$overlay-z-index: 100 !default;
$overlay-style: () !default;

// @param {array} $overlay-dismiss-style
$overlay-dismiss-style: () !default;

// @param {array} $overlay-header-spacing [0]
// @param {array} $overlay-header-padding [8]
// @param {array} $overlay-header-style
$overlay-header-spacing: 0 !default;
$overlay-header-padding: 8 !default;
$overlay-header-style: () !default;

// @param {array} $overlay-body-spacing [0]
// @param {array} $overlay-body-padding [8]
// @param {array} $overlay-body-style
$overlay-body-spacing: 0 !default;
$overlay-body-padding: 8 !default;
$overlay-body-style: () !default;

// @param {array} $overlay-footer-spacing [0]
// @param {array} $overlay-footer-padding [8]
// @param {array} $overlay-footer-style
$overlay-footer-spacing: 0 !default;
$overlay-footer-padding: 8 !default;
$overlay-footer-style: () !default;

//
// WELL
// ------------------------------------------------------
// @param {color} $well-background [$background-lightest]
// @param {color} $well-color [inherit]
// @param {number} $well-radius [4]
// @param {array} $well-padding [16 24]
// @param {array} $well-spacing [0 0 16 0]
// @param {array} $well-style
$well-background: $background-lightest !default;
$well-color: inherit !default;
$well-radius: 4 !default;
$well-padding: 16 24 !default;
$well-spacing: 0 0 16 0 !default;
$well-style: () !default;

//
// ALERT
// ------------------------------------------------------
// @param {color} $alert-background [$background-lightest]
// @param {color} $alert-color [$text]
// @param {string} $alert-weight [$is-normal]
// @param {number} $alert-radius [4]
$alert-background: $background-lightest !default;
$alert-color: $text !default;
$alert-weight: $is-normal !default;
$alert-radius: 4 !default;

// @param {number} $alert-font-size [$base-font-size]
// @param {array} $alert-padding [16 24]
// @param {array} $alert-spacing [0 0 16 0]
// @param {array} $alert-style
$alert-font-size: $base-font-size !default;
$alert-padding: 16 24 !default;
$alert-spacing: 0 0 16 0 !default;
$alert-style: () !default;

// @param {number} $alert-small-font-size [$alert-font-size * .875]
// @param {array} $alert-small-padding [8 12]
// @param {array} $alert-small-spacing [$alert-spacing]
// @param {array} $alert-small-style
$alert-small-font-size: $alert-font-size * 0.875 !default;
$alert-small-padding: 8 12 !default;
$alert-small-spacing: $alert-spacing !default;
$alert-small-style: () !default;

// @param {number} $alert-big-font-size [$alert-font-size * 1.125]
// @param {array} $alert-big-padding [24 28]
// @param {array} $alert-big-spacing [$alert-spacing]
// @param {array} $alert-big-style
$alert-big-font-size: $alert-font-size * 1.125 !default;
$alert-big-padding: 24 28 !default;
$alert-big-spacing: $alert-spacing !default;
$alert-big-style: () !default;

// @param {array} $alert-styles
$alert-styles: () !default;
$alert-styles: map-merge(
  (
    "info": (
      $state-info,
      $state-info-contrast,
    ),
    "error": (
      $state-error,
      $state-error-contrast,
    ),
    "success": (
      $state-success,
      $state-success-contrast,
    ),
    "warning": (
      $state-warning,
      $state-warning-contrast,
    ),
  ),
  $alert-styles
);

//
// TOOLTIP
// ------------------------------------------------------
// @param {number} $tooltip-padding-ratio [2] - ratio by which padding-left and padding-right are bigger
// @param {number} $tooltip-font-size [12]
// @param {number} $tooltip-line-height [14]
// @param {number} $tooltip-min-height [20]
// @param {string} $tooltip-weight [$is-normal]
// @param {color} $tooltip-background [$background-invert]
// @param {color} $tooltip-color [$text-invert]
// @param {array} $tooltip-style
$tooltip-padding-ratio: 2 !default;
$tooltip-font-size: 12 !default;
$tooltip-line-height: 14 !default;
$tooltip-min-height: 20 !default;
$tooltip-weight: $is-normal !default;
$tooltip-background: $background-invert !default;
$tooltip-color: $text-invert !default;
$tooltip-style: () !default;

// @param {boolean} $tooltip-round [false] - sets border-radius to 50% of tooltip height
$tooltip-round: false !default;

// radius of buttons - if $tooltip-round === false
// @param {number} $tooltip-radius [2]
$tooltip-radius: 2 !default;

//
// TABLE
// ------------------------------------------------------
// @param {array} $table-spacing [0 0 16 0]
// @param {array} $table-border [1 solid get-color(divider)]
// @param {array} $table-cell-padding [8]
$table-spacing: 0 0 16 0 !default;
$table-border: 1 solid $base-divider-color !default;
$table-cell-padding: 8 !default;

//
// AVATAR
// ------------------------------------------------------
// @param {number} $avatar-size [40]
// @param {number} $avatar-small-size-size [24]
// @param {number} $avatar-big-size-size [64]
// @param {array} $avatar-border [0]
// @param {array} $avatar-background [$background-lightest]
// @param {number} $avatar-square-radius [4]
// @param {array} $avatar-style
$avatar-size: 40 !default;
$avatar-small-size: 24 !default;
$avatar-big-size: 64 !default;
$avatar-border: 0 !default;
$avatar-background: $background-lightest !default;
$avatar-square-radius: 4 !default;
$avatar-style: () !default;

//
// is-border
// ------------------------------------------------------
// @param {string} $is-border [1 solid $base-divider-color]
$is-border: 1 solid $base-divider-color !default;

//
// IS-THEME
// -------------------------------------------------------------------------------------------------
// @param {map} $is-theme
// @param {map} $is-theme-style
$is-theme: () !default;
$is-theme: map-merge(
  (
    "": (
      $background,
      $text,
      $link,
    ),
    "lightest": (
      $background-lightest,
      $text,
      $link,
    ),
    "invert": (
      $background-invert,
      $text-invert,
      $link,
    ),
  ),
  $is-theme
);

$is-theme-style: () !default;

//
// IS-INK
// -------------------------------------------------------------------------------------------------
// @param {map} $is-ink
$is-ink: () !default;
$is-ink: map-merge(
  (
    "": $text,
    "light": $text-light,
    "lighter": $text-lighter,
    "lightest": $text-lightest,
    "link": $link,
    "minor": $minor-safe,
    "super": $super,
    "highlight": $highlight,
    "invert": $text-invert,
    "error": $state-error-safe,
    "success": $state-success-safe,
    "info": $state-info-safe,
    "warning": $state-warning-safe,
  ),
  $is-ink
);

//
// LAYOUT
// -------------------------------------------------------------------------------------------------

//
// BUMPER
// ------------------------------------------------------
// @param {number} $bumper-size [4]
// @param {color} $bumper-color [$base-divider-color]
// @param {color} $bumper-invert-color [$base-divider-inverted-color]
// @param {array} $bumper-spacing [get-space(5) 0]
// @param {array} $bumper-small-spacing [get-space(3) 0]
// @param {array} $bumper-big-spacing [get-space(6) 0]
$bumper-size: 4 !default;
$bumper-color: $base-divider-color !default;
$bumper-invert-color: $base-divider-inverted-color !default;
$bumper-spacing: get-space(5) 0 !default;
$bumper-small-spacing: get-space(3) 0 !default;
$bumper-big-spacing: get-space(6) 0 !default;

//
// DIVIDER
// ------------------------------------------------------
// @param {number} $divider-height [1]
// @param {color} $divider-color [$base-divider-color]
// @param {color} $divider-invert-color [$base-divider-inverted-color]
// @param {array} $divider-spacing [get-space(3) 0]
// @param {array} $divider-small-spacing [get-space(1) 0]
// @param {array} $divider-big-spacing [get-space(5) 0]
$divider-height: 1 !default;
$divider-color: $base-divider-color !default;
$divider-invert-color: $base-divider-inverted-color !default;
$divider-spacing: get-space(3) 0 !default;
$divider-small-spacing: get-space(1) 0 !default;
$divider-big-spacing: get-space(5) 0 !default;

//
// SPACER
// ------------------------------------------------------
// @param {number} $spacer-size [get-space(5)]
// @param {number} $spacer-small-size [get-space(3)]
// @param {number} $spacer-big-size [get-space(6)]
$spacer-size: get-space(5) !default;
$spacer-small-size: get-space(3) !default;
$spacer-big-size: get-space(6) !default;

//
// SPACING
// ------------------------------------------------------
// @param {number} $spacing-smallest-size [get-space(1)]
// @param {number} $spacing-small-size [get-space(2)]
// @param {number} $spacing-size [get-space(3)]
// @param {number} $spacing-big-size [get-space(5)]
$spacing-size: get-space(3) !default;
$spacing-smallest-size: get-space(1) !default;
$spacing-small-size: get-space(2) !default;
$spacing-big-size: get-space(5) !default;

//
// FORM
// -------------------------------------------------------------------------------------------------

//
// FIELDSET
// ------------------------------------------------------
// @param {color} $fieldset-background [transparent]
// @param {array} $fieldset-padding [0]
// @param {array} $fieldset-spacing [0 0 16]
// @param {array} $fieldset-border [0]
// @param {array} $fieldset-style
$fieldset-background: transparent !default;
$fieldset-padding: 0 !default;
$fieldset-spacing: 0 0 16 !default;
$fieldset-border: 0 !default;
$fieldset-style: () !default;

//
// FIELD-GROUP
// ------------------------------------------------------
// @param {array} $field-group-spacing [0 0 16]
// @param {array} $field-group-padding [0]
// @param {color} $field-group-background [none]
// @param {array} $field-group-style
$field-group-spacing: 0 0 16 !default;
$field-group-padding: 0 !default;
$field-group-background: none !default;
$field-group-style: () !default;

// @param {array} $field-group-label-spacing [4]
// @param {number} $field-group-label-font-size [$base-font-size]
// @param {array} $field-group-label-style
$field-group-label-spacing: 4 !default;
$field-group-label-font-size: $base-font-size !default;
$field-group-label-style: () !default;

// @param {array} $field-group-info-spacing [8 4]
// @param {number} $field-group-info-font-size [$base-font-size * 0.75]
// @param {array} $field-group-info-style
$field-group-info-spacing: 8 4 !default;
$field-group-info-font-size: $base-font-size * 0.75 !default;
$field-group-info-style: () !default;

//
// INPUT
// ------------------------------------------------------
// @param {number} $input-font-size [16]
// @param {number} $input-line-height [24]
// @param {number} $input-min-height [40]
// @param {number} $input-padding-ratio [1]
// @param {array} $input-spacing [0]
// @param {color} $input-border-color [transparent]
// @param {number} $input-border-width [0]
// @param {string} $input-border-style [solid]
// @param {color} $input-background [$background-lighter]
// @param {color} $input-color [$text]
// @param {array} $input-style
// @param {array} $input-hover-style
// @param {array} $input-focus-style
// @param {array} $input-disabled-style
$input-font-size: $base-font-size !default;
$input-line-height: $input-font-size * 1.125 !default;
$input-min-height: 40 !default;
$input-padding-ratio: 1 !default;
$input-spacing: 0 !default;
$input-border-color: transparent !default;
$input-border-width: 0 !default;
$input-border-style: solid !default;
$input-background: #edf2f7 !default;
$input-color: $text !default;
$input-style: () !default;
$input-hover-style: () !default;
$input-focus-style: () !default;
$input-disabled-style: () !default;

// @param {color} $input-placeholder-color [$text-lighter]
// @param {array} $input-placeholder-style
// @param {array} $input-placeholder-hover-style
// @param {array} $input-placeholder-focus-style
$input-placeholder-color: $text-lighter !default;
$input-placeholder-style: () !default;
$input-placeholder-hover-style: () !default;
$input-placeholder-focus-style: () !default;

// @param {number} $input-small-font-size [14]
// @param {number} $input-small-line-height [18]
// @param {number} $input-small-min-height [32]
// @param {array} $input-small-style
$input-small-font-size: $input-font-size * 0.875 !default;
$input-small-line-height: $input-small-font-size * 1.125 !default;
$input-small-min-height: 32 !default;
$input-small-style: () !default;

// @param {number} $input-big-font-size [18]
// @param {number} $input-big-line-height [24]
// @param {number} $input-big-min-height [48]
// @param {array} $input-big-style
$input-big-font-size: $input-font-size * 1.125 !default;
$input-big-line-height: $input-big-font-size * 1.125 !default;
$input-big-min-height: 48 !default;
$input-big-style: () !default;

// @param {boolean} $input-round [false] - sets border-radius to 50% of input height
// @param {number} $input-radius [4] - if $input-round === false
$input-round: false !default;
$input-radius: 4 !default;

//
// RANGE-Slider
// ------------------------------------------------------
// @param {number} $range-slider-height [8]
// @param {number} $range-slider-radius [4]
// @param {color} $range-slider-background [$background-lightest]
// @param {array} $range-slider-style
// @param {array} $range-slider-hover-style
$range-slider-height: 8 !default;
$range-slider-radius: 4 !default;
$range-slider-background: $background-lightest !default;
$range-slider-style: () !default;
$range-slider-hover-style: () !default;

// @param {number} $range-slider-thumb-size [16]
// @param {number} $range-slider-thumb-radius [8]
// @param {color} $range-slider-thumb-color [$link]
// @param {array} $range-slider-thumb-style
// @param {array} $range-slider-thumb-hover-style
$range-slider-thumb-color: $link !default;
$range-slider-thumb-size: 16 !default;
$range-slider-thumb-radius: 8 !default;
$range-slider-thumb-style: () !default;
$range-slider-thumb-hover-style: () !default;

//
// FILE-UPLOAD
// ------------------------------------------------------
// @param {array} $file-upload-spacing [0 0 16]
// @param {array} $file-upload-padding [16]
// @param {color} $file-upload-background [none]
// @param {array} $file-upload-radius [4]
// @param {array} $file-upload-border [2px dashed $minor]
// @param {array} $file-upload-style
// @param {array} $file-upload-hover-style
$file-upload-spacing: 0 0 16 !default;
$file-upload-padding: 16 !default;
$file-upload-background: none !default;
$file-upload-border-radius: 4 !default;
$file-upload-border: 2px dashed $minor !default;
$file-upload-style: () !default;
$file-upload-hover-style: () !default;

//
// INPUT-GROUP
// ------------------------------------------------------
// @param {array} $input-group-spacing [$input-spacing]
$input-group-spacing: $input-spacing !default;

// @param {color} $input-group-addon-color [$text-light]
// @param {color} $input-group-addon-background [$input-background]
// @param {color} $input-group-addon-border-color [$input-border-color]
// @param {color} $input-group-addon-border-width [$input-border-width]
// @param {array} $input-group-addon-border-width [$input-border-style]
// @param {array} $input-group-addon-padding [0 4]
// @param {array} $input-group-addon-style
$input-group-addon-color: $text-light !default;
$input-group-addon-background: $input-background !default;
$input-group-addon-border-color: $input-border-color !default;
$input-group-addon-border-width: $input-border-width !default;
$input-group-addon-border-style: $input-border-style !default;
$input-group-addon-padding: 0 8 !default;
$input-group-addon-style: () !default;

//
// XUI-SELECT
// ------------------------------------------------------
// @param {color} $xui-select-background [$input-background]
// @param {color} $xui-select-color [$input-color]
// @param {array} $xui-select-spacing [$input-spacing]
// @param {number} $xui-select-padding-ratio [$input-padding-ratio]
// @param {number} $xui-select-font-size [$input-font-size]
// @param {number} $xui-select-line-height [$input-line-height]
// @param {number} $xui-select-min-height [$input-min-height]
// @param {boolean} $xui-select-round [$input-round]
// @param {array} $xui-select-radius [$input-radius]
$xui-select-background: $input-background !default;
$xui-select-color: $input-color !default;
$xui-select-spacing: $input-spacing !default;
$xui-select-padding-ratio: $input-padding-ratio !default;
$xui-select-font-size: $input-font-size !default;
$xui-select-line-height: $input-line-height !default;
$xui-select-min-height: $input-min-height !default;
$xui-select-round: $input-round !default;
$xui-select-radius: $input-radius !default;

// @param {color} $xui-select-border-color [$input-border-color]
// @param {number} $xui-select-border-width [$input-border-width]
// @param {string} $xui-select-border-style [$input-border-style]
// @param {map} $xui-select-style
$xui-select-border-color: $input-border-color !default;
$xui-select-border-width: $input-border-width !default;
$xui-select-border-style: $input-border-style !default;
$xui-select-style: () !default;

// @param {color} $xui-select-placeholder-color [$input-placeholder-color]
// @param {map} $xui-select-placeholder-style
// @param {map} $xui-select-placeholder-hover-style
$xui-select-placeholder-color: $input-placeholder-color !default;
$xui-select-placeholder-style: () !default;
$xui-select-placeholder-hover-style: () !default;

// @param {number} $xui-select-options-radius [4]
// @param {array} $xui-select-options-padding [4]
// @param {px | null} $xui-select-options-max-height [240]
// @param {color} $xui-select-options-background
// @param {[1-24] | false} $xui-select-options-elevation [8]
// @param {map} $xui-select-options-style
$xui-select-options-radius: 4 !default;
$xui-select-options-padding: 4 !default;
$xui-select-options-max-height: 240 !default;
$xui-select-options-background: $background !default;
$xui-select-options-elevation: 8 !default;
$xui-select-options-style: () !default;

// @param {number} $xui-select-option-radius [4]
// @param {array} $xui-select-option-spacing [4 0]
// @param {array} $xui-select-option-padding [4]
// @param {number} $xui-select-option-font-size [$xui-select-font-size]
// @param {color} $xui-select-option-background
// @param {color} $xui-select-option-color
// @param {map} $xui-select-option-style
$xui-select-option-radius: 4 !default;
$xui-select-option-spacing: 2 0 !default;
$xui-select-option-padding: 4 !default;
$xui-select-option-font-size: $xui-select-font-size !default;
$xui-select-option-background: none !default;
$xui-select-option-color: $text !default;
$xui-select-option-style: () !default;

// @param {color} $xui-select-option-hover-background
// @param {color} $xui-select-option-hover-color
// @param {map} $xui-select-option-hover-style
$xui-select-option-hover-background: $state-hover !default;
$xui-select-option-hover-color: $xui-select-option-color !default;
$xui-select-option-hover-style: () !default;

// @param {color} $xui-select-option-selected-background
// @param {color} $xui-select-option-selected-color
// @param {map} $xui-select-option-selected-style
$xui-select-option-selected-background: $xui-select-option-hover-background !default;
$xui-select-option-selected-color: $xui-select-option-hover-color !default;
$xui-select-option-selected-style: () !default;

//
// XUI-CHECKBOX
// ------------------------------------------------------
// @param {array} $xui-checkbox-spacing [4 12 4 4]
// @param {number} $xui-checkbox-min-height [20]
// @param {number} $xui-checkbox-radius [6]
// @param {color} $xui-checkbox-background [$input-background]
// @param {array} $xui-checkbox-style
$xui-checkbox-spacing: 4 12 4 4 !default;
$xui-checkbox-height: 20 !default;
$xui-checkbox-radius: 6 !default;
$xui-checkbox-background: $input-background !default;
$xui-checkbox-style: () !default;

// @param {array} $xui-checkbox-hover-style
$xui-checkbox-hover-style: () !default;

// @param {array} $xui-checkbox-checked-style
$xui-checkbox-checked-style: () !default;

// @param {number} $xui-checkbox-check-size [14]
// @param {color} $xui-checkbox-check-color [transparent]
$xui-checkbox-check-size: 14 !default;
$xui-checkbox-check-color: transparent !default;

// @param {color} $xui-checkbox-check-hover-color [$text-lightest]
$xui-checkbox-check-hover-color: $text-lightest !default;

// @param {color} $xui-checkbox-check-checked-color [$link]
$xui-checkbox-check-checked-color: $link !default;

//
// XUI-RADIO
// ------------------------------------------------------
// @param {array} $xui-radio-spacing [0 12 0 0]
// @param {number} $xui-radio-height [20]
// @param {color} $xui-radio-background [$input-background]
// @param {array} $xui-radio-style
$xui-radio-spacing: 0 12 0 0 !default;
$xui-radio-height: 20 !default;
$xui-radio-background: $input-background !default;
$xui-radio-style: () !default;

// @param {array} $xui-checkbox-hover-style
$xui-radio-hover-style: () !default;

// @param {array} $xui-checkbox-checked-style
$xui-radio-checked-style: () !default;

// @param {number} $xui-radio-dot-size [8]
// @param {color} $xui-radio-dot-color [transparent]
$xui-radio-dot-size: 8 !default;
$xui-radio-dot-color: transparent !default;

// @param {color} $xui-radio-dot-hover-color [$text-lightest]
$xui-radio-dot-hover-color: $text-lightest !default;

// @param {color} $xui-radio-dot-checked-color [$link]
$xui-radio-dot-checked-color: $link !default;

//
// XUI-TOGGLE
// ------------------------------------------------------
// @param {color} $xui-toggle-background [$background-lighter]
// @param {color} $xui-toggle-hover-background [etHover($xui-toggle-background)]
// @param {color} $xui-toggle-active-background [$link)]
// @param {number} $xui-toggle-height [20]
// @param {color} $xui-toggle-width [$xui-toggle-height * 1.75]
// @param {color} $xui-toggle-radius [$xui-toggle-height / 2]
// @param {array} $xui-toggle-spacing [0]
// @param {array} $xui-toggle-style
$xui-toggle-background: $background-lighter !default;
$xui-toggle-hover-background: set-hover($xui-toggle-background) !default;
$xui-toggle-active-background: $link !default;
$xui-toggle-height: 20 !default;
$xui-toggle-width: $xui-toggle-height * 1.75 !default;
$xui-toggle-radius: math.div($xui-toggle-height, 2) !default;
$xui-toggle-spacing: 0 8 0 0 !default;
$xui-toggle-style: () !default;

// @param {color} $xui-toggle-pin-background [$background]
// @param {number} $xui-toggle-pin-height[$xui-toggle-height - 8]
// @param {number} $xui-toggle-pin-width [$xui-toggle-pin-height]
// @param {number} $xui-toggle-pin-radius [$xui-toggle-pin-height / 2]
// @param {array} $xui-toggle-pin-style
$xui-toggle-pin-background: $background !default;
$xui-toggle-pin-height: $xui-toggle-height - 8 !default;
$xui-toggle-pin-width: $xui-toggle-pin-height !default;
$xui-toggle-pin-radius: math.div($xui-toggle-pin-height, 2) !default;
$xui-toggle-pin-style: () !default;

// @param {number} $xui-toggle-label-font-size [base-font-size]
// @param {number} $xui-toggle-label-line-height [$xui-toggle-label-font-size * $base-line-height]
// @param {array} xui-toggle-label-style
$xui-toggle-label-font-size: $base-font-size !default;
$xui-toggle-label-line-height: $xui-toggle-label-font-size * $base-line-height !default;
$xui-toggle-label-style: () !default;

//
// MARK
// -------------------------------------------------------------------------------------------------
// @param {color} $mark-color [false]
// @param {color} $mark-background [false]
// @param {array} $mark-style
$mark-color: false !default;
$mark-background: false !default;
$mark-style: () !default;

//
// IS-OUTSET
// -------------------------------------------------------------------------------------------------
// @param {number} $is-outset [48]
// @param {array} $is-outset-spacing [16 0]
// @param {number} $is-outset-gutter [32]
// @param {number} $is-outset-bp [1200]
$is-outset: 48 !default;
$is-outset-spacing: 16 0 !default;
$is-outset-gutter: 32 !default;
$is-outset-bp: 1200 !default;

//
// IS-RADIUS
// -------------------------------------------------------------------------------------------------
// @param {map} $is-radius-options
$is-radius-options: () !default;
$is-radius-options: map-merge(
  (
    "0": 0,
    "": 8,
  ),
  $is-radius-options
);

//
// IS-PADDING
// -------------------------------------------------------------------------------------------------
// @param {map} $is-padding-options [$spacings]
$is-padding-options: $spacings !default;

//
// IS-MARGIN
// -------------------------------------------------------------------------------------------------
// @param {map} $is-margin-options [$spacings]
$is-margin-options: $spacings !default;

//
// IS-HEIGHT
// -------------------------------------------------------------------------------------------------
// @param {map} $is-height-map
$is-height-map: () !default;
$is-height-map: map-merge(
  (
    full: 100,
    "1-4": 25,
    half: 50,
    "3-4": 75,
    "16-9": 56.25,
    golden: 61.803,
  ),
  $is-height-map
);

//
// IS-WIDTH
// -------------------------------------------------------------------------------------------------
// @param {map} $is-width-map - available width options
$is-width-map: () !default;
$is-width-map: map-merge(
  (
    full: 100,
    "1-4": 25,
    half: 50,
    "3-4": 75,
    "16-9": 56.25,
    golden: 61.803,
  ),
  $is-width-map
);

//
// IS-{DISPLAY}
// -------------------------------------------------------------------------------------------------
// @param {map} $is-display-map
$is-display-map: () !default;
$is-display-map: map-merge(
  (
    "is-hidden": "none",
    "is-block": "block",
    "is-flex": "flex",
  ),
  $is-display-map
);

//
// IS-{POSITION}
// -------------------------------------------------------------------------------------------------
// @param {map} $is-position-map
$is-position-map: () !default;
$is-position-map: map-merge(
  (
    "is-relative": "relative",
    "is-absolute": "absolute",
    "is-fixed": "fixed",
    "is-sticky": "sticky",
  ),
  $is-position-map
);

//
// IS-PLACED
// -------------------------------------------------------------------------------------------------
// @param {map} $is-placed-map
$is-placed-map: () !default;
$is-placed-map: map-merge(
  (
    "is-placed": 0,
    "is-fab": 16,
  ),
  $is-placed-map
);

//
// IS-LAYER
// -------------------------------------------------------------------------------------------------
$is-layer: 1, 10, 100, 9999 !default;

//
// MIXINS
// -------------------------------------------------------------------------------------------------

// The default duration value for elevation transitions.
$elevation-transition-duration: 280ms !default;

// The default easing value for elevation transitions.
$elevation-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;

// @param {color} $elevation-color [#000]- The default color for elevation shadows.
$elevation-color: #000 !default;

// @param {string} $elevation-opacity [1] The default opacity scaling value for elevation shadows.
$elevation-opacity: 1 !default;

// @param {string} $elevation-prefix [is-elevated-]
$elevation-prefix: "is-elevated-" !default;
