datetimepicker {
  > ng-form {
    display: flex;
    align-items: center;
  }

  .form-control {
    @extend .input;
  }

  .datetimepicker-wrapper {
    margin-right: rem(8);
  }

  *:focus {
    outline: none;
  }
}

.text-center {
  text-align: center;
}

.free-date-picker-popover {
  @include elevation(8);
  position: absolute;
  background-color: get-color(background);
  border-radius: rem($dropdown-options-radius);
  padding: rem(8);
  font-size: rem(10);
  z-index: 9999;
}

@media (min-width: bp(md)) {
  .daterangepicker {
    width: max-content;
  }
}

.uib-datepicker-popup,
.daterangepicker,
.freedatepicker {
  // position: absolute;
  // list-style: none;
  // margin: 0;
  // background-color: get-color(background);
  // border-radius: rem($modal-radius);
  // box-shadow: 0 4px 8px rgba(black, 0.1), 0 4px 24px rgba(black, 0.1);
  // transition: opacity 0.4s;
  // padding: rem(8);
  // outline: none;
  // z-index: 10;

  .glyphicon-chevron-left {
    &:before {
      @extend %ec-icon;
      content: "\e0d3";
    }
  }

  .glyphicon-chevron-right {
    &:before {
      @extend %ec-icon;
      content: "\e0d4";
    }
  }

  .glyphicon-chevron-left + span,
  .glyphicon-chevron-right + span {
    display: none;
  }

  .btn-default,
  .btn-info.active {
    @extend .btn_clear, .btn_round;
  }

  .btn-info.active {
    @extend .is-active;
  }

  .uib-button-bar {
    overflow: hidden;
  }

  li {
    outline: none;
  }

  .uib-weeks {
    td {
      vertical-align: middle;

      h6 {
        margin: 0;
        font-size: 0.875em;
        color: get-color(text, light);

        em {
          font-style: normal;
        }
      }
    }
  }

  .ranges {
    ul {
      margin: 0 0 rem(16);
    }

    li {
      cursor: pointer;
      border: 0;
      height: auto;
      outline: none;
      position: relative;
      text-decoration: none;
      touch-action: manipulation;
      user-select: none;
      display: block;
      @include inject-style($dropdown-option-style);
      font-size: rem($dropdown-option-font-size);
      line-height: rem($dropdown-option-line-height);
      color: $dropdown-option-color;
      background: $background-lightest;
      margin: array-magic(2 0);
      min-height: rem(28);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: array-magic($dropdown-option-padding);

      &.active,
      &:hover {
        @include inject-style($dropdown-option-hover-style);
        background-color: $state-hover;
      }
    }
  }
}

.uib-timepicker {
  .field-group {
    margin: 0;
  }

  td {
    vertical-align: middle;
  }
}

.uib-daypicker {
  th {
    text-align: center;
  }

  em {
    font-style: normal;
  }
}
