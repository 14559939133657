.mg-public {
  @media (min-width: bp(sm)) {
    padding: rem(32);
  }

  font-family: $public-font;
  font-size: rem(14);
  line-height: 1.4em;
  background-color: #f9fafb;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233b5462' fill-opacity='0.08'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  flex-wrap: wrap;

  @include headings {
    font-family: $public-font;
    font-size: 16px;
    margin: rem(16) 0 rem(8) 0;
  }

  p {
    margin: 0 0 rem(16) 0;
    line-height: 1.4em;
  }

  ul,
  ol {
    margin: 0 0 rem(12) 0;

    li {
      margin: 0 0 rem(4) 0;
    }
  }
}

.mg-public__container {
  max-width: rem(816);
  margin: 0 auto;
  padding: rem(32);
  @include elevation(4);
  border-radius: rem(2);
  background-color: #fff;

  &.mg-public__with_sidebox {
    @media (min-width: bp(lg)) {
      margin: 0 0 0 auto;
    }

    flex-basis: 40rem;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.mg-public__sidebox {
  @media (max-width: bp(lg)) {
    margin: rem(16) 0 0 0;
    max-width: none;
  }

  flex-basis: 10rem;
  flex-grow: 1;

  max-width: rem(320);
  padding: rem(16);
  @include elevation(4);
  border-radius: rem(2);
  background-color: #fff;

  margin: rem(160) auto 0 rem(16);
  height: max-content;
}

.mg-public__logo {
  margin: rem(16) 0 rem(32) 0;

  img {
    width: auto;
    height: rem(64);
  }
}

.mg-public-progress_container {
  justify-content: space-between;
  max-width: rem(600);
  margin: 0 auto rem(32) auto;

  .progress_step {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    text-align: center;
    position: relative;

    .icon_step {
      border-radius: 1000px;
      width: 20px;
      height: 20px;
      background-color: #bbbbbb;
      display: block;
      margin: 0 auto 0.5em auto;
      border-bottom: 1px solid #888888;

      &:before {
        display: block;
        top: 9px;
        width: 100%;
        height: 3px;
        content: "";
        background-color: #bbbbbb;
        position: absolute;
        left: 0;
      }

      &:after {
        display: block;
        top: 9px;
        width: 100%;
        height: 3px;
        content: "";
        background-color: #bbbbbb;
        position: absolute;
        right: 0;
      }
    }

    &.completed .icon_step {
      background-color: #83b0dd;

      &:before,
      &:after {
        background-color: #83b0dd;
      }
    }
  }
}
