.mg-search-form {
  background-color: $input-background;
  border-radius: rem($input-radius);
  min-height: rem($input-min-height);
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  .ixo {
    margin: 0 rem(8);
    color: get-color(text, lighter);
    font-size: rem(16);
    flex-shrink: 0;
  }
}

.mg-search {
  border: 0;
  font-size: 14px;
  min-height: auto;
  background: transparent;

  .ui-select-search {
    background: transparent;
    padding: array-magic(0 4) !important;
    font-size: rem($input-font-size);
    line-height: rem($input-line-height);

    @include placeholder {
      color: $input-placeholder-color;
    }
  }

  .ui-select-placeholder {
    color: get-color(text, lightest);
  }
}
