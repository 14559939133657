.toolbar {
  flex-grow: 1;

  .traffic-light {
    flex-grow: 0;
  }

  @include headings {
    flex-grow: 1;
  }
}
