/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group modifier
///
/// @param {map} $is-display-map
///
/// @requires {function} bp
/// @requires {map} $breakpoints

// scss-lint:disable ImportantRule
$_keys: map-keys($is-display-map);
$_values: map-values($is-display-map);

@each $_key in $_keys {
  $_i: index($_keys, $_key);
  $_v: nth($_values, $_i);

  .#{$_key} {
    display: #{$_v} !important;
  }
}

@each $_bp in map-keys($breakpoints) {
  $_nbp: next-bp($_bp);

  @if $_nbp != null {
    $_nbp: $_nbp - 1;

    @media (min-width: bp(#{$_bp})) and (max-width: #{$_nbp}) {
      @each $_key in $_keys {
        $_i: index($_keys, $_key);
        $_v: nth($_values, $_i);

        .#{$_key}\@#{$_bp} {
          display: #{$_v} !important;
        }
      }
    }
  } @else {
    @media (min-width: bp(#{$_bp})) {
      @each $_key in $_keys {
        $_i: index($_keys, $_key);
        $_v: nth($_values, $_i);

        .#{$_key}\@#{$_bp} {
          display: #{$_v} !important;
        }
      }
    }
  }
}

@each $_bp in map-keys($breakpoints) {
  $_nbp: next-bp($_bp);

  @if $_nbp != null {
    $_nbp: $_nbp - 1;

    @media (min-width: bp(#{$_bp})) {
      @each $_key in $_keys {
        $_i: index($_keys, $_key);
        $_v: nth($_values, $_i);

        .#{$_key}\@#{$_bp}\+ {
          display: #{$_v} !important;
        }
      }
    }
  }
}
