.mg-notice {
  background: $alert-background;
  color: $alert-color;
  border-radius: array-magic($alert-radius);
  font-weight: $alert-weight;
  font-size: em($alert-font-size);
  margin: array-magic($alert-spacing);
  display: flex;
  overflow: hidden;
}

.mg-notice__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(16);
  min-width: rem(32);
  min-height: rem(32);
}

.mg-notice__body {
  display: flex;
  align-items: center;
}

.mg-notice__icon,
.mg-notice__body {
  padding: rem(8);
}

.mg-notice_restriction {
  background-color: $state-error;
  color: $state-error-contrast;

  .mg-notice__icon {
    background-color: darken($state-error, 8%);
    color: set-contrast(darken($state-error, 8%));
  }
}

.mg-notice_status {
  background-color: $state-success;
  color: $state-success-contrast;

  .mg-notice__icon {
    background-color: darken($state-success, 8%);
    color: set-contrast(darken($state-success, 8%));
  }
}
