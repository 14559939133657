/// @author Bernhard Ruoff
/// @since 9.0.4
/// @access public
/// @group molecules

.is-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.is-inline {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
  }
}

.input-with-label {
  display: flex;
  align-items: center;
}

.inline-label {
  margin-left: 5px;
}
