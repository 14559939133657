/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @param {array} $input-group-spacing [$input-spacing]

// scss-lint:disable NestingDepth
.input-group {
  align-items: stretch;
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: array-magic($input-group-spacing);

  > .btn {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;

    &:active {
      transform: initial;
    }

    &:first-child {
      &.btn_outlined {
        border-right-style: solid;
        border-right-width: rem($btn-outlined-border-width);
      }
    }
  }

  .input {
    margin: 0;
  }

  .input,
  .input-group__addon {
    + .btn {
      &.btn_outlined {
        border-left-style: solid;
        border-left-width: rem($btn-outlined-border-width);
      }
    }
  }

  .input-group__addon,
  > .btn,
  > .input,
  > .xui-select {
    &:not(:only-child) {
      &:first-child {
        border-bottom-right-radius: 0;
        border-right: 0;
        border-top-right-radius: 0;
      }

      &:last-child {
        border-bottom-left-radius: 0;
        border-left: 0;
        border-top-left-radius: 0;
      }
    }

    &:not(:last-child) {
      &:not(:first-child) {
        border-left: 0;
        border-radius: 0;
        border-right: 0;
      }
    }
  }
}

// @param {color} $input-group-addon-color [$text-light]
// @param {color} $input-group-addon-background [transparent]
// @param {color} $input-group-addon-border-color [$input-border-color]
// @param {color} $input-group-addon-border-width [$input-border-width]
// @param {array} $input-group-addon-border-width [$input-border-style]
// @param {array} $input-group-addon-padding [0 8]
// @param {array} $input-group-addon-style
.input-group__addon {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: stretch;
  width: auto;
  @include inject-style($input-group-addon-style);
  color: $input-group-addon-color;
  background: $input-group-addon-background;
  border-width: rem($input-group-addon-border-width);
  border-color: $input-group-addon-border-color;
  border-style: $input-group-addon-border-style;
  border-radius: rem($input-radius);
  padding: array-magic($input-group-addon-padding);
  min-width: rem($input-min-height);
}
