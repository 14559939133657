.traffic-light-popover {
  height: rem(27);
  width: rem(16);
}

.traffic-light {
  width: rem(6);
  height: rem(6);
  border-radius: rem(3);
  background-color: get-color(divider);
  box-shadow: 0 rem(9) 0 get-color(divider), 0 rem(18) 0 get-color(divider);
  mix-blend-mode: multiply;

  &.is-low {
    background-color: get-color(divider);
    box-shadow: 0 rem(9) 0 get-color(divider), 0 rem(18) 0 $traffic-low;
  }

  &.is-normal {
    background-color: get-color(divider);
    box-shadow: 0 rem(9) 0 $traffic-normal, 0 rem(18) 0 get-color(divider);
  }

  &.is-high {
    background-color: $traffic-high;
    box-shadow: 0 rem(9) 0 get-color(divider), 0 rem(18) 0 get-color(divider);
  }
}
