@use "sass:math";

.data-list {
  display: flex;
  flex-direction: column;
  background-color: get-color(background);
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  backface-visibility: visible;

  * {
    backface-visibility: visible;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.data-list-row {
  min-height: 0;
  display: flex;
  padding: 0;
  justify-content: space-between;

  .data-list-cell {
    &:first-child {
      border-radius: array-magic(4 0 0 4);
    }
    &:last-child {
      border-radius: array-magic(0 4 4 0);
    }
  }
}

.data-list-cell-group {
  flex-basis: 10%;
  flex-grow: 1;
}

.data-list-cell {
  display: flex;
  flex-basis: 20em;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  padding: rem(8);
  background-color: get-color(background);
  font-size: rem(11);

  &.declined {
    text-decoration: line-through;
  }

  select.input {
    height: rem(32);
    mix-blend-mode: multiply;
  }

  .tag {
    mix-blend-mode: multiply;
  }
}

.data-list-cell_column {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.data-list-body {
  min-height: 0;

  .alert {
    margin: rem(math.div($grid-gutter, 2));
  }

  > div {
    margin-top: rem(4) 0;
  }

  > div,
  > .data-list-row {
    &:nth-child(even) {
      .data-list-cell {
        background-color: get-color(background, lightest);
      }
    }

    &:hover,
    &.is-active {
      .data-list-cell {
        background: var(--areaHoverBackground);
      }
    }
  }
}

.data-list-head {
  min-height: 0;

  .data-list-row {
    &:hover {
      .data-list-cell {
        background: inherit;
      }
    }
  }

  .data-list-cell {
    font-size: rem(10);
    text-transform: uppercase;
    font-weight: $is-bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    .btn {
      font-size: rem($base-font-size);
    }
  }

  .data-field_menu {
    background-color: get-color(background) !important;
  }
}

.is-sorted-indicator {
  margin-left: rem(8);
  height: rem(12);
  position: relative;
  width: rem(12);
  align-self: center;
  flex-grow: 0;

  &::after,
  &::before {
    content: "";
    position: absolute;
    border: rem(4) solid transparent;
    left: 50%;
    margin-left: rem(-4);
  }

  &::before {
    border-bottom-color: get-color(text, lightest);
    top: rem(-4);
  }

  &::after {
    border-top-color: get-color(text, lightest);
    bottom: rem(-4);
  }
}
