.whiteboard-card {
  background-color: get-color(background);
  padding: rem(32);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  box-shadow: inset 0 -1px 0 rgba(#000, 0.1);

  .advanced-search-pop {
    right: 0;
    transform: unset;
  }
}

.sticky-title {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 16px 32px;
  margin: 0 -16px;
  background-color: rgba($background, 0.96);
  box-shadow: inset 0 -1px 0 get-color(divider);
}
