#ngProgress {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

// would be much nicer like this, but not possible with current process
// #ngProgress {
//   height: rem(4) !important;
//   border-radius: 100vh;
//   user-select: none;

//   &::after {
//     content: "";
//     transform: translatex(-50%) translateY(-50%);
//     z-index: -1;
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     width: 100%;
//     max-width: rem(240);
//     height: rem(24 * 2 + 4);
//     border-radius: 100vh;
//     box-shadow: inset 0 0 0 rem(24) $background-lightest;
//     background-color: $input-background;
//   }
// }

// #ngProgress-container {
//   transform: translatex(-50%) translateY(-50%);
//   z-index: 1000;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   width: 100%;
//   max-width: rem(240);
//   height: rem(24 * 2 + 4);
//   user-select: none;
//   padding: rem(24);
// }
