/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group modifier
///
/// @param {map} $is-radius-options
///
/// @require {function} rem
///
/// @deprecated since 10.0.0 `.has-#`

@mixin _is-radius($radius) {
  border-radius: $radius;

  &-top {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }

  &-right {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
  }

  &-bottom {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  &-left {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  }

  &-tr {
    border-top-right-radius: $radius;
  }

  &-br {
    border-bottom-right-radius: $radius;
  }

  &-tl {
    border-top-left-radius: $radius;
  }

  &-bl {
    border-bottom-left-radius: $radius;
  }
}

.has-radius,
.is-radius {
  $_states: map-keys($is-radius-options);
  $_values: map-values($is-radius-options);

  @each $_state in $_states {
    $_i: index($_states, $_state);
    $_v: nth($_values, $_i);
    $_c: "-";

    @if $_state == "" {
      $_c: "";
    }

    &#{$_c}#{$_state} {
      @include _is-radius(rem($_v));
    }
  }
}

.is-round {
  @include _is-radius(100vw);
}
