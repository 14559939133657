.mg-deck-sub {
  flex-direction: column;
  background-color: get-color(background);
  position: relative;
  box-shadow: inset 1px 0 0 get-color(divider);
  height: 100vh;

  .mg-deck__body {
    iframe {
      border: 1px solid get-color(divider);
    }
  }
}
