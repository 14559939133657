@import "atoms/atoms";

@import "molecules/molecules";
@import "organisms/organisms";

@import "partials/partials";
@import "templates/templates";

@import "modifier";

/*kill the transitions on any descendant elements of .notransition*/
.notransition * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

:root {
  overflow-y: auto;
}

.area-color {
  color: var(--areaColor);
}
