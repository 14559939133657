/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group modifier
///
/// @param {map} $is-placed-map

@mixin _is-placed($p) {
  &-n {
    @include set-breakpoints {
      left: 50%;
      top: rem($p);
      transform: translateX(-50%);
    }
  }

  &-ne {
    @include set-breakpoints {
      right: rem($p);
      top: rem($p);
    }
  }

  &-e {
    @include set-breakpoints {
      right: rem($p);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-se {
    @include set-breakpoints {
      bottom: rem($p);
      right: rem($p);
    }
  }

  &-s {
    @include set-breakpoints {
      bottom: rem($p);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-sw {
    @include set-breakpoints {
      bottom: rem($p);
      left: rem($p);
    }
  }

  &-w {
    @include set-breakpoints {
      left: rem($p);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-nw {
    @include set-breakpoints {
      left: rem($p);
      top: rem($p);
    }
  }

  &-0 {
    @include set-breakpoints {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}

$_keys: map-keys($is-placed-map);
$_values: map-values($is-placed-map);

@each $_key in $_keys {
  $_i: index($_keys, $_key);
  $_v: nth($_values, $_i);

  .#{$_key} {
    @include _is-placed($_v);
  }
}
