.kpis-info-list {
  list-style: none;
  padding: 0;

  & > li {
    padding: rem(4);
    background-color: get-color(background);
    width: auto;

    &:nth-child(even) {
      background-color: get-color(background, lightest);
    }

    &.show-more {
      text-align: center;
      margin-top: rem(16);
      background-color: get-color(background);
    }
  }
}
