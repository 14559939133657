/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group molecules
///
/// @param {number} $card-radius [4]
/// @param {color} $card-background [$background]
/// @param {color} $card-color [$text]
/// @param {array} $card-padding [24]
/// @param {array} $card-spacing [0]
/// @param {[1-24] | false} $card-elevation [4]
/// @param {array} $card-style
///
/// @require {mixin} inject-style
/// @require {mixin} elevation
/// @require {function} array-magic

.card {
  position: relative;
  z-index: 1;
  @if $card-elevation {
    @include elevation($card-elevation);
  }
  @include inject-style($card-style);
  background: $card-background;
  color: $card-color;
  border-radius: array-magic($card-radius);
  padding: array-magic($card-padding);
  margin: array-magic($card-spacing);
}
