.query-highlight {
  width: 100%;
  padding: 0;
  margin: array-magic(16 0 4);

  .query-highlight-list {
    list-style: none;
    display: inline-flex;
    flex-wrap: wrap;
    padding: array-magic(0 4);
    border-radius: rem(2);
    margin: 0;
    background-color: get-color(background, invert);
    font-size: rem(10);
    color: get-color(text, invert);

    li {
      padding: rem(4);
    }
  }
}
