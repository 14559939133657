/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group modifier
///
/// @param {map} $is-padding-options [$is-padding-options]
///
/// @require {mixin} set-breakpoints
/// @require {function} get-space
///
/// @deprecated since 10.0.0 `.has-#`

.has-padding,
.is-padding {
  @each $_spacing, $_h in $is-padding-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        padding: get-space($_spacing);
      }
    }
  }
}

.has-padding-middle,
.is-padding-middle {
  @each $_spacing, $_h in $is-padding-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        padding-bottom: get-space($_spacing);
        padding-top: get-space($_spacing);
      }
    }
  }
}

.has-padding-top,
.is-padding-top {
  @each $_spacing, $_h in $is-padding-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        padding-top: get-space($_spacing);
      }
    }
  }
}

.has-padding-bottom,
.is-padding-bottom {
  @each $_spacing, $_h in $is-padding-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        padding-bottom: get-space($_spacing);
      }
    }
  }
}

.has-padding-center,
.is-padding-center {
  @each $_spacing, $_h in $is-padding-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        padding-left: get-space($_spacing);
        padding-right: get-space($_spacing);
      }
    }
  }
}

.has-padding-left,
.is-padding-left {
  @each $_spacing, $_h in $is-padding-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        padding-left: get-space($_spacing);
      }
    }
  }
}

.has-padding-right,
.is-padding-right {
  @each $_spacing, $_h in $is-padding-options {
    &-#{$_spacing} {
      @include set-breakpoints {
        padding-right: get-space($_spacing);
      }
    }
  }
}
