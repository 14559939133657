.quicklist-droparea {
  cursor: pointer;
  border: array-magic($file-upload-border);
  color: get-color(text, lighter);
  padding: array-magic($file-upload-padding);
  margin: array-magic($file-upload-spacing);
  border-radius: array-magic($file-upload-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    color: get-color(text, lighter);
  }

  &:hover {
    color: get-color(text);
    border-color: get-color(text);

    a {
      color: get-color(text);
    }
  }

  &.is-active {
    background-color: get-color(state, hover);
  }
}
