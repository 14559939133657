/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @deprecated `.flex-spacer` since 10.0.0
///
/// @require {mixin} set-breakpoints

.flex-spacer,
.flex-space {
  @include set-breakpoints {
    flex-grow: 1;
  }
}
