/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @param {number} $btn-wide -  min width for .btn_wide button
/// @param {number} $btn-small-wide - min width for .btn_wide.btn_small button
/// @param {number} $btn-big-wide - min width for .btn_wide.btn_big button

.btn_wide {
  min-width: rem($btn-wide);

  &.btn_small {
    min-width: rem($btn-small-wide);
  }

  &.btn_big {
    min-width: rem($btn-big-wide);
  }
}

// full-width buttons
.btn_block {
  display: flex;
  flex: auto;

  @at-root [type="button"]#{&},
    [type="submit"]#{&},
    button#{&} {
    width: 100%;
  }
}
