/// @author Bernhard Ruoff
/// @since 9.1.0
/// @access public
/// @group atoms
///
/// @param {map} $img-crop-ratio
@use "sass:math";

@each $_postfix, $_array in $img-crop-ratio {
  .img-crop-#{$_postfix} {
    padding-top: math.div(100%, nth($_array, 1)) * nth($_array, 2);
    position: relative;
    width: 100%;

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      width: 100%;
      vertical-align: top;
    }
  }
}
