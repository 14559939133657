// @import url('https://icons.entrecode.de/ec-icons-5.0.0.min.css');
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&display=swap");

@font-face {
  font-family: "TradeGothic LT Extended";
  src: url("../fonts/Linotype-TradeGothicLTExtended.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

$body-font-family: "IBM Plex Sans", sans-serif;
$headings-font-family: "IBM Plex Sans", sans-serif;

$ci-font: "TradeGothic LT Extended", sans-serif;
$public-font: "Helvetica Neue", Helvetica, sans-serif;

$is-light: 400;
$is-normal: 400;
$is-medium: 500;
$is-bold: 600;

$background: #ffffff;
$text: #3b5462 !default;
$link: #b58d4f !default;
$super: #b58d4f;
$minor: #edf2f7;
$minor-contrast: $text;
$highlight: #fff7cc;

$candidate: #448ef6;
$client: #fac70b;
$employee: #df0e62;
$project: #12e2a3;
$textkernel: #00bcd4;

$text-light: lighten($text, 20%);
$text-lighter: lighten($text, 40%);
$text-lightest: lighten($text, 60%);

//$background-lightest: #f7f8f8;
$background-lightest: #f9fafb;

$base-divider-color: darken(#f7f8f8, 4%);
$state-hover: #edf2f7;
$state-error: #ffbbbb;

$elevation-color: $text;

:root {
  --areaColor: #3b5461;
  --areaHoverBackground: #fff7cc;
}

.candidate,
.area-candidates {
  --areaColor: #448ef6;
  --areaHoverBackground: #def5ff;
}

.client,
.area-clients {
  --areaColor: #fac70b;
  --areaHoverBackground: #fff7cc;
}

.project,
.area-projects {
  --areaColor: #12e2a3;
  --areaHoverBackground: #dafff4;
}

.employee,
.area-employees {
  --areaColor: #df0e62;
  --areaHoverBackground: #ffe8f1;
}

$grid-gutter: 24;

$alert-style: (
  font-size: 0.875em,
);

$alert-padding: 12 16;

$traffic-low: #d50000;
$traffic-normal: #ffc107;
$traffic-high: #00c853;

$_notification: #e50508;

$is-ink: (
  "notification": $_notification,
);

$base-font-size: 12;
$base-line-height: 1.2;
$vertical-rhythm: 8;
$headings-line-height: $base-line-height;

$type-scale: (
  h1: 1.5,
  h2: 1.5,
  h3: 1.25,
  h4: 1.25,
  h5: 1,
  h6: 1,
  is-giga: 1.5,
  is-mega: 1.25,
  is-kilo: 1,
  is-lead: 1.25,
  p: 1,
);

$type-scale-bp: (
  xs: 0.875,
  md: 1,
  xxl: 1,
);

$headings-weight: $is-bold;

$btn-min-height: 32;
$btn-font-size: 10;
$btn-line-height: 16;
$btn-weight: $is-bold;

$btn-small-font-size: 10;
$btn-small-line-height: 16;
$btn-small-min-height: 28;

$btn-background: $text !default;
$btn-color: #e1c395 !default;

$btn-style: (
  text-transform: uppercase,
);

$btn-clear-hover-background: $minor;

$tag-background: #c2d3dc;

$tag-styles: (
  "invert": (
    $background,
    $text,
    $background,
  ),
);

$tooltip-font-size: 11;
$tooltip-rounded: true;

$tag-font-size: 10;
$tag-line-height: 16;
$tag-min-height: 24;
$tag-weight: $is-bold;

$tag-small-font-size: 10;
$tag-small-line-height: 12;
$tag-small-min-height: 16;

$field-group-label-font-size: 10;
$field-group-spacing: 0 0 16;

$input-min-height: 40;
$input-padding-ratio: 1;

//$input-group-addon-background: $background;

$xui-list-item-gutter: 8;
$xui-list-item-background: $background;
$xui-list-item-hover-background: var(--areaHoverBackground);

$xui-checkbox-font-size: 10;
$xui-checkbox-line-height: 16;
$xui-checkbox-height: 16;
$xui-checkbox-radius: 4;
$xui-checkbox-check-checked-color: #00cf00;

$deck-header-padding: 8 16;
$deck-body-padding: 16;

$xui-checkbox-style: (
  box-shadow: inset 0 0 0 1px mix($text, $background, 20%) !important,
);

$nav-item-padding: 4;

$input-invalid-style: (
  color: get-color(error, safe),
);

$input-valid-style: (
  color: get-color(success, safe),
);

$titlebar-padding: 8 0;

$giga-spacing: 0 0 8 0;
$mega-spacing: $giga-spacing;
$kilo-spacing: $giga-spacing;
$h1-spacing: $giga-spacing;
$h2-spacing: $giga-spacing;
$h3-spacing: $giga-spacing;
$h4-spacing: $giga-spacing;
$h5-spacing: $giga-spacing;
$h6-spacing: $giga-spacing;

$card-elevation: 3;

$meatball-size: 8;
$meatball-position-top: 4;
$meatball-position-right: 4;
$meatball-border-color: $_notification;
$meatball-background: $_notification;
