/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group organism
///
/// @param {array} $titlebar-spacing [0]
/// @param {array} $titlebar-padding [0]
/// @param {array} $titlebar-style
///
/// @deprecated since 10.0.0 `.title`

.title,
.titlebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include inject-style($titlebar-style);
  margin: array-magic($titlebar-spacing);
  padding: array-magic($titlebar-padding);

  > .nav,
  > .btn {
    align-self: flex-start;
  }

  > .tag {
    align-self: baseline;
  }
}
