#textkernelSearchAndMatch {
  width: 100%;
  min-height: 600px;
  height: 100%;
}

textkernel-open-search {
  display: flex;
  flex-direction: column;
  height: 100%;
}
