.tooltip {
  //display: none;
  letter-spacing: 0;
  pointer-events: none;
  position: absolute;
  text-align: center;
  text-decoration: none;
  text-transform: initial;
  white-space: nowrap;
  z-index: 100;
  font-family: $body-font-family;
  @include inject-style($tooltip-style);
  @include metrics(
    $tooltip-font-size,
    $tooltip-line-height,
    $tooltip-min-height,
    $padding-ratio: $tooltip-padding-ratio
  );
  background: $tooltip-background;
  color: $tooltip-color;
  font-weight: $tooltip-weight;

  @if $tooltip-round == true {
    border-radius: 100vh;
  } @else {
    border-radius: array-magic($tooltip-radius);
  }
}
