.mg-page {
  flex: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: 64px auto;
  grid-template-areas: "header body";
  height: 100vh;
}

.mg-page-header {
  grid-area: header;
}

.mg-page-body {
  overflow: hidden;
  grid-area: body;
  display: flex;

  & > *:not(.kiosk, .users-help-sidebar, .mg-deck-wrapper, .show-deck) {
    width: 100%;
  }
}

#mega {
  display: flex;
  root-view {
    flex-grow: 1;
  }
}

#root {
  flex: 1;
}
