/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group modifier
///
/// @param {map} $is-layer [1, 10, 100, 9999]

.is-layer {
  @each $l in $is-layer {
    &-#{$l} {
      @include set-breakpoints {
        z-index: $l;
      }
    }
  }
}
