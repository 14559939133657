/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @param {color} $fieldset-background [transparent]
/// @param {array} $fieldset-padding [0]
/// @param {array} $fieldset-spacing [0 0 16]
/// @param {array} $fieldset-border [0]
/// @param {array} $fieldset-style

.fieldset {
  @include inject-style($fieldset-style);
  padding: array-magic($fieldset-padding);
  margin: array-magic($fieldset-spacing);
  background: $fieldset-background;
  border: array-magic($fieldset-border);
}
