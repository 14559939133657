/// @author Bernhard Ruoff
/// @since 9.1.0
/// @access public
/// @group molecules inline

.well {
  > p {
    &:last-child,
    &:only-child {
      margin: 0;
    }
  }
}
