.dropdown-menu {
  list-style: none;
  margin: 0;
  position: absolute;
  top: 100%;
  @include is-scrollable(y, contain, $behavior: auto);
  @include inject-style($dropdown-options-style);
  background: $dropdown-options-background;
  border-radius: array-magic($dropdown-options-radius);
  @if $dropdown-options-elevation {
    @include elevation($dropdown-options-elevation);
  }
  color: set-contrast($dropdown-options-background);
  max-height: rem($dropdown-options-max-height);
  padding: array-magic($dropdown-options-padding);
  z-index: 9999;
  max-height: rem(320);

  .dropdown-menu__option,
  .uib-typeahead-match {
    border-radius: rem(4);
    &.is-active,
    &.active,
    &:hover {
      background-color: get-color(state, hover);
    }
  }

  .dropdown-menu__link {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    padding: rem(8);
    font-weight: $is-bold;
    font-size: rem($base-font-size);
    color: $text;

    &.is-disabled,
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  .divider {
    margin: rem(8) 0;
  }
}

.daterangepicker.dropdown-menu {
  display: none;
  max-height: initial;
}

.has-dropdown-indicator {
  &::after {
    content: "\205D";
    font-family: sans-serif;
    position: absolute;
    top: 50%;
    right: rem(4);
    color: get-color(text, light);
    font-size: rem(16);
    line-height: rem(16);
    font-weight: normal;
    opacity: 0.8;
    margin-top: rem(-8);
  }
}

.has-dropdown-indicator:hover {
  &::after {
    opacity: 1;
  }
}
