/// @author Bernhard Ruoff
/// @since 10.0.0
/// @group flex-grid
/// @access public
///
/// @param {number} $grid-gutter [32]
/// @param {number} $grid-small-gutter [16]
/// @param {number} $grid-smaller-gutter [4]
///
/// @require {mixins} set-data-breakpoints()
/// @require {functions} rem()
@use "sass:math";

[data-grid] {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(math.div($grid-gutter, -2));
  margin-right: rem(math.div($grid-gutter, -2));

  @at-root ul#{&} {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  > [data-col] {
    padding-left: rem(math.div($grid-gutter, 2));
    padding-right: rem(math.div($grid-gutter, 2));
  }
}

@include set-data-breakpoints("grid", "default-gutter") {
  margin-left: rem(math.div($grid-gutter, -2));
  margin-right: rem(math.div($grid-gutter, -2));

  > [data-col] {
    padding-left: rem(math.div($grid-gutter, 2));
    padding-right: rem(math.div($grid-gutter, 2));
  }
}

@include set-data-breakpoints("grid", "small-gutter") {
  margin-left: rem(math.div($grid-small-gutter, -2));
  margin-right: rem(math.div($grid-small-gutter, -2));

  > [data-col] {
    padding-left: rem(math.div($grid-small-gutter, 2));
    padding-right: rem(math.div($grid-small-gutter, 2));
  }
}

@include set-data-breakpoints("grid", "smaller-gutter") {
  margin-left: rem(math.div($grid-smaller-gutter, -2));
  margin-right: rem(math.div($grid-smaller-gutter, -2));

  > [data-col] {
    padding-left: rem(math.div($grid-smaller-gutter, 2));
    padding-right: rem(math.div($grid-smaller-gutter, 2));
  }
}

@include set-data-breakpoints("grid", "no-gutter") {
  margin-left: 0;
  margin-right: 0;

  > [data-col] {
    padding-left: 0;
    padding-right: 0;
  }
}

$_justify-content: (
  "center": "center",
  "start": "flex-start",
  "end": "flex-end",
);

@include set-data-map-breakpoints(grid, justify-content, $_justify-content);
