#companyOrgChart {
  width: 100%;
  min-height: 600px;
  height: 100%;

  .orgchart {
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: center;
    background-image: none;

    .node::before,
    .node::after,
    .hierarchy::before {
      background-color: #000;
      border-top-color: #000;
    }

    .node {
      &:hover {
        background-color: rgba(220, 220, 220, 0.5);
      }

      .title {
        width: auto;
        min-width: 130px;
        justify-content: start;

        .oci {
          margin-right: 20px;
        }
      }
    }

    .node .mg-org-chart-node {
      color: #000;
      border: 1px solid #000;
      padding: rem(7);
      text-align: left;
      max-width: 180px;

      &.is-company {
        max-width: max-content;
      }

      .title,
      .content {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: #000;
        text-align: left;
      }

      .content {
        overflow: hidden;
      }

      .title {
        padding: 0px 0px rem(4);
      }
    }
  }
}
