/// @author Bernhard Ruoff
/// @since 7.0.0
/// @access public
/// @group form mixins

// scss-lint:disable VendorPrefix
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}
