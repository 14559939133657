/// @author Bernhard Ruoff
/// @since 9.0.6
/// @access public
/// @group organism
///
/// @require {function} rem
/// @require {function} array-magic
/// @require {mixin} inject-style

// @param {color} $xui-list-group-background [none]
// @param {array} $xui-list-group-padding [0]
// @param {array} $xui-list-group-spacing [0 0 16]
// @param {number} $xui-list-group-radius [0]
// @param {array} $xui-list-group-style
.xui-list-group {
  width: 100%;
  @include inject-style($xui-list-group-style);
  border-radius: array-magic($xui-list-group-radius);
  padding: array-magic($xui-list-group-padding);
  margin: array-magic($xui-list-group-spacing);
  background: $xui-list-group-background;

  // @param {color} $xui-list-group-header-background [none]
  // @param {color} $xui-list-group-header-color [inherit]
  // @param {array} $xui-list-group-header-padding [0]
  // @param {array} $xui-list-group-header-spacing [0 0 16]
  // @param {number} $xui-list-group-header-radius [0]
  // @param {array} $xui-list-group-header-style
  &__header {
    @include inject-style($xui-list-group-header-style);
    border-radius: array-magic($xui-list-group-header-radius);
    padding: array-magic($xui-list-group-header-padding);
    margin: array-magic($xui-list-group-header-spacing);
    background: $xui-list-group-header-background;
    color: $xui-list-group-header-color;
  }
}

// @param {color} $xui-list-background [none]
// @param {color} $xui-list-color [inherit]
// @param {array} $xui-list-padding [0]
// @param {array} $xui-list-spacing [0 0 16]
// @param {number} $xui-list-radius [0]
// @param {array} $xui-list-style
.xui-list {
  list-style: none;
  @include inject-style($xui-list-style);
  border-radius: array-magic($xui-list-radius);
  padding: array-magic($xui-list-padding);
  margin: array-magic($xui-list-spacing);
  background: $xui-list-background;
  color: $xui-list-color;
}

// @param {color} $xui-list-item-background [transparent]
// @param {color} $xui-list-item-color [none]
// @param {array} $xui-list-item-padding [8]
// @param {array} $xui-list-item-spacing [0]
// @param {array} $xui-list-item-gutter [$base-spacing]
// @param {number} $xui-list-item-radius [4]
// @param {array} $xui-list-item-style
.xui-list-item {
  align-items: center;
  display: flex;
  @include inject-style($xui-list-item-style);
  border-radius: array-magic($xui-list-item-radius);
  padding: array-magic($xui-list-item-padding);
  margin: array-magic($xui-list-item-spacing);
  background: $xui-list-item-background;
  color: $xui-list-item-color;

  // @param {color} $xui-list-item-active-background [$xui-list-item-hover-background]
  // @param {color} $xui-list-item-active-color [$xui-list-item-hover-color]
  // @param {array} $xui-list-item-hover-style
  &:hover {
    @include inject-style($xui-list-item-hover-style);
    background: $xui-list-item-hover-background;
    color: $xui-list-item-hover-color;
  }

  // @param {color} $xui-list-item-active-background [$xui-list-item-hover-background]
  // @param {color} $xui-list-item-active-color [$xui-list-item-hover-color]
  // @param {array} $xui-list-item-active-style
  &.is-active {
    @include inject-style($xui-list-item-active-style);
    background: $xui-list-item-active-background;
    color: $xui-list-item-active-color;
  }

  &__start {
    margin-right: rem($xui-list-item-gutter);
  }

  &__label {
    margin-right: rem($xui-list-item-gutter);
  }

  &__end {
    margin-left: auto;
    margin-right: 0;
  }
}
