.ui-select-container {
  @extend .input;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 rem(8) 0 rem(2);

  &.ui-select-multiple {
    .ui-select-search {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100%;
      cursor: pointer;
      z-index: 0;
    }

    .ui-select-match {
      .ui-select-match-item {
        position: relative;
      }
    }

    &.open {
      .ui-select-match {
        display: none;
      }

      .ui-select-search {
        cursor: initial;
      }
    }
  }

  &:not(.ui-select-multiple) {
    .ui-select-match {
      flex-grow: 1;
      padding-left: rem(4);
      overflow: hidden;
    }

    .ui-select-toggle {
      flex-grow: 1;
      border: 0;
      padding: 0;
      margin: 0;
      justify-content: space-between;
      background-color: transparent;
      color: $input-placeholder-color;
      font-size: rem($input-font-size);
      line-height: rem($input-line-height);
      font-weight: $is-normal;
      text-transform: initial;

      .caret {
        display: none;
      }

      .ui-select-match-text {
        @include ellipsis;
        color: $input-color;
        text-align: left;
      }

      .btn.btn-xs.btn-link {
        margin: 0;
        min-height: initial;
        min-width: initial;
        border: none;
        padding: 0;
        height: initial;
        border-radius: 0;
        position: relative;
        color: get-color(text, light);
        background-color: transparent;
        pointer-events: all;

        .glyphicon.glyphicon-remove {
          font-style: normal;
          font-weight: $is-bold;

          &::before {
            content: "\2715";
            color: get-color(text, light);
          }
        }
      }
    }
  }

  .ui-select-match {
    &:empty {
      display: none;
    }
  }

  .ui-select-search {
    -webkit-appearance: none;
    outline: none !important;
    border: none;
    padding: 0 rem(8);
    margin: 0;
    font-family: $body-font-family;
    font-size: rem($input-font-size);
    line-height: rem($input-line-height);
    width: 100%;
    align-self: stretch;
    background-color: transparent;
    border-radius: rem(2);
  }

  .ui-select-no-choice {
    .alert {
      margin: 0 !important;
      padding: rem(8);
    }
  }

  .ui-select-choices {
    @extend .dropdown-options;
    visibility: inherit;
    opacity: 1;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: rem(4);
    padding: 0;
    z-index: 100;
    max-height: rem(240);
    overflow-y: auto;
  }

  .ui-select-choices {
    padding: rem(8);
  }

  .ui-select-placeholder {
    @include ellipsis;
    color: get-color(text, light);
  }

  .ui-select-choices-group {
    width: 100%;
    margin-bottom: rem(8);
  }

  .ui-select-choices-group-label {
    font-size: rem(10);
    color: get-color(text, light);
    padding: rem(4) rem(8);
    font-weight: $is-bold;
  }

  .ui-select-choices-row {
    width: 100%;
    padding: rem(4);

    &:hover,
    &.active,
    &.is-active {
      .ui-select-choices-row-inner {
        background-color: get-color(state, hover);
        color: get-color(link);
      }
    }

    &.disabled {
      .ui-select-choices-row-inner {
        color: get-color(text, light);
      }
    }

    .ui-select-choices-row-inner {
      padding: rem(4) rem(8);
      border-radius: rem(2);
      min-height: rem(32);
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: rem(16);

      .ui-select-highlight {
        color: get-color(link);
        font-weight: bold;
      }

      .ui-select-synonym {
        margin: 0;
        margin-top: rem(1);
        padding-left: rem(1);
        small {
          display: inline-block;
          white-space: normal;
        }
      }

      small {
        font-size: rem(10);
        line-height: rem(16);
        display: block;
        color: get-color(text, light);
        @include ellipsis;
      }
    }
  }

  .ui-select-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
  }
}

.ui-select-match {
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  .ui-select-toggle {
    margin: rem(2);
  }
}

.ui-select-match-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-bottom;
  @include inject-style($tag-style);
  @include metrics($tag-font-size, $tag-line-height, $tag-min-height, $padding-ratio: $tag-padding-ratio);
  margin: array-magic($tag-spacing);
  background: $tag-background;
  color: $tag-color;
  border-radius: array-magic($tag-radius);
  font-family: $tag-font-family;
  font-weight: $tag-weight;
  text-transform: initial;
  margin: rem(2);

  .ui-select-match-close {
    display: inline-block;
    width: rem(16);
    text-align: center;
    margin-right: rem(-2);
    opacity: 0.4;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  & > * {
    pointer-events: auto !important;
  }
}
