.kpis-list {
  list-style: none;
  margin: 0 rem(8);
  padding: 0;
  display: flex;

  li {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0;
    min-width: rem(28);

    .kpis-list__item {
      display: flex;
      border-radius: 0;
      border: 1px solid get-color(divider);
      border-right: 0;
      background-color: get-color(background);
      padding: rem(8) rem(16);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: get-color(text, light);

      &.is-active {
        color: get-color(text);
      }

      &.is-selected {
        background-color: get-color(background, lightest);
      }

      &.is-selectable {
        cursor: pointer;
        color: get-color(link);
        transition: all 0.2s;

        &:hover {
          background-color: get-color(background, lightest);
        }
      }

      &.kpis-list__item-top,
      &.kpis-list__item-bottom {
        width: 100%;
        padding: rem(8) rem(16);
      }

      &.kpis-list__item-middle {
        width: 100%;
        display: flex;
        padding: 0;
        min-height: 48px;

        kpi-details {
          width: 100%;
          flex-grow: 1;
          display: flex;
        }

        .kpis-list {
          flex-grow: 1;

          .kpis-list__item {
            padding-left: 0;
            padding-right: 0;
          }

          li:first-child > .kpis-list__item {
            border-left: 0;
          }

          li > .kpis-list__item {
            border-radius: 0;
            border-right: 0;
            font-size: 0.7em !important;
            height: 100%;
            border-top: 0;
            border-bottom: 0;
          }
        }
      }

      &.kpis-list__item-top,
      &.kpis-list__item-middle {
        border-bottom: 0;
      }

      &.kpis-list__item-top + .kpis-list__item,
      &.kpis-list__item-middle + .kpis-list__item {
        border-top: 1px solid get-color(divider);
      }

      small {
        font-size: rem(10);
        line-height: rem(14);
        display: inline-block;
        white-space: nowrap;
      }

      strong {
        font-size: rem(14);
        line-height: rem(14);
        display: inline-block;
        font-weight: $is-bold;
        white-space: nowrap;
      }
    }

    &:first-child {
      > .kpis-list__item {
        border-radius: rem(4) 0 0 rem(4);
      }
    }

    &:last-child {
      > .kpis-list__item {
        border-radius: 0 rem(4) rem(4) 0;
        border-right: 1px solid get-color(divider);
      }
    }

    &:first-child {
      > .kpis-list__item-top {
        border-radius: rem(4) 0 0 0;
      }

      > .kpis-list__item-middle {
        border-radius: 0;
      }

      > .kpis-list__item-bottom {
        border-radius: 0 0 0 rem(4);
      }
    }

    &:last-child {
      > .kpis-list__item-top {
        border-radius: 0 rem(4) 0 0;
      }

      > .kpis-list__item-middle {
        border-radius: 0;
      }

      > .kpis-list__item-bottom {
        border-radius: 0 0 rem(4) 0;
      }
    }
  }

  &_small {
    li {
      width: rem(24);

      .kpis-list__item {
        padding: rem(4);

        strong {
          font-size: rem(12);
          line-height: rem(12);
        }

        small {
          font-size: rem(8);
          line-height: rem(12);
        }
      }
    }
  }
}

.kpis-list-footer {
  margin-top: rem(8);
}

.dashboard-card {
  .kpis-list {
    margin: 0;

    li {
      max-width: initial;
    }

    .kpis-list__item {
      background-color: transparent;
    }
  }
}

.kpis-list_dashboard {
  > li:nth-child(even) {
    background-color: get-color(background, lightest);
  }
}
