.ixo {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &.pulse {
    animation-name: stretch;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(2);
  }
}
