/// @author Bernhard Ruoff
/// @since 10.0.0
/// @group flex-grid
/// @access public
///
/// @param {map} $_order
/// @param {mixin} set-data-map-breakpoints()
/// @require {function} bp()

$_order: (
  "-1": "-1",
  "first": "-1",
  "last": "1",
);

@include set-data-map-breakpoints(flex-order, order, $_order);

@for $i from 1 through $grid-num-columns {
  [data-flex-order~="#{$i}"] {
    order: #{$i};
  }
}

@each $bp in map-keys($breakpoints) {
  @for $i from 1 through $grid-num-columns {
    [data-flex-order~="#{$i}\@#{$bp}"] {
      @media (min-width: bp(#{$bp})) {
        order: #{$i};
      }
    }
  }
}
