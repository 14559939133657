@charset "UTF-8";

// $ecicons-font-path: 'https://icons.entrecode.de/fonts/';
$ecicons-font-version: "4.0.0";

.mega-icons {
  display: none;
}

@font-face {
  font-family: "ec-icon";
  src: url("../fonts/ec-icon-#{$ecicons-font-version}.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.ec-icon {
  &::before {
    font-family: "ec-icon" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal !important;
    font-variant: normal !important;
    font-style: normal;
    text-transform: none !important;
    text-rendering: optimizeLegibility;
    speak: none;
    display: inline-block;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    font-size: inherit;
    line-height: inherit;
    vertical-align: text-bottom;
  }
}

/// define spacing icon to text
.ec-icon {
  &:before {
    padding-right: 0.5rem;
  }

  &:empty {
    &:before {
      padding-right: 0;
    }
  }

  &.right {
    &:before {
      padding-right: 0;
      padding-left: 0.5rem;
      float: right;
    }
  }
}

.ec-icon {
  &.add:before {
    content: "\61";
  }
  &.add-circle:before {
    content: "\62";
  }
  &.alarm:before {
    content: "\63";
  }
  &.alarm-clock:before {
    content: "\64";
  }
  &.alarm-silent:before {
    content: "\65";
  }
  &.align-center:before {
    content: "\66";
  }
  &.align-justify:before {
    content: "\67";
  }
  &.align-left:before {
    content: "\68";
  }
  &.align-right:before {
    content: "\69";
  }
  &.all-caps:before {
    content: "\6a";
  }
  &.brackets:before {
    content: "\6b";
  }
  &.angle-tool:before {
    content: "\6c";
  }
  &.arrange-1-to-9:before {
    content: "\6d";
  }
  &.arrange-9-to-1:before {
    content: "\6e";
  }
  &.arrange-a-to-z:before {
    content: "\6f";
  }
  &.arrange-z-to-a:before {
    content: "\70";
  }
  &.arrow-down:before {
    content: "\71";
  }
  &.arrow-down-left:before {
    content: "\72";
  }
  &.arrow-down-right:before {
    content: "\73";
  }
  &.arrow-left:before {
    content: "\74";
  }
  &.arrow-return:before {
    content: "\75";
  }
  &.arrow-right:before {
    content: "\76";
  }
  &.arrow-up:before {
    content: "\77";
  }
  &.arrow-up-left:before {
    content: "\78";
  }
  &.arrow-up-right:before {
    content: "\79";
  }
  &.artboard:before {
    content: "\7a";
  }
  &.asterisk:before {
    content: "\41";
  }
  &.at-sign:before {
    content: "\42";
  }
  &.bank:before {
    content: "\43";
  }
  &.bank-note:before {
    content: "\44";
  }
  &.banking-debit-machine:before {
    content: "\45";
  }
  &.barcode:before {
    content: "\46";
  }
  &.trash:before {
    content: "\47";
  }
  &.binoculars:before {
    content: "\48";
  }
  &.bitcoin-coin:before {
    content: "\49";
  }
  &.bookmark:before {
    content: "\4b";
  }
  &.border-bottom:before {
    content: "\4c";
  }
  &.border-inside:before {
    content: "\4d";
  }
  &.border-inside-vertical:before {
    content: "\4e";
  }
  &.border-inside-horizontal:before {
    content: "\4f";
  }
  &.border-left:before {
    content: "\50";
  }
  &.border-none:before {
    content: "\51";
  }
  &.border-outside:before {
    content: "\52";
  }
  &.border-right:before {
    content: "\53";
  }
  &.border-top:before {
    content: "\54";
  }
  &.box:before {
    content: "\55";
  }
  &.briefcase:before {
    content: "\56";
  }
  &.bus:before {
    content: "\57";
  }
  &.graph-pie:before {
    content: "\59";
  }
  &.scale:before {
    content: "\5a";
  }
  &.whiteboard:before {
    content: "\30";
  }
  &.graph-plot:before {
    content: "\31";
  }
  &.calculator:before {
    content: "\32";
  }
  &.calculator-2:before {
    content: "\33";
  }
  &.calendar:before {
    content: "\34";
  }
  &.calendar-check:before {
    content: "\35";
  }
  &.calendar-close:before {
    content: "\36";
  }
  &.calendar-pencil:before {
    content: "\37";
  }
  &.calendar-refresh-2:before {
    content: "\38";
  }
  &.camera:before {
    content: "\39";
  }
  &.brightness-1:before {
    content: "\21";
  }
  &.camera-off:before {
    content: "\22";
  }
  &.camera-pin:before {
    content: "\23";
  }
  &.camera-refresh:before {
    content: "\24";
  }
  &.car-6:before {
    content: "\25";
  }
  &.dashboard:before {
    content: "\26";
  }
  &.tape:before {
    content: "\27";
  }
  &.center-point:before {
    content: "\28";
  }
  &.chat-bubble-circle:before {
    content: "\29";
  }
  &.chat-bubble-circle-active:before {
    content: "\2a";
  }
  &.chat-bubble-square:before {
    content: "\2b";
  }
  &.chat-bubble-square-active:before {
    content: "\2c";
  }
  &.chat-bubble-square-2:before {
    content: "\2d";
  }
  &.chat-bubbles-square:before {
    content: "\2e";
  }
  &.check-box:before {
    content: "\2f";
  }
  &.cloud:before {
    content: "\3b";
  }
  &.cloud-disable:before {
    content: "\3c";
  }
  &.cloud-download:before {
    content: "\3d";
  }
  &.cloud-refresh:before {
    content: "\3e";
  }
  &.cloud-upload:before {
    content: "\3f";
  }
  &.cloud-wifi:before {
    content: "\40";
  }
  &.color-contrast-off:before {
    content: "\5b";
  }
  &.color-contrast-on:before {
    content: "\5d";
  }
  &.color-palette:before {
    content: "\5e";
  }
  &.computer-screen:before {
    content: "\5f";
  }
  &.contact-book:before {
    content: "\60";
  }
  &.content-book:before {
    content: "\7b";
  }
  &.content-bookmark:before {
    content: "\7c";
  }
  &.filter:before {
    content: "\7d";
  }
  &.layers-hide:before {
    content: "\7e";
  }
  &.layers-show:before {
    content: "\5c";
  }
  &.note:before {
    content: "\e000";
  }
  &.content-view-array:before {
    content: "\e001";
  }
  &.content-view-content:before {
    content: "\e002";
  }
  &.content-view-list:before {
    content: "\e003";
  }
  &.content-view-module:before {
    content: "\e004";
  }
  &.crop:before {
    content: "\e005";
  }
  &.cursor-arrow:before {
    content: "\e006";
  }
  &.cursor-hand:before {
    content: "\e007";
  }
  &.cursor-move:before {
    content: "\e008";
  }
  &.cursor-select-area:before {
    content: "\e009";
  }
  &.cursor-tap:before {
    content: "\e00a";
  }
  &.cursor-target:before {
    content: "\e00b";
  }
  &.data-download:before {
    content: "\e00c";
  }
  &.data-syncing:before {
    content: "\e00d";
  }
  &.data-transfer:before {
    content: "\e00e";
  }
  &.data-upload:before {
    content: "\e00f";
  }
  &.delete:before {
    content: "\e010";
  }
  &.dial-pad:before {
    content: "\e011";
  }
  &.diamond:before {
    content: "\e012";
  }
  &.divide:before {
    content: "\e013";
  }
  &.email:before {
    content: "\e014";
  }
  &.email-read:before {
    content: "\e015";
  }
  &.envelope:before {
    content: "\e016";
  }
  &.equaliser:before {
    content: "\e017";
  }
  &.eraser:before {
    content: "\e018";
  }
  &.expand:before {
    content: "\e019";
  }
  &.expand-2:before {
    content: "\e01a";
  }
  &.expand-vertical:before {
    content: "\e01b";
  }
  &.expand-horizontal:before {
    content: "\e01c";
  }
  &.exposure-level:before {
    content: "\e01d";
  }
  &.file-add:before {
    content: "\e01e";
  }
  &.file-copy:before {
    content: "\e01f";
  }
  &.file-new:before {
    content: "\e020";
  }
  &.file-notes-document:before {
    content: "\e021";
  }
  &.file-notes-new:before {
    content: "\e022";
  }
  &.files:before {
    content: "\e023";
  }
  &.files-thumbs:before {
    content: "\e024";
  }
  &.flash:before {
    content: "\e025";
  }
  &.flash-off:before {
    content: "\e026";
  }
  &.flip-down:before {
    content: "\e027";
  }
  &.flip-left:before {
    content: "\e028";
  }
  &.flip-right:before {
    content: "\e029";
  }
  &.flip-up:before {
    content: "\e02a";
  }
  &.focus:before {
    content: "\e02b";
  }
  &.focus-off:before {
    content: "\e02c";
  }
  &.folder-new:before {
    content: "\e02d";
  }
  &.folders:before {
    content: "\e02e";
  }
  &.font-color:before {
    content: "\e02f";
  }
  &.font-size:before {
    content: "\e030";
  }
  &.format-clear:before {
    content: "\e031";
  }
  &.frame:before {
    content: "\e032";
  }
  &.gradient:before {
    content: "\e033";
  }
  &.hash:before {
    content: "\e034";
  }
  &.health-blood-drop:before {
    content: "\e035";
  }
  &.eye-closed:before {
    content: "\e036";
  }
  &.highlight:before {
    content: "\e037";
  }
  &.hourglass:before {
    content: "\e038";
  }
  &.id-card:before {
    content: "\e039";
  }
  &.inbox:before {
    content: "\e03a";
  }
  &.indent-decrease:before {
    content: "\e03b";
  }
  &.indent-increase:before {
    content: "\e03c";
  }
  &.infinity-loop:before {
    content: "\e03d";
  }
  &.interface-alert-circle:before {
    content: "\e03e";
  }
  &.interface-information:before {
    content: "\e03f";
  }
  &.interface-question-mark:before {
    content: "\e040";
  }
  &.intersect:before {
    content: "\e041";
  }
  &.italic:before {
    content: "\e042";
  }
  &.key:before {
    content: "\e043";
  }
  &.laptop:before {
    content: "\e044";
  }
  &.square:before {
    content: "\e045";
  }
  &.lightbulb:before {
    content: "\e046";
  }
  &.link:before {
    content: "\e047";
  }
  &.link-broken:before {
    content: "\e048";
  }
  &.list-bullets:before {
    content: "\e049";
  }
  &.list-number:before {
    content: "\e04a";
  }
  &.location-direction:before {
    content: "\e04b";
  }
  &.location-globe:before {
    content: "\e04c";
  }
  &.location-gps-off:before {
    content: "\e04d";
  }
  &.location-gps-on:before {
    content: "\e04e";
  }
  &.location-plan:before {
    content: "\e04f";
  }
  &.location-map:before {
    content: "\e050";
  }
  &.location-pin-1:before {
    content: "\e051";
  }
  &.location-pin-2:before {
    content: "\e052";
  }
  &.lock-closed:before {
    content: "\e053";
  }
  &.lock-open:before {
    content: "\e054";
  }
  &.leave:before {
    content: "\e055";
  }
  &.harry-potter:before {
    content: "\e056";
  }
  &.measuring-tool:before {
    content: "\e057";
  }
  &.minus-front:before {
    content: "\e058";
  }
  &.mobile-phone:before {
    content: "\e059";
  }
  &.money-note-coin:before {
    content: "\e05a";
  }
  &.move-down:before {
    content: "\e05b";
  }
  &.move-left:before {
    content: "\e05c";
  }
  &.move-right:before {
    content: "\e05d";
  }
  &.move-to-back:before {
    content: "\e05e";
  }
  &.move-to-front:before {
    content: "\e05f";
  }
  &.move-up:before {
    content: "\e060";
  }
  &.music-note:before {
    content: "\e061";
  }
  &.more-hamburger:before {
    content: "\e062";
  }
  &.more-horizontal:before {
    content: "\e063";
  }
  &.more-vertical:before {
    content: "\e064";
  }
  &.new-square:before {
    content: "\e065";
  }
  &.outbox:before {
    content: "\e066";
  }
  &.paint-brush:before {
    content: "\e067";
  }
  &.paint-bucket:before {
    content: "\e068";
  }
  &.paint-palette:before {
    content: "\e069";
  }
  &.paint-roll:before {
    content: "\e06a";
  }
  &.paperclip:before {
    content: "\e06b";
  }
  &.paperplane:before {
    content: "\e06c";
  }
  &.paragraph:before {
    content: "\e06d";
  }
  &.paragraph-left-to-right:before {
    content: "\e06e";
  }
  &.paragraph-right-to-left:before {
    content: "\e06f";
  }
  &.paragraph-spacing:before {
    content: "\e070";
  }
  &.pen-tool:before {
    content: "\e071";
  }
  &.pencil:before {
    content: "\e072";
  }
  &.percent:before {
    content: "\e073";
  }
  &.bug:before {
    content: "\e074";
  }
  &.photo-landscape:before {
    content: "\e075";
  }
  &.photo-portrait:before {
    content: "\e076";
  }
  &.photo-view-all:before {
    content: "\e077";
  }
  &.picture-layer:before {
    content: "\e078";
  }
  &.pin:before {
    content: "\e079";
  }
  &.home:before {
    content: "\e07a";
  }
  &.power:before {
    content: "\e07b";
  }
  &.printer:before {
    content: "\e07c";
  }
  &.qr-code:before {
    content: "\e07d";
  }
  &.quote-closing:before {
    content: "\e07e";
  }
  &.quote-opening:before {
    content: "\e07f";
  }
  &.reflect-left:before {
    content: "\e080";
  }
  &.reflect-right:before {
    content: "\e081";
  }
  &.close-x-big:before {
    content: "\e082";
  }
  &.ruler:before {
    content: "\e083";
  }
  &.scale-vertical:before {
    content: "\e084";
  }
  &.scissors:before {
    content: "\e085";
  }
  &.select-area:before {
    content: "\e086";
  }
  &.send-to-back:before {
    content: "\e087";
  }
  &.send-to-front:before {
    content: "\e088";
  }
  &.database:before {
    content: "\e089";
  }
  &.settings-slider:before {
    content: "\e08a";
  }
  &.share:before {
    content: "\e08b";
  }
  &.megaphone:before {
    content: "\e08c";
  }
  &.rss:before {
    content: "\e08d";
  }
  &.signal:before {
    content: "\e08e";
  }
  &.signal-tower:before {
    content: "\e08f";
  }
  &.shear-left:before {
    content: "\e090";
  }
  &.shear-right:before {
    content: "\e091";
  }
  &.shopping-bag-alt:before {
    content: "\e092";
  }
  &.shopping-basket:before {
    content: "\e093";
  }
  &.shopping-cart:before {
    content: "\e094";
  }
  &.shrink:before {
    content: "\e095";
  }
  &.shrink-diagonal:before {
    content: "\e096";
  }
  &.shrink-horizontal:before {
    content: "\e097";
  }
  &.shrink-vertical:before {
    content: "\e098";
  }
  &.command:before {
    content: "\e099";
  }
  &.sign-euro:before {
    content: "\e09a";
  }
  &.sign-ramp-left:before {
    content: "\e09b";
  }
  &.sign-ramp-right:before {
    content: "\e09c";
  }
  &.skull:before {
    content: "\e09d";
  }
  &.sliders:before {
    content: "\e09e";
  }
  &.slider:before {
    content: "\e09f";
  }
  &.small-caps:before {
    content: "\e0a0";
  }
  &.speaker-volume-decrease:before {
    content: "\e0a1";
  }
  &.speaker-volume-high:before {
    content: "\e0a2";
  }
  &.speaker-volume-increase:before {
    content: "\e0a3";
  }
  &.speaker-volume-low:before {
    content: "\e0a4";
  }
  &.speaker-volume-off:before {
    content: "\e0a5";
  }
  &.spelling-check:before {
    content: "\e0a6";
  }
  &.spray-paint:before {
    content: "\e0a7";
  }
  &.strike-through:before {
    content: "\e0a8";
  }
  &.subscript:before {
    content: "\e0a9";
  }
  &.substract:before {
    content: "\e0aa";
  }
  &.sunny:before {
    content: "\e0ab";
  }
  &.superscript:before {
    content: "\e0ac";
  }
  &.switch-left:before {
    content: "\e0ad";
  }
  &.switch-right:before {
    content: "\e0ae";
  }
  &.switches:before {
    content: "\e0af";
  }
  &.bluetooth:before {
    content: "\e0b0";
  }
  &.wifi:before {
    content: "\e0b1";
  }
  &.repeat:before {
    content: "\e0b2";
  }
  &.synchronize:before {
    content: "\e0b3";
  }
  &.switch:before {
    content: "\e0b4";
  }
  &.price-tag:before {
    content: "\e0b5";
  }
  &.tank:before {
    content: "\e0b6";
  }
  &.text:before {
    content: "\e0b7";
  }
  &.text-eraser:before {
    content: "\e0b8";
  }
  &.rotate-right:before {
    content: "\e0b9";
  }
  &.rotate-left:before {
    content: "\e0ba";
  }
  &.text-vector:before {
    content: "\e0bb";
  }
  &.toolbox:before {
    content: "\e0bc";
  }
  &.underline:before {
    content: "\e0bd";
  }
  &.unite:before {
    content: "\e0be";
  }
  &.user:before {
    content: "\e0bf";
  }
  &.vector-circle:before {
    content: "\e0c0";
  }
  &.vector-line:before {
    content: "\e0c1";
  }
  &.vector-square:before {
    content: "\e0c2";
  }
  &.video-call:before {
    content: "\e0c3";
  }
  &.video-clip:before {
    content: "\e0c4";
  }
  &.eject:before {
    content: "\e0c5";
  }
  &.next:before {
    content: "\e0c6";
  }
  &.pause:before {
    content: "\e0c7";
  }
  &.play:before {
    content: "\e0c8";
  }
  &.previous:before {
    content: "\e0c9";
  }
  &.stop:before {
    content: "\e0ca";
  }
  &.heart-outlined:before {
    content: "\e0cb";
  }
  &.star-outlined:before {
    content: "\e0cc";
  }
  &.thumbs-down:before {
    content: "\e0cd";
  }
  &.thumbs-up:before {
    content: "\e0ce";
  }
  &.zoom-in:before {
    content: "\e0cf";
  }
  &.zoom-out:before {
    content: "\e0d0";
  }
  &.glasses:before {
    content: "\e0d1";
  }
  &.save:before {
    content: "\e0d2";
  }
  &.chevron-left:before {
    content: "\e0d3";
  }
  &.chevron-right:before {
    content: "\e0d4";
  }
  &.move-vertical:before {
    content: "\e0d5";
  }
  &.drag-handle:before {
    content: "\e0d7";
  }
  &.user-chat:before {
    content: "\e0d8";
  }
  &.text-input:before {
    content: "\e0d9";
  }
  &.tablet:before {
    content: "\e0da";
  }
  &.company:before {
    content: "\e0db";
  }
  &.android:before {
    content: "\e0dd";
  }
  &.apple:before {
    content: "\e0de";
  }
  &.facebook:before {
    content: "\e0df";
  }
  &.google:before {
    content: "\e0e0";
  }
  &.linkedin:before {
    content: "\e0e1";
  }
  &.twitter:before {
    content: "\e0e2";
  }
  &.xing:before {
    content: "\e0e3";
  }
  &.youtube:before {
    content: "\e0e4";
  }
  &.find:before {
    content: "\e0e5";
  }
  &.business-man:before {
    content: "\e0e6";
  }
  &.factory:before {
    content: "\e0e7";
  }
  &.building:before {
    content: "\e0e8";
  }
  &.fax:before {
    content: "\e0e9";
  }
  &.globe:before {
    content: "\e0ea";
  }
  &.phone:before {
    content: "\e0eb";
  }
  &.close-x-circle:before {
    content: "\e0ec";
  }
  &.ticket:before {
    content: "\e0ed";
  }
  &.key-enter:before {
    content: "\e0ee";
  }
  &.key-left:before {
    content: "\e0ef";
  }
  &.key-right:before {
    content: "\e0f0";
  }
  &.key-shift:before {
    content: "\e0f1";
  }
  &.img:before {
    content: "\e0f2";
  }
  &.smiley:before {
    content: "\e0f3";
  }
  &.arrow-next:before {
    content: "\e0f4";
  }
  &.arrow-prev:before {
    content: "\e0f5";
  }
  &.wand:before {
    content: "\e0f6";
  }
  &.h-align-left:before {
    content: "\e0f9";
  }
  &.h-align-middle:before {
    content: "\e0fa";
  }
  &.h-align-right:before {
    content: "\e0fb";
  }
  &.v-align-bottom:before {
    content: "\e0fc";
  }
  &.v-align-middle:before {
    content: "\e0fd";
  }
  &.v-align-top:before {
    content: "\e0fe";
  }
  &.flip-h:before {
    content: "\e0ff";
  }
  &.flip-v:before {
    content: "\e100";
  }
  &.graph-bar:before {
    content: "\e101";
  }
  &.check:before {
    content: "\e0f8";
  }
  &.layer-down:before {
    content: "\e102";
  }
  &.layer-up:before {
    content: "\e103";
  }
  &.undo:before {
    content: "\e104";
  }
  &.redo:before {
    content: "\e105";
  }
  &.stackoverflow:before {
    content: "\e106";
  }
  &.kununu:before {
    content: "\e107";
  }
  &.totop:before {
    content: "\e108";
  }
  &.shopping-bag:before {
    content: "\e109";
  }
  &.profile-circle:before {
    content: "\e10a";
  }
  &.heart-filled:before {
    content: "\e10b";
  }
  &.github:before {
    content: "\e10c";
  }
  &.glue:before {
    content: "\e10d";
  }
  &.adhesive-tape:before {
    content: "\e10e";
  }
  &.transparent:before {
    content: "\e10f";
  }
  &.recycling:before {
    content: "\e110";
  }
  &.dumbbell:before {
    content: "\e111";
  }
  &.instagram:before {
    content: "\e112";
  }
  &.eye-open:before {
    content: "\e113";
  }
  &.water-bottle:before {
    content: "\e114";
  }
  &.close-x:before {
    content: "\e115";
  }
  &.star-filled:before {
    content: "\e116";
  }
  &.clock:before {
    content: "\e117";
  }
  &.appointment:before {
    content: "\e118";
  }
  &.save-date:before {
    content: "\e119";
  }
  &.whistle:before {
    content: "\e11a";
  }
  &.weight:before {
    content: "\e11b";
  }
  &.share-alt:before {
    content: "\e11c";
  }
  &.coin-transfer:before {
    content: "\e11d";
  }
  &.toggle-caret:before {
    content: "\e11e";
  }
  &.config:before {
    content: "\e11f";
  }
  &.server:before {
    content: "\e120";
  }
  &.undo-2:before {
    content: "\e121";
  }
  &.filter-2:before {
    content: "\e0d6";
  }
  &.article:before {
    content: "\e0f7";
  }
  &.bold:before {
    content: "\e122";
  }
  &.bigger-text:before {
    content: "\e123";
  }
  &.checkmark:before {
    content: "\e124";
  }
  &.cross:before {
    content: "\e125";
  }
  &.img-center:before {
    content: "\e126";
  }
  &.img-full:before {
    content: "\e127";
  }
  &.img-left:before {
    content: "\e128";
  }
  &.smaller-text:before {
    content: "\e129";
  }
  &.checkmark-2:before {
    content: "\e0dc";
  }
}
