/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @param {array} $hamburger-spacing [$btn-spacing]
/// @param {number} $hamburger-width [16] - width of hamburger menu
/// @param {color} $hamburger-background [transparent]
/// @param {number} $hamburger-line-weight [2] - height of hamburger menu bars
/// @param {number} $hamburger-line-spacing [4]
/// @param {color} $hamburger-line-color [$link]
/// @param {array} $hamburger-style
@use "sass:math";

.hamburger,
.hamburger-btn {
  $_h: $hamburger-line-weight;
  $_w: $hamburger-line-width;
  $_s: $hamburger-line-weight + $hamburger-line-spacing;
  $_c: $hamburger-line-color;

  position: relative;
  @include _btn-reset;
  @include _btn-reset;
  @include inject-style($btn-style);
  @include inject-style($hamburger-style);
  @include metrics($btn-font-size, $btn-line-height, $btn-min-height, $padding-ratio: $btn-padding-ratio);
  border-radius: array-magic($btn-radius);
  background-color: $hamburger-background;
  margin: array-magic($hamburger-spacing);

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transition: all 0.4s;
    background-color: $_c;
    border-radius: array-magic(math.div($_h, 2));
    box-shadow: 0 rem(($_s)) 0 0 $_c, 0 rem(-($_s)) 0 0 $_c;
    height: rem($_h);
    left: rem(math.div($btn-min-height - $_w, 2));
    margin-top: rem(math.div(-$_h, 2));
    width: rem($_w);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transition: all 0.4s;
    background-color: $_c;
    border-radius: array-magic(math.div($_h, 2));
    height: rem($_h);
    left: rem((math.div($btn-min-height - $_w, 2)));
    margin-top: rem(math.div(-$_h, 2));
    width: rem($_w);
  }

  &.is-active {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
      background-color: $_c;
      border-radius: array-magic(math.div($_h, 2));
      height: rem($_h);
      left: rem((math.div($btn-min-height - $_w, 2)));
      margin-top: rem(math.div(-$_h, 2));
      transform: rotate(45deg);
      width: rem($_w);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}
