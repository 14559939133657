.btn-toggle {
  display: flex;
  padding: rem(2);
  border-radius: rem($btn-small-radius + 2);
  background-color: $btn-minor-background;

  button {
    flex: 1;
    @include _btn-reset;
    @include inject-style($btn-style);
    @include metrics(
      $btn-small-font-size,
      $btn-small-line-height,
      $btn-small-min-height - 4,
      $padding-ratio: $btn-padding-ratio
    );
    border-radius: rem($btn-small-radius);
    font-weight: $btn-weight;
    background: none;
    color: $btn-minor-color;

    &.active {
      background-color: $btn-background;
      color: $btn-color;
    }
  }
}

.field-group_toggle {
  &.vertical-center {
    margin: 0;
  }
}

.field-group_toggle {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;

  .field-group__label {
    font-size: 1em;
    color: get-color(text);
    margin: 0 rem(8);
    flex: 1;
  }
}
