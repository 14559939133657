.teams-select {
  > .input-group {
    width: 100%;
  }
}

.teams-select-dropdown {
  max-width: rem(320);
  min-width: rem(240);
  max-height: initial;
  overflow: visible;
}

.ui-select-user-select {
  z-index: 10000;
}
