//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &.fade .modal-dialog {
    transform: translate(0, -25%);
    transition: all 0.3s ease-out;
  }
  &.in .modal-dialog {
    transform: initial;
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: 100%;
  padding: rem(16);
}

// Actual modal
.modal-content {
  position: relative;
  background-color: get-color(background);
  border-radius: rem($dialog-radius);
  background-clip: padding-box;
  outline: 0;
  max-width: rem(960);
  margin: 0 auto;
  padding: 16px;
}

.modal-sm {
  .modal-content {
    max-width: rem(320);
  }
}

.modal-xl {
  padding: 0;
  display: flex;
  height: 100vh;

  .modal-content {
    border-radius: unset;
    max-width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .modal-body {
      flex-grow: 1;
    }
  }
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(black, 0.2);

  // Fade for backdrop
  &.fade {
    opacity: 0;
  }
  &.in {
    opacity: 1;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  background-color: get-color(background);
  position: sticky;
  top: 0;
  z-index: 10;
  padding: rem(8) rem(16);
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: rem(8) rem(16);

  .section-body {
    padding-left: 0;
    padding-right: 0;
  }
}

// Footer (for actions)
.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
