/**
 * Variables declared here can be overridden by consuming applications, with
 * the help of the `!default` flag.
 *
 * @example
 *     // overriding $hoverColor
 *     $hoverColor: rgba(red, 0.05);
 *
 *     // overriding image path
 *     $flagsImagePath: "images/";
 *
 *     // import the scss file after the overrides
 *     @import "bower_component/intl-tel-input/src/css/intlTelInput";
 */

// scss-lint:disable EmptyLineBetweenBlocks ImportantRule
@use "sass:math";

.intl-tel-input {
  position: relative;
  display: inline-block;
  width: 100%;

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .hide {
    display: none;
  }

  .v-hide {
    visibility: hidden;
  }

  input {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0;
    &[type="text"],
    &[type="tel"] {
      position: relative;
      z-index: 0;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-right: 36px;
      margin-right: 0;
    }
  }

  .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px;
  }

  .selected-flag {
    z-index: 1;
    position: relative;
    width: 46px;
    height: 100%;
    padding: 0 0 0 8px;

    .iti-flag {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    .iti-arrow {
      position: absolute;
      top: 50%;
      margin-top: -2px;
      right: 6px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;

      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }

  .country-list {
    list-style: none;
    margin: 0;
    position: absolute;
    top: 100%;
    right: 0;
    @include is-scrollable(y, contain, $behavior: auto);
    @include inject-style($dropdown-options-style);
    background: $dropdown-options-background;
    border-radius: array-magic($dropdown-options-radius);
    @if $dropdown-options-elevation {
      @include elevation($dropdown-options-elevation);
    }
    color: set-contrast($dropdown-options-background);
    padding: array-magic($dropdown-options-padding);
    z-index: 10;
    max-height: rem(320);
    text-align: left;

    &.dropup {
      top: auto;
      bottom: 100%;
    }

    .flag-box {
      display: inline-block;
      width: 20px;
    }

    .divider {
      margin: rem(4) 0;
    }

    .country {
      padding: 5px 10px;

      .dial-code {
        color: #999;
      }

      &.highlight {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .flag-box,
    .country-name,
    .dial-code {
      vertical-align: middle;
    }

    .flag-box,
    .country-name {
      margin-right: 6px;
    }
  }

  &.allow-dropdown input {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;

    &[type="text"],
    &[type="tel"] {
      padding-right: 6px;
      padding-left: 52px;
      margin-left: 0;
    }
  }

  &.separate-dial-code input {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;

    &[type="text"],
    &[type="tel"] {
      padding-right: 6px;
      padding-left: 52px;
      margin-left: 0;
    }
  }

  &.allow-dropdown .flag-container,
  &.separate-dial-code .flag-container {
    right: auto;
    left: 0;
  }

  &.allow-dropdown .selected-flag,
  &.separate-dial-code .selected-flag {
    width: 46px;
  }

  &.allow-dropdown {
    .flag-container:hover {
      cursor: pointer;
      .selected-flag {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    input {
      &[disabled] + .flag-container:hover,
      &[readonly] + .flag-container:hover {
        cursor: default;
      }
      &[disabled] + .flag-container:hover .selected-flag,
      &[readonly] + .flag-container:hover .selected-flag {
        background-color: transparent;
      }
    }
  }
  &.separate-dial-code {
    .selected-flag {
      background-color: rgba(0, 0, 0, 0.05);
      display: table;
    }
    .selected-dial-code {
      display: table-cell;
      vertical-align: middle;
      padding-left: 28px;
    }
    &.iti-sdc-2 {
      input {
        padding-left: 66px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 66px;
        }
      }
      .selected-flag {
        width: 60px;
      }
    }
    &.allow-dropdown.iti-sdc-2 {
      input {
        padding-left: 76px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 76px;
        }
      }
      .selected-flag {
        width: 70px;
      }
    }
    &.iti-sdc-3 {
      input {
        padding-left: 74px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 74px;
        }
      }
      .selected-flag {
        width: 68px;
      }
    }
    &.allow-dropdown.iti-sdc-3 {
      input {
        padding-left: 84px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 84px;
        }
      }
      .selected-flag {
        width: 78px;
      }
    }
    &.iti-sdc-4 {
      input {
        padding-left: 82px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 82px;
        }
      }
      .selected-flag {
        width: 76px;
      }
    }
    &.allow-dropdown.iti-sdc-4 {
      input {
        padding-left: 92px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 92px;
        }
      }
      .selected-flag {
        width: 86px;
      }
    }
    &.iti-sdc-5 {
      input {
        padding-left: 90px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 90px;
        }
      }
      .selected-flag {
        width: 84px;
      }
    }
    &.allow-dropdown.iti-sdc-5 {
      input {
        padding-left: 100px;
        &[type="text"],
        &[type="tel"] {
          padding-left: 100px;
        }
      }
      .selected-flag {
        width: 94px;
      }
    }
  }
  &.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px;
    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}

.iti-mobile .intl-tel-input {
  &.iti-container {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    position: fixed;
  }

  .country-list {
    max-height: 100%;
    width: 100%;

    .country {
      padding: 10px 10px;
      line-height: 1.5em;
    }
  }
}

.iti-flag {
  width: 20px;

  &.be {
    width: 18px;
  }

  &.ch {
    width: 15px;
  }

  &.mc {
    width: 19px;
  }

  &.ne {
    width: 18px;
  }

  &.np {
    width: 13px;
  }

  &.va {
    width: 15px;
  }

  &.ac {
    height: 10px;
    background-position: 0px 0px;
  }

  &.ad {
    height: 14px;
    background-position: -22px 0px;
  }

  &.ae {
    height: 10px;
    background-position: -44px 0px;
  }

  &.af {
    height: 14px;
    background-position: -66px 0px;
  }

  &.ag {
    height: 14px;
    background-position: -88px 0px;
  }

  &.ai {
    height: 10px;
    background-position: -110px 0px;
  }

  &.al {
    height: 15px;
    background-position: -132px 0px;
  }

  &.am {
    height: 10px;
    background-position: -154px 0px;
  }

  &.ao {
    height: 14px;
    background-position: -176px 0px;
  }

  &.aq {
    height: 14px;
    background-position: -198px 0px;
  }

  &.ar {
    height: 13px;
    background-position: -220px 0px;
  }

  &.as {
    height: 10px;
    background-position: -242px 0px;
  }

  &.at {
    height: 14px;
    background-position: -264px 0px;
  }

  &.au {
    height: 10px;
    background-position: -286px 0px;
  }

  &.aw {
    height: 14px;
    background-position: -308px 0px;
  }

  &.ax {
    height: 13px;
    background-position: -330px 0px;
  }

  &.az {
    height: 10px;
    background-position: -352px 0px;
  }

  &.ba {
    height: 10px;
    background-position: -374px 0px;
  }

  &.bb {
    height: 14px;
    background-position: -396px 0px;
  }

  &.bd {
    height: 12px;
    background-position: -418px 0px;
  }

  &.be {
    height: 15px;
    background-position: -440px 0px;
  }

  &.bf {
    height: 14px;
    background-position: -460px 0px;
  }
  &.bg {
    height: 12px;
    background-position: -482px 0px;
  }
  &.bh {
    height: 12px;
    background-position: -504px 0px;
  }
  &.bi {
    height: 12px;
    background-position: -526px 0px;
  }
  &.bj {
    height: 14px;
    background-position: -548px 0px;
  }
  &.bl {
    height: 14px;
    background-position: -570px 0px;
  }
  &.bm {
    height: 10px;
    background-position: -592px 0px;
  }
  &.bn {
    height: 10px;
    background-position: -614px 0px;
  }
  &.bo {
    height: 14px;
    background-position: -636px 0px;
  }
  &.bq {
    height: 14px;
    background-position: -658px 0px;
  }
  &.br {
    height: 14px;
    background-position: -680px 0px;
  }
  &.bs {
    height: 10px;
    background-position: -702px 0px;
  }
  &.bt {
    height: 14px;
    background-position: -724px 0px;
  }
  &.bv {
    height: 15px;
    background-position: -746px 0px;
  }
  &.bw {
    height: 14px;
    background-position: -768px 0px;
  }
  &.by {
    height: 10px;
    background-position: -790px 0px;
  }
  &.bz {
    height: 14px;
    background-position: -812px 0px;
  }
  &.ca {
    height: 10px;
    background-position: -834px 0px;
  }
  &.cc {
    height: 10px;
    background-position: -856px 0px;
  }
  &.cd {
    height: 15px;
    background-position: -878px 0px;
  }
  &.cf {
    height: 14px;
    background-position: -900px 0px;
  }
  &.cg {
    height: 14px;
    background-position: -922px 0px;
  }
  &.ch {
    height: 15px;
    background-position: -944px 0px;
  }
  &.ci {
    height: 14px;
    background-position: -961px 0px;
  }
  &.ck {
    height: 10px;
    background-position: -983px 0px;
  }
  &.cl {
    height: 14px;
    background-position: -1005px 0px;
  }
  &.cm {
    height: 14px;
    background-position: -1027px 0px;
  }
  &.cn {
    height: 14px;
    background-position: -1049px 0px;
  }
  &.co {
    height: 14px;
    background-position: -1071px 0px;
  }
  &.cp {
    height: 14px;
    background-position: -1093px 0px;
  }
  &.cr {
    height: 12px;
    background-position: -1115px 0px;
  }
  &.cu {
    height: 10px;
    background-position: -1137px 0px;
  }
  &.cv {
    height: 12px;
    background-position: -1159px 0px;
  }
  &.cw {
    height: 14px;
    background-position: -1181px 0px;
  }
  &.cx {
    height: 10px;
    background-position: -1203px 0px;
  }
  &.cy {
    height: 13px;
    background-position: -1225px 0px;
  }
  &.cz {
    height: 14px;
    background-position: -1247px 0px;
  }
  &.de {
    height: 12px;
    background-position: -1269px 0px;
  }
  &.dg {
    height: 10px;
    background-position: -1291px 0px;
  }
  &.dj {
    height: 14px;
    background-position: -1313px 0px;
  }
  &.dk {
    height: 15px;
    background-position: -1335px 0px;
  }
  &.dm {
    height: 10px;
    background-position: -1357px 0px;
  }
  &.do {
    height: 13px;
    background-position: -1379px 0px;
  }
  &.dz {
    height: 14px;
    background-position: -1401px 0px;
  }
  &.ea {
    height: 14px;
    background-position: -1423px 0px;
  }
  &.ec {
    height: 14px;
    background-position: -1445px 0px;
  }
  &.ee {
    height: 13px;
    background-position: -1467px 0px;
  }
  &.eg {
    height: 14px;
    background-position: -1489px 0px;
  }
  &.eh {
    height: 10px;
    background-position: -1511px 0px;
  }
  &.er {
    height: 10px;
    background-position: -1533px 0px;
  }
  &.es {
    height: 14px;
    background-position: -1555px 0px;
  }
  &.et {
    height: 10px;
    background-position: -1577px 0px;
  }
  &.eu {
    height: 14px;
    background-position: -1599px 0px;
  }
  &.fi {
    height: 12px;
    background-position: -1621px 0px;
  }
  &.fj {
    height: 10px;
    background-position: -1643px 0px;
  }
  &.fk {
    height: 10px;
    background-position: -1665px 0px;
  }
  &.fm {
    height: 11px;
    background-position: -1687px 0px;
  }
  &.fo {
    height: 15px;
    background-position: -1709px 0px;
  }
  &.fr {
    height: 14px;
    background-position: -1731px 0px;
  }
  &.ga {
    height: 15px;
    background-position: -1753px 0px;
  }
  &.gb {
    height: 10px;
    background-position: -1775px 0px;
  }
  &.gd {
    height: 12px;
    background-position: -1797px 0px;
  }
  &.ge {
    height: 14px;
    background-position: -1819px 0px;
  }
  &.gf {
    height: 14px;
    background-position: -1841px 0px;
  }
  &.gg {
    height: 14px;
    background-position: -1863px 0px;
  }
  &.gh {
    height: 14px;
    background-position: -1885px 0px;
  }
  &.gi {
    height: 10px;
    background-position: -1907px 0px;
  }
  &.gl {
    height: 14px;
    background-position: -1929px 0px;
  }
  &.gm {
    height: 14px;
    background-position: -1951px 0px;
  }
  &.gn {
    height: 14px;
    background-position: -1973px 0px;
  }
  &.gp {
    height: 14px;
    background-position: -1995px 0px;
  }
  &.gq {
    height: 14px;
    background-position: -2017px 0px;
  }
  &.gr {
    height: 14px;
    background-position: -2039px 0px;
  }
  &.gs {
    height: 10px;
    background-position: -2061px 0px;
  }
  &.gt {
    height: 13px;
    background-position: -2083px 0px;
  }
  &.gu {
    height: 11px;
    background-position: -2105px 0px;
  }
  &.gw {
    height: 10px;
    background-position: -2127px 0px;
  }
  &.gy {
    height: 12px;
    background-position: -2149px 0px;
  }
  &.hk {
    height: 14px;
    background-position: -2171px 0px;
  }
  &.hm {
    height: 10px;
    background-position: -2193px 0px;
  }
  &.hn {
    height: 10px;
    background-position: -2215px 0px;
  }
  &.hr {
    height: 10px;
    background-position: -2237px 0px;
  }
  &.ht {
    height: 12px;
    background-position: -2259px 0px;
  }
  &.hu {
    height: 10px;
    background-position: -2281px 0px;
  }
  &.ic {
    height: 14px;
    background-position: -2303px 0px;
  }
  &.id {
    height: 14px;
    background-position: -2325px 0px;
  }
  &.ie {
    height: 10px;
    background-position: -2347px 0px;
  }
  &.il {
    height: 15px;
    background-position: -2369px 0px;
  }
  &.im {
    height: 10px;
    background-position: -2391px 0px;
  }
  &.in {
    height: 14px;
    background-position: -2413px 0px;
  }
  &.io {
    height: 10px;
    background-position: -2435px 0px;
  }
  &.iq {
    height: 14px;
    background-position: -2457px 0px;
  }
  &.ir {
    height: 12px;
    background-position: -2479px 0px;
  }
  &.is {
    height: 15px;
    background-position: -2501px 0px;
  }
  &.it {
    height: 14px;
    background-position: -2523px 0px;
  }
  &.je {
    height: 12px;
    background-position: -2545px 0px;
  }
  &.jm {
    height: 10px;
    background-position: -2567px 0px;
  }
  &.jo {
    height: 10px;
    background-position: -2589px 0px;
  }
  &.jp {
    height: 14px;
    background-position: -2611px 0px;
  }
  &.ke {
    height: 14px;
    background-position: -2633px 0px;
  }
  &.kg {
    height: 12px;
    background-position: -2655px 0px;
  }
  &.kh {
    height: 13px;
    background-position: -2677px 0px;
  }
  &.ki {
    height: 10px;
    background-position: -2699px 0px;
  }
  &.km {
    height: 12px;
    background-position: -2721px 0px;
  }
  &.kn {
    height: 14px;
    background-position: -2743px 0px;
  }
  &.kp {
    height: 10px;
    background-position: -2765px 0px;
  }
  &.kr {
    height: 14px;
    background-position: -2787px 0px;
  }
  &.kw {
    height: 10px;
    background-position: -2809px 0px;
  }
  &.ky {
    height: 10px;
    background-position: -2831px 0px;
  }
  &.kz {
    height: 10px;
    background-position: -2853px 0px;
  }
  &.la {
    height: 14px;
    background-position: -2875px 0px;
  }
  &.lb {
    height: 14px;
    background-position: -2897px 0px;
  }
  &.lc {
    height: 10px;
    background-position: -2919px 0px;
  }
  &.li {
    height: 12px;
    background-position: -2941px 0px;
  }
  &.lk {
    height: 10px;
    background-position: -2963px 0px;
  }
  &.lr {
    height: 11px;
    background-position: -2985px 0px;
  }
  &.ls {
    height: 14px;
    background-position: -3007px 0px;
  }
  &.lt {
    height: 12px;
    background-position: -3029px 0px;
  }
  &.lu {
    height: 12px;
    background-position: -3051px 0px;
  }
  &.lv {
    height: 10px;
    background-position: -3073px 0px;
  }
  &.ly {
    height: 10px;
    background-position: -3095px 0px;
  }
  &.ma {
    height: 14px;
    background-position: -3117px 0px;
  }
  &.mc {
    height: 15px;
    background-position: -3139px 0px;
  }
  &.md {
    height: 10px;
    background-position: -3160px 0px;
  }
  &.me {
    height: 10px;
    background-position: -3182px 0px;
  }
  &.mf {
    height: 14px;
    background-position: -3204px 0px;
  }
  &.mg {
    height: 14px;
    background-position: -3226px 0px;
  }
  &.mh {
    height: 11px;
    background-position: -3248px 0px;
  }
  &.mk {
    height: 10px;
    background-position: -3270px 0px;
  }
  &.ml {
    height: 14px;
    background-position: -3292px 0px;
  }
  &.mm {
    height: 14px;
    background-position: -3314px 0px;
  }
  &.mn {
    height: 10px;
    background-position: -3336px 0px;
  }
  &.mo {
    height: 14px;
    background-position: -3358px 0px;
  }
  &.mp {
    height: 10px;
    background-position: -3380px 0px;
  }
  &.mq {
    height: 14px;
    background-position: -3402px 0px;
  }
  &.mr {
    height: 14px;
    background-position: -3424px 0px;
  }
  &.ms {
    height: 10px;
    background-position: -3446px 0px;
  }
  &.mt {
    height: 14px;
    background-position: -3468px 0px;
  }
  &.mu {
    height: 14px;
    background-position: -3490px 0px;
  }
  &.mv {
    height: 14px;
    background-position: -3512px 0px;
  }
  &.mw {
    height: 14px;
    background-position: -3534px 0px;
  }
  &.mx {
    height: 12px;
    background-position: -3556px 0px;
  }
  &.my {
    height: 10px;
    background-position: -3578px 0px;
  }
  &.mz {
    height: 14px;
    background-position: -3600px 0px;
  }
  &.na {
    height: 14px;
    background-position: -3622px 0px;
  }
  &.nc {
    height: 10px;
    background-position: -3644px 0px;
  }
  &.ne {
    height: 15px;
    background-position: -3666px 0px;
  }
  &.nf {
    height: 10px;
    background-position: -3686px 0px;
  }
  &.ng {
    height: 10px;
    background-position: -3708px 0px;
  }
  &.ni {
    height: 12px;
    background-position: -3730px 0px;
  }
  &.nl {
    height: 14px;
    background-position: -3752px 0px;
  }
  &.no {
    height: 15px;
    background-position: -3774px 0px;
  }
  &.np {
    height: 15px;
    background-position: -3796px 0px;
  }
  &.nr {
    height: 10px;
    background-position: -3811px 0px;
  }
  &.nu {
    height: 10px;
    background-position: -3833px 0px;
  }
  &.nz {
    height: 10px;
    background-position: -3855px 0px;
  }
  &.om {
    height: 10px;
    background-position: -3877px 0px;
  }
  &.pa {
    height: 14px;
    background-position: -3899px 0px;
  }
  &.pe {
    height: 14px;
    background-position: -3921px 0px;
  }
  &.pf {
    height: 14px;
    background-position: -3943px 0px;
  }
  &.pg {
    height: 15px;
    background-position: -3965px 0px;
  }
  &.ph {
    height: 10px;
    background-position: -3987px 0px;
  }
  &.pk {
    height: 14px;
    background-position: -4009px 0px;
  }
  &.pl {
    height: 13px;
    background-position: -4031px 0px;
  }
  &.pm {
    height: 14px;
    background-position: -4053px 0px;
  }
  &.pn {
    height: 10px;
    background-position: -4075px 0px;
  }
  &.pr {
    height: 14px;
    background-position: -4097px 0px;
  }
  &.ps {
    height: 10px;
    background-position: -4119px 0px;
  }
  &.pt {
    height: 14px;
    background-position: -4141px 0px;
  }
  &.pw {
    height: 13px;
    background-position: -4163px 0px;
  }
  &.py {
    height: 11px;
    background-position: -4185px 0px;
  }
  &.qa {
    height: 8px;
    background-position: -4207px 0px;
  }
  &.re {
    height: 14px;
    background-position: -4229px 0px;
  }
  &.ro {
    height: 14px;
    background-position: -4251px 0px;
  }
  &.rs {
    height: 14px;
    background-position: -4273px 0px;
  }
  &.ru {
    height: 14px;
    background-position: -4295px 0px;
  }
  &.rw {
    height: 14px;
    background-position: -4317px 0px;
  }
  &.sa {
    height: 14px;
    background-position: -4339px 0px;
  }
  &.sb {
    height: 10px;
    background-position: -4361px 0px;
  }
  &.sc {
    height: 10px;
    background-position: -4383px 0px;
  }
  &.sd {
    height: 10px;
    background-position: -4405px 0px;
  }
  &.se {
    height: 13px;
    background-position: -4427px 0px;
  }
  &.sg {
    height: 14px;
    background-position: -4449px 0px;
  }
  &.sh {
    height: 10px;
    background-position: -4471px 0px;
  }
  &.si {
    height: 10px;
    background-position: -4493px 0px;
  }
  &.sj {
    height: 15px;
    background-position: -4515px 0px;
  }
  &.sk {
    height: 14px;
    background-position: -4537px 0px;
  }
  &.sl {
    height: 14px;
    background-position: -4559px 0px;
  }
  &.sm {
    height: 15px;
    background-position: -4581px 0px;
  }
  &.sn {
    height: 14px;
    background-position: -4603px 0px;
  }
  &.so {
    height: 14px;
    background-position: -4625px 0px;
  }
  &.sr {
    height: 14px;
    background-position: -4647px 0px;
  }
  &.ss {
    height: 10px;
    background-position: -4669px 0px;
  }
  &.st {
    height: 10px;
    background-position: -4691px 0px;
  }
  &.sv {
    height: 12px;
    background-position: -4713px 0px;
  }
  &.sx {
    height: 14px;
    background-position: -4735px 0px;
  }
  &.sy {
    height: 14px;
    background-position: -4757px 0px;
  }
  &.sz {
    height: 14px;
    background-position: -4779px 0px;
  }
  &.ta {
    height: 10px;
    background-position: -4801px 0px;
  }
  &.tc {
    height: 10px;
    background-position: -4823px 0px;
  }
  &.td {
    height: 14px;
    background-position: -4845px 0px;
  }
  &.tf {
    height: 14px;
    background-position: -4867px 0px;
  }
  &.tg {
    height: 13px;
    background-position: -4889px 0px;
  }
  &.th {
    height: 14px;
    background-position: -4911px 0px;
  }
  &.tj {
    height: 10px;
    background-position: -4933px 0px;
  }
  &.tk {
    height: 10px;
    background-position: -4955px 0px;
  }
  &.tl {
    height: 10px;
    background-position: -4977px 0px;
  }
  &.tm {
    height: 14px;
    background-position: -4999px 0px;
  }
  &.tn {
    height: 14px;
    background-position: -5021px 0px;
  }
  &.to {
    height: 10px;
    background-position: -5043px 0px;
  }
  &.tr {
    height: 14px;
    background-position: -5065px 0px;
  }
  &.tt {
    height: 12px;
    background-position: -5087px 0px;
  }
  &.tv {
    height: 10px;
    background-position: -5109px 0px;
  }
  &.tw {
    height: 14px;
    background-position: -5131px 0px;
  }
  &.tz {
    height: 14px;
    background-position: -5153px 0px;
  }
  &.ua {
    height: 14px;
    background-position: -5175px 0px;
  }
  &.ug {
    height: 14px;
    background-position: -5197px 0px;
  }
  &.um {
    height: 11px;
    background-position: -5219px 0px;
  }
  &.us {
    height: 11px;
    background-position: -5241px 0px;
  }
  &.uy {
    height: 14px;
    background-position: -5263px 0px;
  }
  &.uz {
    height: 10px;
    background-position: -5285px 0px;
  }
  &.va {
    height: 15px;
    background-position: -5307px 0px;
  }
  &.vc {
    height: 14px;
    background-position: -5324px 0px;
  }
  &.ve {
    height: 14px;
    background-position: -5346px 0px;
  }
  &.vg {
    height: 10px;
    background-position: -5368px 0px;
  }
  &.vi {
    height: 14px;
    background-position: -5390px 0px;
  }
  &.vn {
    height: 14px;
    background-position: -5412px 0px;
  }
  &.vu {
    height: 12px;
    background-position: -5434px 0px;
  }
  &.wf {
    height: 14px;
    background-position: -5456px 0px;
  }
  &.ws {
    height: 10px;
    background-position: -5478px 0px;
  }
  &.xk {
    height: 15px;
    background-position: -5500px 0px;
  }
  &.ye {
    height: 14px;
    background-position: -5522px 0px;
  }
  &.yt {
    height: 14px;
    background-position: -5544px 0px;
  }
  &.za {
    height: 14px;
    background-position: -5566px 0px;
  }
  &.zm {
    height: 14px;
    background-position: -5588px 0px;
  }
  &.zw {
    height: 10px;
    background-position: -5610px 0px;
  }
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.png");
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
  &.np {
    background-color: transparent;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: math.div(2, 1)),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: math.div(2, 1)),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../images/flags@2x.png");
  }
}
