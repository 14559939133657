/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @param {array} $xui-radio-spacing [0 8 0 0]
/// @param {number} $xui-radio-height [20]
/// @param {color} $xui-radio-background [input-background]
/// @param {array} $xui-radio-style
/// @param {number} $xui-radio-dot-size [8]
/// @param {color} $xui-radio-dot-color [transparent]
///
/// @require {placeholder} is-hidden-input
/// @require {mixin} inject-style()
/// @require {function} array-magic
/// @require {function} rem
@use "sass:math";

.xui-radio {
  position: relative;

  [type="radio"],
  [type="checkbox"] {
    @extend %is-hidden-input;
  }
}

.xui-radio__label {
  cursor: pointer;
  display: flex;
  align-items: flex-start;

  // @param {color} $xui-radio-dot-hover-color [$text-lightest]
  // @param {array} $xui-checkbox-hover-style
  &:hover {
    @media (hover: hover) {
      &::before {
        @include inject-style($xui-radio-hover-style);
        background-color: $xui-radio-dot-hover-color;
        border-width: rem(math.div($xui-radio-height - $xui-radio-dot-size, 2));
      }
    }
  }

  // function to set equal margin for xui-checkbox without a label
  $_length: length($xui-radio-spacing);
  $_s: $xui-radio-spacing;

  @if ($_length == 4) {
    $_s: nth($xui-radio-spacing, 4);
  } @else if ($_length > 1) {
    $_s: nth($xui-radio-spacing, 2);
  }

  &:empty {
    &::before {
      margin-right: rem($_s);
    }
  }

  &::before {
    content: "";
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    transition: all 0.2s;
    border-style: solid;
    @include inject-style($xui-radio-style);
    border-width: rem(math.div($xui-radio-height, 2));
    border-radius: array-magic(math.div($xui-radio-height, 2));
    border-color: $xui-radio-background;
    background-color: $xui-radio-dot-color;
    height: rem($xui-radio-height);
    width: rem($xui-radio-height);
    margin: array-magic($xui-radio-spacing);

    // @param {color} $xui-radio-dot-checked-color [$link]
    // @param {array} $xui-checkbox-checked-style
    @at-root :checked ~ & {
      @include inject-style($xui-radio-checked-style);
      background-color: $xui-radio-dot-checked-color;
      border-width: rem(math.div($xui-radio-height - $xui-radio-dot-size, 2));
    }
  }
}
