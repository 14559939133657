@use "sass:math";

.mg-deck-sub {
  order: 3;
  display: none;
  flex-grow: 1;
  flex-basis: 30%;
}

.mg-deck-sidebar {
  order: 1;
  flex-grow: 0;
}

.mg-deck-help {
  display: none;
}

.mg-deck__content {
  order: 2;
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;

  .mg-deck__header {
    flex-grow: 0;
  }

  .mg-deck__body {
    flex-grow: 1;
    overflow-y: auto;
  }
}

.mg-deck-kpis {
  padding: rem(8) 0;
  background-color: get-color(background);

  .kpis-list {
    margin: 0;
  }
}

.mg-deck_half {
  width: 100vw;

  @media (min-width: bp(md)) {
    width: calc((100vw - 80px) / 2);
  }

  @media (min-width: bp(lg)) {
    width: calc((100vw - 160px) / 2);
  }

  @media (min-width: bp(xl)) {
    width: calc((100vw - 320px) / 2);
  }
}

.mg-deck_full {
  width: 100vw;

  @media (min-width: bp(md)) {
    width: calc(100vw - 80px);
  }

  @media (min-width: bp(lg)) {
    width: calc(100vw - 160px);
  }

  @media (min-width: bp(xl)) {
    width: calc(100vw - 320px);
  }
}

.mg-deck-primary-open {
  .mg-deck-primary {
    display: flex;

    .mg-deck-close-primary {
      display: inline-flex;
    }
  }

  &.mg-deck-sub-open {
    .mg-deck-sub {
      display: flex;

      &.project {
        display: none;
      }
    }
  }
}

.mg-deck-secondary-open {
  .mg-deck-primary {
    display: flex;

    .mg-deck-close-primary {
      display: inline-flex;
    }
  }

  .mg-deck-secondary {
    display: flex;

    .mg-deck-close-secondary {
      display: inline-flex;
    }
  }

  .mg-deck-sub {
    display: none;
  }
}

.mg-deck-secondary {
  z-index: 10;
}

.mg-deck__body {
  flex-grow: 1;
  @include is-scrollable(y, contain, $behavior: auto);
  @include inject-style($deck-body-style);
  margin: array-magic($deck-body-spacing);
  padding: array-magic($deck-body-padding);

  dl.is-inline {
    dt {
      border-bottom: 1px solid get-color(divider);
      @include word-wrap;

      &:last-of-type {
        border-bottom: none;
      }
    }

    dd {
      border-bottom: 1px solid get-color(divider);

      p {
        &:empty {
          display: none;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }

      p {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    ul:not([class]) {
      padding-left: rem(16);
    }
  }
}

.mg-deck__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  @include inject-style($deck-header-style);
  margin: array-magic($deck-header-spacing);
  padding: array-magic($deck-header-padding);
  z-index: 1;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.06);

  .toolbar {
    height: rem(80);
  }

  @include headings {
    small {
      display: block;
    }
  }
}

.mg-deck {
  flex-direction: row;
  display: none;
  position: relative;
  height: 100%;
  @include inject-style($deck-style);
  background: $deck-background;
  color: $deck-color;
  padding: array-magic($deck-padding);
  box-shadow: array-magic($deck-opens-left-shadow);
}
