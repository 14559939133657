.notification-item {
  padding: rem(12) rem(16);
  margin: rem(2) 0;
  border-radius: rem(4);
  cursor: pointer;

  &:nth-of-type(even) {
    background-color: get-color(background, lightest);
  }

  p {
    margin: 0 0 rem(4) 0;
  }

  &.is-new {
  }

  &.is-read {
    color: get-color(text, light);
  }

  &:hover {
    background-color: var(--areaHoverBackground);
  }
}

.notification {
  font-size: rem($base-font-size);
  width: rem(240);
}
