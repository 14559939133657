/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms modifier
///
/// @param {number} $btn-padding-ratio [1.5]
/// @param {number} $btn-outlined-border-width [2]
/// @param {color} $btn-outlined-border-style [solid]

// @param {number} $btn-font-size [$base-font-size]
// @param {number} $btn-line-height [$btn-font-size * $base-line-height]
// @param {number} $btn-min-height [40]
// @param {color} $btn-outlined-border-color [$link]
// @param {color} $btn-outlined-color [$link]
// @param {array} $btn-outlined-style
.btn_outlined {
  background: transparent;
  @include inject-style($btn-outlined-style);
  @include metrics(
    $btn-font-size,
    $btn-line-height,
    $btn-min-height,
    $btn-outlined-border-width,
    $padding-ratio: $btn-padding-ratio
  );
  border-style: $btn-outlined-border-style;
  border-color: $btn-outlined-border-color;
  color: $btn-outlined-color;

  // @param {number} $btn-small-font-size [$btn-font-size * .875]
  // @param {number} $btn-small-line-height [$btn-small-font-size * $base-line-height]
  // @param {number} $btn-small-min-height [32]
  &.btn_small {
    @include metrics(
      $btn-small-font-size,
      $btn-small-line-height,
      $btn-small-min-height,
      $btn-outlined-border-width,
      $padding-ratio: $btn-padding-ratio
    );
  }

  // @param {number} $btn-big-font-size [$btn-font-size * 1.125]
  // @param {number} $btn-big-line-height [$btn-big-font-size * $base-line-height]
  // @param {number} $btn-big-min-height [48]
  &.btn_big {
    @include metrics(
      $btn-big-font-size,
      $btn-big-line-height,
      $btn-big-min-height,
      $btn-outlined-border-width,
      $padding-ratio: $btn-padding-ratio
    );
  }

  // @param {color} $btn-outlined-hover-border-color [set-hover($btn-outlined-border-color)]
  // @param {color} $btn-outlined-hover-color [set-hover($btn-outlined-color)]
  // @param {array} $btn-outlined-hover-style
  &.is-active,
  &:hover,
  &:focus {
    background: transparent;
    @include inject-style($btn-outlined-hover-style);
    border-color: $btn-outlined-hover-border-color;
    color: $btn-outlined-hover-color;
  }

  // @param {color} $btn-outlined-minor-border-color [$minor]
  // @param {color} $btn-outlined-minor-color [$minor-contrast]
  // @param {array} $btn-outlined-minor-style
  &.btn_minor {
    @include inject-style($btn-outlined-minor-style);
    border-color: $btn-outlined-minor-border-color;
    color: $btn-outlined-minor-hover-color;

    // @param {color} $btn-outlined-minor-hover-border-color [set-hover($btn-outlined-minor-border-color)]
    // @param {color} $btn-outlined-minor-hover-color [set-hover($btn-outlined-minor-color)]
    // @param {array} $btn-outlined-minor-hover-style
    &.is-active,
    &:hover,
    &:focus {
      @include inject-style($btn-outlined-minor-hover-style);
      border-color: $btn-outlined-minor-hover-border-color;
      color: $btn-outlined-minor-hover-color;
    }
  }

  // @param {color} $btn-outlined-super-border-color [$super]
  // @param {color} $btn-outlined-super-color [$super-contrast]
  // @param {array} $btn-outlined-super-style
  &.btn_super {
    @include inject-style($btn-outlined-super-style);
    border-color: $btn-outlined-super-border-color;
    color: $btn-outlined-super-hover-color;

    // @param {color} $btn-outlined-super-hover-border-color [set-hover($btn-outlined-super-border-color)]
    // @param {color} $btn-outlined-super-hover-color [set-hover($btn-outlined-super-color)]
    // @param {array} $btn-outlined-super-hover-style
    &.is-active,
    &:hover,
    &:focus {
      @include inject-style($btn-outlined-super-hover-style);
      border-color: $btn-outlined-super-hover-border-color;
      color: $btn-outlined-super-hover-color;
    }
  }

  // @param {color} $btn-outlined-invert-border-color [$text-invert]
  // @param {color} $btn-outlined-invert-color [$text-invert]
  // @param {array} $btn-outlined-invert-style
  &.btn_invert {
    @include inject-style($btn-outlined-invert-style);
    border-color: $btn-outlined-invert-border-color;
    color: $btn-outlined-invert-hover-color;

    // @param {color} $btn-outlined-invert-hover-border-color [set-hover($btn-outlined-invert-border-color)]
    // @param {color} $btn-outlined-invert-hover-color [set-hover($btn-outlined-invert-color)]
    // @param {array} $btn-outlined-invert-hover-style
    &.is-active,
    &:hover,
    &:focus {
      @include inject-style($btn-outlined-invert-hover-style);
      border-color: $btn-outlined-invert-hover-border-color;
      color: $btn-outlined-invert-hover-color;
    }
  }
}
