.map-wrapper {
  margin-bottom: rem(16);
  width: 100%;
  max-width: rem(320);
  padding-top: 150px;
  position: relative;

  .map-iframe {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    border: 0;

    &.activateMap {
      pointer-events: auto;
    }

    pointer-events: none;
  }

  &:hover {
    .map-iframe {
      filter: blur(2px);
    }

    .map-overlay {
      visibility: visible;
      opacity: 1;
      color: get-color(text, invert);
    }
  }
}

.map-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 10;
  visibility: hidden;
  width: 100%;
  height: 150px;
  background-color: rgba($text, 0.4);
  color: #fff;
  font-size: rem(12);
  text-transform: uppercase;
  font-weight: $is-normal;

  .ixo {
    font-size: rem(20);
  }
}

.map-address {
  font-style: normal;
}
