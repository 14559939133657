@mixin areaBtn($color) {
  position: relative;
  background-color: transparent;
  color: $color;
  text-transform: uppercase;

  &::before {
    content: "";
    position: absolute;
    left: rem(-16);
    top: 50%;
    height: rem(4);
    background-color: $color;
    width: rem(4);
    opacity: 0;
    border-radius: 0 rem(4) rem(4) 0;
    transform: translateY(-50%);
    transition: all 0.2s;
  }

  &.is-active,
  &:focus,
  &:active,
  &:hover {
    background-color: transparent;
    color: $color;

    &::before {
      opacity: 1;
      height: rem(32);
    }
  }
}

.btn {
  .loader {
    position: absolute;
    top: 0;
    left: 0;
  }

  &_dashboard {
    @include areaBtn($link);
  }

  &_candidate {
    @include areaBtn($candidate);
  }

  &_client {
    @include areaBtn($client);
  }

  &_employee {
    @include areaBtn($employee);
  }

  &_project {
    @include areaBtn($project);
  }

  &_textkernel {
    @include areaBtn($textkernel);
  }
}
