.mg-deck-sidebar {
  position: relative;
  background-color: get-color(background);
  padding-top: rem(64);
  flex-grow: 0;
  flex-shrink: 0;
  order: -1;
  box-shadow: inset -1px 0 0 get-color(divider);

  a {
    color: var(--areaColor);

    &::before {
      content: "";
      position: absolute;
      left: rem(-8);
      top: 50%;
      height: rem(4);
      background-color: var(--areaColor);
      width: rem(4);
      opacity: 0;
      border-radius: array-magic(4 0 0 4);
      transform: translateY(-50%);
      transition: all 0.2s;
    }

    &.active,
    &:focus,
    &:active,
    &:hover {
      color: var(--areaColor);

      &::before {
        opacity: 1;
        height: rem(32);
      }
    }
  }
}
