.context-menu-wrapper {
  position: absolute;
  top: 100%;
  z-index: 10;
  background-color: get-color(background);
  border-radius: rem($dropdown-options-radius);
  box-shadow: 0 4px 8px rgba(black, 0.1), 0 4px 24px rgba(black, 0.1);
  transition: opacity 0.4s;
  padding: rem(16);
  min-width: rem(320);
  transform: translateX(-50%);
}
