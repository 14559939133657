/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group modifier
///
/// @param {map} $is-position-map
///
/// @require {mixin} set-breakpoints

$_keys: map-keys($is-position-map);
$_values: map-values($is-position-map);

@each $_key in $_keys {
  $_i: index($_keys, $_key);
  $_v: nth($_values, $_i);

  .#{$_key} {
    @include set-breakpoints {
      position: #{$_v};
    }
  }
}
