.mg-date-range-picker {
  position: relative;
}

.mg-date-range-picker__icon {
  position: absolute;
  right: 0;
  height: 100%;
  pointer-events: none;
}
