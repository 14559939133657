.mce-container,
.mce-container *,
.mce-widget,
.mce-widget *,
.mce-reset {
  color: $text !important;
  font-family: $body-font-family !important;
  font-size: rem($base-font-size) !important;
}

.mce-panel {
  border: none !important;
  background-color: transparent !important;
}

.mce-btn-group .mce-btn {
  border: 0 !important;
}

.mce-btn {
  display: inline-flex !important;
  min-width: rem(32) !important;
  min-height: rem(32) !important;
  justify-content: center !important;
  text-align: center;
  align-items: center !important;
  border: 0 !important;
  background-color: transparent !important;
}

.mce-edit-area {
  border: 1px solid $input-background !important;
  margin: 0 !important;
}

.mce-toolbar {
  background-color: $input-background !important;
}

.mce-statusbar {
  font-size: 12px !important;
  background-color: $input-background !important;
}

.mce-ico {
  font-family: "tinymce", sans-serif !important;
}

.mce-flow-layout-item {
  margin: 0 !important;

  &.mce-last {
    margin: 0 !important;
  }
}

.mce-btn-group {
  border: none !important;
  padding: 0 !important;
  margin: 0 2px !important;
}

.mce-menu {
  background: $dropdown-options-background !important;
  border-radius: array-magic($dropdown-options-radius) !important;
  @if $dropdown-options-elevation {
    @include elevation($dropdown-options-elevation);
  }
  color: set-contrast($dropdown-options-background) !important;
  padding: array-magic($dropdown-options-padding) !important;
}

.mce-menu-item,
.mce-menu-item-normal,
.mce-menu-item-preview {
  border: 0 !important;
  outline: none;
  transition: background-color 0.4s, color 0.4s !important;
  @include inject-style($xui-select-option-style);
  margin: array-magic($xui-select-option-spacing) !important;
  background: $xui-select-option-background !important;
  color: $xui-select-option-color !important;
  border-radius: array-magic($xui-select-option-radius) !important;
  padding: array-magic($xui-select-option-padding) !important;
  font-size: em($xui-select-option-font-size, $xui-select-font-size) !important;

  .mce-caret {
    border-left-color: $xui-select-option-selected-color !important;
  }

  &.mce-selected,
  &.mce-active {
    @include inject-style($xui-select-option-selected-style);
    background: $xui-select-option-selected-background !important;
    color: $xui-select-option-selected-color !important;

    .mce-caret {
      border-left-color: $xui-select-option-selected-color !important;
    }
  }

  &:hover {
    @include inject-style($xui-select-option-hover-style);
    background: $xui-select-option-hover-background !important;
    color: $xui-select-option-hover-color !important;

    .mce-caret {
      border-left-color: $xui-select-option-selected-color !important;
    }
  }
}

.mce-widget.mce-tooltip {
  .mce-tooltip-arrow {
    display: none !important;
  }

  .mce-tooltip-inner {
    font-family: $body-font-family;
    background: $tooltip-background !important;
    color: $tooltip-color !important;
    font-weight: $tooltip-weight !important;

    @if $tooltip-round == true {
      border-radius: 100vh;
    } @else {
      border-radius: array-magic($tooltip-radius);
    }
  }
}
