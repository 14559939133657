.users-help-sidebar {
  @include is-scrollable(y, contain, auto);
  height: 100vh;
  width: 0;
  //flex-grow: 1;
  background-color: get-color(background, lightest);
  box-shadow: inset 1px 0 0 rgba(#000, 0.04);
  transition: all 0.2s;

  &.is-active {
    width: rem(280);
  }

  &__body {
    padding: rem(16);
  }
}
