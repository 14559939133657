/// @author Bernhard Ruoff
/// @since 10.0.0
/// @access public
/// @group atoms
///
/// @param {color} $xui-toggle-background [$background-lighter]
/// @param {color} $xui-toggle-hover-background [etHover($xui-toggle-background)]
/// @param {color} $xui-toggle-active-background [$link)]
/// @param {number} $xui-toggle-height [20]
/// @param {color} $xui-toggle-width [$xui-toggle-height * 1.75]
/// @param {color} $xui-toggle-radius [$xui-toggle-height / 2]
/// @param {array} $xui-toggle-spacing [0]
/// @param {array} $xui-toggle-style
///
/// @require {placeholer} is-hidden-input
/// @require {mixin} inject-style()
/// @require {function} array-magic()
/// @require {function} rem()
/// @require {function} em()
@use "sass:math";

.xui-toggle {
  position: relative;
  display: flex;
  align-items: center;

  [type="radio"],
  [type="checkbox"] {
    @extend %is-hidden-input;
  }
}

.xui-toggle__switch {
  cursor: pointer;
  direction: ltr;
  display: inline-block;
  flex-shrink: 0;
  transition: background-color 0.2s;
  @include inject-style($xui-toggle-style);
  background: $xui-toggle-background;
  border-radius: array-magic($xui-toggle-radius);
  height: rem($xui-toggle-height);
  width: rem($xui-toggle-width);

  // @param {color} $xui-toggle-pin-background [$background]
  // @param {number} $xui-toggle-pin-height[$xui-toggle-height - 8]
  // @param {number} $xui-toggle-pin-width [$xui-toggle-pin-height]
  // @param {number} $xui-toggle-pin-radius [$xui-toggle-pin-height / 2]
  // @param {array} $xui-toggle-pin-style
  &::after {
    content: "";
    display: block;
    @include inject-style($xui-toggle-pin-style);
    background: $xui-toggle-pin-background;
    border-radius: array-magic($xui-toggle-pin-radius);
    height: rem($xui-toggle-pin-height);
    width: rem($xui-toggle-pin-width);
    margin: rem(math.div($xui-toggle-height - $xui-toggle-pin-height, 2));
  }

  @at-root :hover > & {
    background: $xui-toggle-hover-background;
  }

  @at-root :checked ~ &,
    :checked ~ .xui-toggle__label & {
    direction: rtl;
    background: $xui-toggle-active-background;
  }
}

// @param {number} $xui-toggle-label-font-size [base-font-size]
// @param {number} $xui-toggle-label-line-height [$xui-toggle-label-font-size * $base-line-height]
// @param {array} xui-toggle-label-style
.xui-toggle__label {
  cursor: pointer;
  display: flex;
  user-select: none;
  margin: 0;
  @include inject-style($xui-toggle-label-style);
  font-size: em($xui-toggle-label-font-size);
  line-height: em($xui-toggle-label-line-height, $xui-toggle-label-font-size);

  .xui-toggle__switch {
    margin: array-magic($xui-toggle-spacing);
  }
}
